import React, { ReactElement } from 'react';
import { Tabs } from 'antd';
import cx from 'classnames';

import './styles.css';
import style from './style.module.css';
import {
  TTabsPanel,
  TabsConfig,
  TabsItem,
  TabsSecondaryProps,
} from './types';

const { TabPane } = Tabs;

export const TabsPanel = ({
  tabs, _onChange, defaultTab, centeredTabs = false, activeKey, mode = 'top',
}: TTabsPanel): ReactElement => (
  <Tabs
    defaultActiveKey={defaultTab}
    onChange={_onChange}
    centered={centeredTabs}
    activeKey={activeKey}
    tabPosition={mode}
  >
    {tabs ? tabs.map((item: TabsItem) => (
      <TabPane tab={item.tab} key={item.key}>
        {item.content ? item.content : ''}
        {' '}
      </TabPane>
    )) : null}
  </Tabs>
);

export const TabsSecondary = ({
  config,
  activeTab,
  setActiveTab,
  redirectMethod,
  type,
  foreignClass = '',
}: TabsSecondaryProps): ReactElement => (
  <div className={style.tabsSecondary__container}>
    {config.map((tab: TabsConfig, index: number) => (
      <div
        key={tab.name}
        className={cx({
          [style.tabsSecondary__tab]: !foreignClass,
          [foreignClass]: foreignClass,
          [style['tabsSecondary__tab--active']]: activeTab === index + 1,
        })}
        onClick={(): void => setActiveTab(index + 1)}
        role="tab"
        tabIndex={index + 1}
        aria-hidden
      >
        {tab.name}
        {tab.icon && (
          <div
            className={style.tabsSecondary__icon}
            onClick={(): any => redirectMethod(type)}
            role="presentation"
          >
            {tab.icon}
          </div>
        )}
      </div>

    ))}
  </div>
);
