import { TypeValue } from '../components/Video/VideoExercise/types';
import { LIST_TYPE } from './enums';
import { TStatusOptions } from './model';

export const pageSizeOptions = ['15', '25', '50', '100'];
export const PAGE_SIZE_OPTIONS_FOR_VIDEO_EXERCISE = ['15', '25', '50', '100'];
export const PAGE_SIZE_OPTIONS_FOR_PENDING_PATIENT_INVITATIONS = ['15', '25', '50', '100'];
export const PAGE_SIZE_OPTIONS_FOR_PDF_PLANS = ['9', '27', '51', '100'];

export const getHistoryMenuConfig = (t: any) => {
  const change_by_hcp = t && t.dashboard.hcp.profile_patient.video_bank.change_by_hcp;
  const change_by_patient = t && t.dashboard.hcp.profile_patient.video_bank.change_by_patient;

  return [
    { value: 'hcp', label: change_by_hcp },
    { value: 'patient', label: change_by_patient },
  ];
};
export const MENU_CONFIG = (t: any): TypeValue[] => {
  const status_active = t && t.common.status_active;
  const status_inactive = t && t.common.status_inactive;
  const status_archived = t && t.common.status_archived;
  const status_deleted_by_hcp = t && t.common.status_deleted_by_hcp;
  const status_deleted_by_patient = t && t.common.status_deleted_by_patient;
  const status_not_uploaded = t && t.common.status_not_uploaded;

  return [
    { value: 'Active', label: status_active },
    { value: 'Archived', label: status_archived },
    { value: 'DeletedByHcp', label: status_deleted_by_hcp },
    { value: 'DeletedByPatient', label: status_deleted_by_patient },
    { value: 'Inactive', label: status_inactive },
    { value: 'NotUploaded', label: status_not_uploaded },
  ];
};
export const getStatusFilterOverviewTabVideobank = (t: any): TypeValue[] => {
  const status_active = t?.common.status_active;
  const status_inactive = t?.common.status_inactive;
  const status_archived = t?.common.status_archived;

  return [
    { value: 'Active', label: status_active },
    { value: 'Inactive', label: status_inactive },
    { value: 'Archived', label: status_archived },
  ];
};

export const COLOR_OPTIONS_VIDEOBANK = (t: any): TStatusOptions[] => {
  const status_active = t && t.common.status_active;
  const status_inactive = t && t.common.status_inactive;
  const status_archived = t && t.common.status_archived;
  const status_deleted_by_hcp = t && t.common.status_deleted_by_hcp;
  const status_deleted_by_patient = t && t.common.status_deleted_by_patient;
  const status_not_uploaded = t && t.common.status_not_uploaded;

  return [
    { value: 'Inactive', label: status_inactive, color: '#FD7743' },
    { value: 'Active', label: status_active, color: '#1CBA66' },
    { value: 'Archived', label: status_archived, color: '#23335B' },
    { value: 'Not uploaded', label: status_not_uploaded, color: '#9BA1AE' },
    { value: 'Deleted by patient', label: status_deleted_by_patient, color: '#FFA500' },
    { value: 'Deleted by hcp', label: status_deleted_by_hcp, color: '#CC8400' },
  ];
};

export const COLOR_STATUS_VIDEOBANK = (t: any): any => {
  const status_active = t && t.common.status_active;
  const status_inactive = t && t.common.status_inactive;
  const status_archived = t && t.common.status_archived;
  const status_deleted_by_hcp = t && t.common.status_deleted_by_hcp;
  const status_deleted_by_patient = t && t.common.status_deleted_by_patient;
  const status_not_uploaded = t && t.common.status_not_uploaded;

  return {
    Inactive: { label: status_inactive, color: '#FD7743' },
    Active: { label: status_active, color: '#1CBA66' },
    Archived: { label: status_archived, color: '#23335B' },
    'Not uploaded': { label: status_not_uploaded, color: '#9BA1AE' },
    'Deleted by patient': { label: status_deleted_by_patient, color: '#FFA500' },
    'Deleted by hcp': { label: status_deleted_by_hcp, color: '#CC8400' },
  };
};

export const sortStyles = {
  fontSize: '12px',
  height: '10px',
};

export const getColorOptionsTable = (t: any): TStatusOptions[] => {
  const active = t && t.common.status_active;
  const inactive = t && t.common.status_inactive;
  const invited = t && t.common.status_invited;
  const expired = t && t.common.status_expired;
  const connected = t && t.common.status_connected;
  const disconnected = t && t.common.status_disconnected;
  const treatment_completed = t && t.common.status_treatment_completed;
  const pending = t && t.common.status_pending;
  const invite_sent = t && t.common.status_invite_sent;
  const status_declined = t?.common.status_declined;

  return [
    { value: 'active', label: active, color: '#1CBA66' },
    { value: 'inactive', label: inactive, color: '#9BA1AE' },
    { value: 'invited', label: invited, color: '#FD7743' },
    { value: 'expired', label: expired, color: '#000000' },

    { value: 'connected', label: connected, color: '#1CBA66' },
    { value: 'disconnected', label: disconnected, color: '#FD7743' },
    { value: 'pending', label: pending, color: '#FD7743' },
    { value: 'treatment_completed', label: treatment_completed, color: '#00008B' },
    { value: 'invite_sent', label: invite_sent, color: '#b1532e' },
    { value: 'declined', label: status_declined, color: '#b1532e' },
  ];
};

export const EXCLUDE_FROM_VIDEOBANK_STATUS_SELECT = ['Not uploaded', 'Deleted by patient', 'Inactive'];
export const EXCLUDE_FROM_TABLE_STATUS_SELECT = ['active', 'inactive', 'invited', 'expired', 'pending', 'invite_sent', 'declined']; // to do - delete when will be deleting from HCP and BE

export const dayInMilliseconds = 86400000;

export const sorting = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const hcpArray: string[] = [LIST_TYPE.HCP, LIST_TYPE.HCP_ADMIN];
export const patientArray: string[] = [LIST_TYPE.PATIENT, LIST_TYPE.PATIENT_ADMIN];

export const adminAccount: string[] = [LIST_TYPE.HCP_ADMIN, LIST_TYPE.PATIENT_ADMIN];
export const hcpAccount: string[] = [LIST_TYPE.HCP, LIST_TYPE.PATIENT];

export const mobileFormat = 920;
export const startResponsivePoint = 1299;
export const numberYTicksForPainChart = 5;
export const numberYTicksForStepChart = 5;

export const exerciseConfig = (t: any): any => {
  const reps = t && t.dashboard.hcp.profile_patient.video_bank.reps;
  const time = t && t.dashboard.hcp.profile_patient.video_bank.time;
  const sets = t && t.dashboard.hcp.profile_patient.video_bank.sets;
  const timesDay = t && t.dashboard.hcp.profile_patient.video_bank.times_per_day;
  const restDaysWeek = t && t.dashboard.hcp.profile_patient.video_bank.rest_days_week;
  const minutes = t && t.dashboard.hcp.profile_patient.video_bank.minutes;
  const seconds = t && t.dashboard.hcp.profile_patient.video_bank.seconds;

  return {
    repsTime: [
      { label: reps, value: 'reps' },
      { label: time, value: 'time' },
    ],
    minSec: [
      { label: minutes, value: 'minutes' },
      { label: seconds, value: 'seconds' },
    ],
    sets: [
      {
        label: reps, value: 'reps', optionStart: 1, optionEnd: 30,
      },
      {
        label: sets, value: 'sets', optionStart: 1, optionEnd: 4,
      },
      {
        label: timesDay, value: 'timesDay', optionStart: 1, optionEnd: 5,
      },
      {
        label: restDaysWeek, value: 'restDaysWeek', optionStart: 0, optionEnd: 7,
      },
    ],
  };
};

// FORMAT DATES
// Format date for React-date-picker
export const dotFormatDate = 'dd.MM.yyyy';
export const slashFormatDate = 'MM/dd/yyyy';
export const dashFormatDate = 'dd-MM-yyyy';

// Format date for moment js
export const formatDateEU = 'DD.MM.YYYY';
export const formatDateUS = 'MM/DD/YYYY';

// globalNotification
export const ITEMS_PER_PAGE = 8;
export const NOT_READING_NOTIFICATION = 7;
export const ITEMS_PER_PATIENT_PAGE = 30;
export const NOT_READING_PATIENT_NOTIFICATION = 29;

export const chartLegendStyles = {
  left: '101%',
  top: 10,
  width: '195px',
};
