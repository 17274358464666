/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import State from '../completions/init';
import {
  LAST_3_MONTHS,
  LAST_6_MONTHS,
  maxStepScale,
} from '../../utils/helper_charts';
import { getDatesByFormat } from '../../utils/share/period';
import { PatientState } from '../patient';
import { QuestionnaireState } from '../questionnaires/types';
import { SelectedPeriodType } from '../dropdown';
import { PeriodListOptionValue, ProcedureKey, TDataPerPeriod } from '../../utils/model';
import { MotivationState } from '../motivations';
import { ExercisesType } from '../completions/types';
import { TSkipStopByExercises } from '../../components/BarCharts/types';
import { CommonType, HcpName } from '../common';
import { AvailablePatientPromsType } from '../../components/Proms/types';

interface WebFormatType {
  web: object;
}

interface TranslateType {
  local: object;
}

interface MotivationType {
  isFetching: boolean;
  error: any;
  motivation: object;
}
export interface FormatDates {
  momentFormat: string;
  reactDatePickerFormat: string;
}

export interface GetState {
  t: TranslateType;
  completed: ExercisesType;
  motivation: MotivationState;
  patient: PatientState;
  common: CommonType;
  proms: QuestionnaireState;
  selectedPeriod: SelectedPeriodType;
}

interface ExerciseTooltipType {
  completed: number;
  partlyDone: number;
  attempts: number;
  noAttempt?: number;
  isNull?: number;
}

interface StepType {
 id: number;
 patientId: number;
 steps: number;
 updatedAt: string;
}

export interface TAverageMapKey {
  motivation: number;
  averagePainScoreCompleted: number;
  averagePainScoreSkipStop: number;
}
export interface TAverageSkipStopMapKey {
  averagePainScoreCompleted: number;
  averagePainScoreSkipStop: number;
}

// Common
export const getCurrenLang = (state: GetState): object => state.t.local;
export const getCurrentFormatDate = (state: GetState): FormatDates => state.common.formatDates;
export const getUserLang = (state: GetState): string => state.common.userLang;
export const getUserCountry = (state: GetState): string => state.common.userCountry;
export const getHcpName = (state: GetState): HcpName => state.common.hcp;
export const getProcedureKeys = (state: GetState): ProcedureKey[] => state.common.listProcedureKeys;
export const getPermissions = (state: GetState): any[] => state.common.hcp.permissions;

// Patient profile
export const getPatientProfile = (state: GetState): any => state.patient.profile;
export const getLoadingPatientProfile = (state: GetState): boolean => state.patient.loading;
export const getIsNotSavedPatientProfile = (state: GetState): boolean => state.patient
  .isNotSavedProfileData;
export const getIsNotSavedPatientInfo = (state: GetState): boolean => state.patient
  .isNotSavedPatientInfo;

// Chart state
// --motivation
export const getMotivationOverviewTab = (state: GetState) => state.motivation.motivationOverviewTab;
export const getMotivationOverviewTabLoading = (state: GetState) => state.motivation
  .loadingOverviewTab;
export const getMotivation = (state: GetState) => state.motivation.motivation;
export const getMotivationLoading = (state: GetState) => state.motivation.loading;
export const getMotivValueForLegend = (state: GetState) => state.completed.motivationsLegendValue;

// --reasons
export const getReasonsValueForLegend = (state: GetState) => state.completed.reasonsLegendValue;

// --exercises
export const getExercisesOverviewTab = (state: GetState) => state.completed.exercisesOverviewTab;
export const getExercForMotivationPainTab = (state: GetState) => state.completed.exercMotivPainTab;
export const getExercForReasonsTab = (state: GetState) => state.completed.exercReasonsTab;
// --by exercises
export const getDataByExercises = (state: GetState): any => state.completed.byExercises;
export const getByExercValueForLegend = (state: GetState) => state.completed
  .complByExercLegendValue;
// --by periods
export const getPeriodsOfExercises = (state: GetState): any => state.completed.exercisesPerPeriod;

// --steps
export const getStepsOverviewTab = (state: GetState): any => state.completed.stepOverviewTab;
export const getStepsByExercisesTab = (state: GetState): any => state.completed.stepByExercises;
export const getStepsByPeriod = (state: GetState): any => state.completed.step;
export const getStepsLoading = (state: GetState): boolean => state.completed.stepsLoading;
export const getComplStepsForLegend = (state: GetState) => state.completed.complStepsLegendValue;

// --skip/stop
export const getComplSkipStopForLegend = (state: GetState) => state.completed
  .complSkipStopLegendValue;

export const getIsExercise = (state: GetState) => state.completed.isExercise;
export const getExerciseName = (state: GetState): null | string => state.completed.exerciseName;
export const getExercisesLoading = (state: GetState): boolean => state.completed.isFetching;

// Selected periods
export const getSelectedPeriodOverviewTab = (state: GetState): PeriodListOptionValue => state
  .selectedPeriod.periodOverviewTab;
export const getSelectedReason = (state: any) => state.selectedPeriod.reasons;
export const getSelectedPeriodMotivationPain = (state: GetState): PeriodListOptionValue => state
  .selectedPeriod.motivationPain;
// --by exercises
export const getSelectedDatesByExercises = (state: GetState): PeriodListOptionValue => state
  .selectedPeriod.completion;
// --by periods
export const getSelectedPeriodsDates = (state: GetState): PeriodListOptionValue => state
  .selectedPeriod.period;

// PROMs state
export const getAllPatientProms = (state: GetState) => state.proms.allPatientQuestionnaires;
export const getPatientPromsLoading = (state: GetState): boolean => state.proms
  .loadingQuestionnaires;
export const getAllProms = (state: GetState) => state.proms.questionnairesList.allQuestionnaires;
export const getLoadingAllProms = (state: GetState): boolean => state.proms.questionnairesList
  .loadingAllQuestionnaires;
export const getPainDetectPics = (state: GetState) => state.proms.painDetectPics.pics;
export const getLoadingPainDetecPics = (state: GetState) => state.proms.painDetectPics.loadingPics;
export const getEqValueForLegend = (state: GetState) => state.proms.eqLegendValue;
export const getAvailablePatientProms = (state: GetState) => state.proms
  .availablePatientQuestionnaires;
export const getLoadingAvailablePatientProms = (state: GetState): boolean => state.proms
  .loadingAvailablePatientQuestionnaires;

// compose selector
// export const getMotivationWithPainPerDay = (state: any) => {
//   const getCompleted = getDataByExercises(state);
//   return getCompleted.map(({ date, painLevel }) => ({
//     date,
//     painLevel,
//   }));
// };

export const getAllowedPatientProms = createSelector(
  getAvailablePatientProms,
  (availablePatientProms: AvailablePatientPromsType[]) => {
    const normilizeData: any = {};
    if (availablePatientProms?.length > 0) {
      availablePatientProms.map((questionnaiere: AvailablePatientPromsType) => {
        normilizeData[questionnaiere.id] = questionnaiere.status;
        return null;
      });
    }
    return normilizeData;
  },
);

export function getDatesBetween(dateFrom: string, dateTo: string) {
  const dateArray = [];
  let currentDate = moment(dateFrom);
  const stopDate = moment(dateTo);

  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}
// Skip Stop
// By periods
export const getPainAndMotivationDataPeriods = createSelector(
  getPeriodsOfExercises,
  getSelectedPeriodsDates,
  getMotivation,
  (exercises, period, motivations) => {
    // const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);

    const { periodFrom, dateTo, periodType } = period;
    const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    const painTypes = [
      'sumPainScoreCompleted',
      'sumPainScoreSkip',
      'sumPainScoreStop',
      'exerciseCompleted',
      'exerciseSkipped',
      'exerciseStopped',
      'exerciseSkippedAll',
      'exerciseStoppedAll',
      'sumDesiredNumberOfReps',
      // 'sumPainScoreBackCompleted',
      // 'sumPainScoreLegCompleted',
      // 'sumPainScoreBackSkip',
      // 'sumPainScoreLegSkip',
    ];
    const averagePainTyps = ['motivation'];
    const painAndMotivationResult: TDataPerPeriod[] = [];
    let emptyData = 0;
    let ticks = 0;

    if (exercises && exercises.length > 0) {
      let count = 0;
      for (const [dateKey, dateArray] of Object.entries(dates)) {
        const dataPerPeriod: TDataPerPeriod = {
          motivation: 0,
          sumPainScoreCompleted: 0,
          sumPainScoreSkip: 0,
          sumPainScoreStop: 0,
          exerciseCompleted: 0,
          exerciseSkipped: 0,
          exerciseStopped: 0,
          exerciseSkippedAll: 0,
          exerciseStoppedAll: 0,
          averagePainScoreCompleted: 0,
          averagePainScoreSkipStop: 0,
          dateArray: '',
          isArchive: false,
          sumDesiredNumberOfReps: 0,
          avDesiredNumberOfReps: 0,
          sumPainScoreBackCompleted: 0, // for motiv and pain chart
          sumPainScoreLegCompleted: 0, // for motiv and pain chart
          sumPainScoreBackSkip: 0, // for motiv and pain chart
          sumPainScoreLegSkip: 0, // for motiv and pain chart
          sumPainScoreBackStop: 0, // for motiv and pain chart
          sumPainScoreLegStop: 0, // for motiv and pain chart
          avPainScoreBackCompleted: 0, // for motiv and pain chart
          avPainScoreBackSkipStop: 0, // for motiv and pain chart
          avPainScoreLegCompleted: 0, // for motiv and pain chart
          avPainScoreLegSkipStop: 0, // for motiv and pain chart
        };

        let empty = 0;
        dateArray.map((date: string) => {
          const motivation = motivations.find((item: any) => item.createdAt.includes(date));
          if (motivation) {
            dataPerPeriod.motivation += +motivation.type;
          }
          exercises.map((exercise: any) => {
            exercise.exerciseDone.map((exerciseResult: any) => {
            // JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
              if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
                for (const [key, value] of Object.entries(exerciseResult[date])) {
                  if (painTypes.includes(key)) {
                    dataPerPeriod[key as keyof TAverageMapKey] += exerciseResult[date][key];
                  }
                }
              } else {
                emptyData += 1;
              }
              if (exerciseResult[date] === null) {
                empty += 1;
              }
              ticks += 1;
              return null;
            });
            return null;
          });
          const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
          const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
            + dataPerPeriod.sumPainScoreStop;
          if (dataPerPeriod.exerciseStoppedAll) {
            dataPerPeriod.avDesiredNumberOfReps = Math.round(dataPerPeriod.sumDesiredNumberOfReps
            / dataPerPeriod.exerciseStoppedAll);
          }
          dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
            ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
            : 0;
          dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
            ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
            : 0;
          return null;
        });

        if (dateArray.length) {
          averagePainTyps.map((key: string) => {
            dataPerPeriod[key as keyof TAverageMapKey] = Math.round(
              dataPerPeriod[key as keyof TAverageMapKey] / dateArray.length,
            );
            return null;
          });
        } else {
          averagePainTyps.map((key: string) => {
            dataPerPeriod[key as keyof TAverageMapKey] = 0;
            return null;
          });
        }
        count += 1;
        dataPerPeriod.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
        if (dateArray.length === (empty / exercises.length)) {
          dataPerPeriod.isArchive = true;
        }
        painAndMotivationResult.push(dataPerPeriod);
      }
    }
    return ticks !== emptyData ? painAndMotivationResult : [];
  },
);

// Completion tab (by exercises). Get a list of exercise names.
export const getConvertedExercisesNames = createSelector(getDataByExercises, (exercisesAll) => {
  const exercises = exercisesAll.filter((item: any) => item.exerciseDone !== null);
  return exercises.map((exercise: any) => ({ value: exercise.id, label: exercise.name }));
  // return exercises.map((exercise: any) => ({ value: exercise.name, label: exercise.name }));
});

// by Exercises (full screen)
export const getExercisesChartData = createSelector(
  getDataByExercises,
  getSelectedDatesByExercises,
  getExerciseName,
  (exercisesArrayAll, getSelected, exerciseId) => {
    const exercisesArray = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
    const exercises = exerciseId !== null
      ? exercisesArray.filter((item: any) => item.id === exerciseId)
      // ? exercisesArray.filter((item: any) => item.name === exerciseName)
      : exercisesArray;

    const { periodFrom, dateTo, periodType } = getSelected;
    const dates = getDatesByFormat(periodFrom, dateTo, periodType);

    const exercisesResult: any = [];

    exercises.map((exercise: any, exerciseIndex: number) => {
      const result: any = {
        id: 0,
        name: '',
        title: '',
        dates: {},
      };
      result.id = exercise.id;
      // Can be the same exercise name. For Chart delete id for XAxis
      result.name = `${exercise.name}<id_>${exercise.id}`;
      // result.name = exercise.name;
      result.title = String.fromCharCode(exerciseIndex + 65);

      for (const [key, datesArray] of Object.entries(dates)) {
        datesArray.map((date: any) => {
          const exerciseStatus: ExerciseTooltipType = {
            completed: 0,
            partlyDone: 0,
            attempts: 0,
            noAttempt: 0,
            isNull: 0,
          };

          const exerciseTooltipStatus: ExerciseTooltipType = {
            completed: 0,
            partlyDone: 0,
            attempts: 0,
            noAttempt: 0,
            isNull: 0,
          };
          JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
            if (exerciseResult[date] === null) {
              exerciseStatus.isNull = 100;
              exerciseTooltipStatus.isNull = 100;
              result.dates[date] = exerciseStatus;
            } else {
              if (!exerciseResult[date]) {
                exerciseStatus.noAttempt = 100;
                result.dates[date] = exerciseStatus;
                return;
              }
              for (const [key, value] of Object.entries(exerciseResult[date].status)) {
                if (key !== 'noAttempt' && value !== 0) {
                  // @ts-ignore
                  exerciseTooltipStatus[key] = value;
                }
              }
              let sumValues = 0;
              let sumPreviousValues = 0;

              for (const [key, value] of Object.entries(exerciseTooltipStatus)) {
                sumValues += value || 0;
                if (sumValues > 100) {
                  // @ts-ignore
                  exerciseStatus[key] = 100 - sumPreviousValues;
                  break;
                }
                sumPreviousValues += value || 0;
                // @ts-ignore
                exerciseStatus[key] = value;
              }
            }
          });
          exerciseStatus.noAttempt = 0;
          exerciseTooltipStatus.noAttempt = 0;
          exerciseStatus.noAttempt = Object.values(exerciseStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exerciseStatus).reduce((a, b) => a + b, 0) : 0;
          exerciseTooltipStatus.noAttempt = Object.values(exerciseTooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exerciseTooltipStatus).reduce((a, b) => a + b, 0) : 0;
          result.dates[date] = { status: { ...exerciseStatus }, tooltipStatus: { ...exerciseTooltipStatus } };
        });
      }
      exercisesResult.push(result);
    });
    return exercisesResult;
  },
);

// Completion & steps tab (full screen)
// steps by Exercises
export const getStepsByExercises = createSelector(
  getStepsByExercisesTab,
  getSelectedDatesByExercises,
  (steps, period) => {
    const { periodFrom, dateTo, periodType } = period;
    const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    const stepsArrayResult = [];
    const averageSteps = [];

    let count = 0;
    for (const [dataKey, dateArray] of Object.entries(dates)) {
      const stepsForPeriodResult: any = { steps: 0 };
      let sumValue = 0;
      dateArray.map((date: string) => {
        // @ts-ignore
        const step: StepType = steps.find((stepOne: StepType[]) => stepOne.updatedAt.indexOf(date) > -1);
        if (step) {
          sumValue += step.steps;
        }
        return null;
      });
      stepsForPeriodResult.steps = Math.round(sumValue / dateArray.length);
      averageSteps.push(stepsForPeriodResult);
    }

    for (const [dataKey, dateArray] of Object.entries(dates)) {
      const stepResult: any = {
        'steps': 0,
        'chartValue': 0,
        'dateSteps': '',
      };

      let sumValue = 0;
      dateArray.map((date: string) => {
        // @ts-ignore
        const step: StepType = steps.find((stp: StepType[]) => stp.updatedAt.indexOf(date) > -1);
        if (step) {
          sumValue += step.steps;
        }
        return null;
      });

      stepResult.steps = Math.round(sumValue / dateArray.length);
      stepResult.chartValue = Math.round((stepResult.steps / maxStepScale(averageSteps)) * 100);
      stepResult.dateSteps = dateArray.length === 1 ? dateArray[0] : dataKey;
      count += 1;
      stepResult.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
      stepsArrayResult.push(stepResult);
    }
    return stepsArrayResult;
  },
);

// Completion & steps tab (full screen)
// Number of Exercises Skipped or Stopped by Exercises
export const getSkipStopDataByExercises = createSelector(
  getDataByExercises,
  getSelectedDatesByExercises,
  getMotivation,
  getExerciseName,
  (exercisesArrayAll, period, motivations: any, exerciseId) => {
    const exercisesArray = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
    const exercises = exerciseId !== null
      ? exercisesArray.filter((item: any) => item.id === exerciseId)
      : exercisesArray;
    const painAndMotivationResult: TSkipStopByExercises[] = [];
    let emptyData = 0;
    let ticks = 0;

    if (exercises && exercises.length > 0) {
      const painTypes = [
        'sumPainScoreCompleted',
        'sumPainScoreSkip',
        'sumPainScoreStop',
        'exerciseCompleted',
        'exerciseSkipped',
        'exerciseStopped',
        'exerciseSkippedAll',
        'exerciseStoppedAll',
        'sumDesiredNumberOfReps',
      ];
      const averagePainTyps = ['motivation'];
      const { periodFrom, dateTo, periodType } = period;
      const dates = getDatesByFormat(periodFrom, dateTo, periodType);
      let count = 0;
      for (const [dateKey, dateArray] of Object.entries(dates)) {
        const dataPerPeriod: TSkipStopByExercises = {
          // motivation: 0,
          sumPainScoreCompleted: 0,
          sumPainScoreSkip: 0,
          sumPainScoreStop: 0,
          exerciseCompleted: 0,
          exerciseSkipped: 0,
          exerciseStopped: 0,
          exerciseSkippedAll: 0,
          exerciseStoppedAll: 0,
          averagePainScoreCompleted: 0,
          averagePainScoreSkipStop: 0,
          dateArray: '',
          isArchive: false,
          sumDesiredNumberOfReps: 0,
          avDesiredNumberOfReps: 0,
        };

        let empty = 0;
        dateArray.map((date: string) => {
          // const motivation = motivations.find((item: any) => item.createdAt.includes(date));
          // if (motivation) {
          //   dataPerPeriod.motivation += +motivation.type;
          // }
          exercises.map((exercise: any) => {
            JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
              if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
                for (const [key, value] of Object.entries(exerciseResult[date])) {
                  if (painTypes.includes(key)) {
                    dataPerPeriod[key as keyof TAverageSkipStopMapKey] += exerciseResult[date][key];
                  }
                }
              } else {
                emptyData += 1;
              }
              if (exerciseResult[date] === null) {
                empty += 1;
              }
              ticks += 1;
              return null;
            });
            return null;
          });
          const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
          const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
            + dataPerPeriod.sumPainScoreStop;
          if (dataPerPeriod.exerciseStoppedAll) {
            dataPerPeriod.avDesiredNumberOfReps = Math.round(dataPerPeriod.sumDesiredNumberOfReps
            / dataPerPeriod.exerciseStoppedAll);
          }
          dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
            ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
            : 0;
          dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
            ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
            : 0;
          return null;
        });

        if (dateArray.length) {
          averagePainTyps.map((key: string) => {
            dataPerPeriod[key as keyof TAverageSkipStopMapKey] = Math.round(
              dataPerPeriod[key as keyof TAverageSkipStopMapKey] / dateArray.length,
            );
            return null;
          });
        } else {
          averagePainTyps.map((key: string) => {
            dataPerPeriod[key as keyof TAverageSkipStopMapKey] = 0;
            return null;
          });
        }
        count += 1;
        dataPerPeriod.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
        if (dateArray.length === (empty / exercises.length)) {
          dataPerPeriod.isArchive = true;
        }
        painAndMotivationResult.push(dataPerPeriod);
      }
    }
    return ticks !== emptyData ? painAndMotivationResult : [];
  },
);

// Completion & steps tab (full screen)
// By period
export const getPeriodsChartsData = createSelector(
  getSelectedPeriodsDates, getPeriodsOfExercises, (getSelectedDates, exercises) => {
    if (exercises?.length) {
      // const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
      const { periodFrom, dateTo, periodType, value } = getSelectedDates;
      const dates = getDatesByFormat(periodFrom, dateTo, periodType);

      const resultByDayAndExercises: any = {};
      const resultByAveragePeriod: any = {};
      let isEmptyData = 0;
      let ticks = 0;
      for (const [dataKey, dateArray] of Object.entries(dates)) {
        dateArray.map((date: any) => {
          exercises.map((exercise: any) => {
            const exercisesResult: any = {
              status: {
                completed: 0,
                partlyDone: 0,
                attempts: 0,
                noAttempt: 0,
                isNull: 0,
              },
              // tooltipStatus: {
              //   completed: 0,
              //   partlyDone: 0,
              //   attempts: 0,
              //   noAttempt: 0,
              //   isNull: 0,
              // },
              name: exercise.name,
            };
            exercise.exerciseDone.map((exerciseResult: any) => {
            // JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
              if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
                for (const [key, val] of Object.entries(exerciseResult[date])) {
                  let sumPreviousValues: any = 0;
                  let sumValue: any = 0;
                  if (typeof val === 'object') {
                    for (const [key2, value2] of Object.entries(exerciseResult[date][key])) {
                      if (key === 'status') {
                        sumValue += value2;
                        if (sumValue > 100) {
                          // exercisesResult.tooltipStatus[key2] = 100 - sumPreviousValues;
                          exercisesResult.status[key2] = 100 - sumPreviousValues;
                          break;
                        }
                        sumPreviousValues += value2;
                        // exercisesResult.tooltipStatus[key2] = value2;
                        exercisesResult.status[key2] = value2;
                      }
                    }
                  }
                }
              } else {
                isEmptyData += 1;
              }
              if (exerciseResult[date] === null) {
                // exercisesResult.tooltipStatus.isNull = 100;
                exercisesResult.status.isNull = 100;
              }

              ticks += 1;

              exercisesResult.status.noAttempt = 0;
              // exercisesResult.tooltipStatus.noAttempt = 0;
              // @ts-ignore
              // exercisesResult.tooltipStatus.noAttempt = Object.values(exercisesResult.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exercisesResult.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
              // @ts-ignore
              exercisesResult.status.noAttempt = Object.values(exercisesResult.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exercisesResult.status).reduce((a, b) => a + b, 0) : 0;
            });
            if (!resultByDayAndExercises[date]) {
              resultByDayAndExercises[date] = [];
            }
            resultByDayAndExercises[date].push(exercisesResult);
            return null;
          });
          return null;
        });
        if (dateArray.length > 1) {
            let exercisesDataPeriod: any[] = [];
            exercises.forEach((exercise: any, index: number) => {
                let totalStatusesForTheEntirePeriod: any = {
                    'status': {
                        'completed': 0,
                        'partlyDone': 0,
                        'attempts': 0,
                        'noAttempt': 0,
                        'isNull':0
                    },
                    // 'tooltipStatus': {
                    //     'completed': 0,
                    //     'partlyDone': 0,
                    //     'attempts': 0,
                    //     'noAttempt': 0,
                    //     'isNull':0
                    // },
                    'name': '',
                }
                dateArray.map((day: any) => {
                    for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
                        totalStatusesForTheEntirePeriod.status[key] += resultByDayAndExercises[day][index].status[key];
                        // totalStatusesForTheEntirePeriod.tooltipStatus[key] += resultByDayAndExercises[day][index].tooltipStatus[key];
                    }
                    totalStatusesForTheEntirePeriod.name = resultByDayAndExercises[day][index].name;
                });
                for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
                    // @ts-ignore
                    totalStatusesForTheEntirePeriod.status[key] = Math.round( value / (dateArray.length));
                    if(key === 'isNull' && totalStatusesForTheEntirePeriod.status[key] < 100) {
                    totalStatusesForTheEntirePeriod.status[key] = 0;
                    }
                }
                // for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
                //     // @ts-ignore
                //     totalStatusesForTheEntirePeriod.tooltipStatus[key] = Math.round( value / (dateArray.length));
                //     if(key === 'isNull' && totalStatusesForTheEntirePeriod.tooltipStatus[key] < 100) {
                //       totalStatusesForTheEntirePeriod.tooltipStatus[key] = 0;
                //     }
                // }
                
                totalStatusesForTheEntirePeriod.status['noAttempt'] = 0;
                // totalStatusesForTheEntirePeriod.tooltipStatus['noAttempt'] = 0;
                let sumPreviousValuesAverage: number = 0;
                let sumValueAverage: number = 0;
                for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
                    // @ts-ignore
                    sumValueAverage += value2;
                    // @ts-ignore
                    if (sumValueAverage > 100) {
                        totalStatusesForTheEntirePeriod.status[key2] = 100 - sumPreviousValuesAverage;
                        break;
                    }
                      // @ts-ignore
                    sumPreviousValuesAverage += value2;
                    totalStatusesForTheEntirePeriod.status[key2] = value2;

                }
                
                // let sumPreviousValuesAverageTooltip: number = 0;
                // let sumValueAverageTooltip: number = 0;
                // for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
                //     // @ts-ignore
                //     sumValueAverageTooltip += value2;
                //     // @ts-ignore
                //     if (sumValueAverageTooltip > 100) {
                //         totalStatusesForTheEntirePeriod.tooltipStatus[key2] = 100 - sumPreviousValuesAverageTooltip;
                //         break;
                //     }
                //       // @ts-ignore
                //     sumPreviousValuesAverageTooltip += value2;
                //     totalStatusesForTheEntirePeriod.tooltipStatus[key2] = value2;

                // }
                // @ts-ignore
                totalStatusesForTheEntirePeriod.status['noAttempt'] = Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) : 0;
                // @ts-ignore
                // totalStatusesForTheEntirePeriod.tooltipStatus['noAttempt'] = Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
                exercisesDataPeriod.push(totalStatusesForTheEntirePeriod);
            })
            
            if (value === LAST_3_MONTHS || value === LAST_6_MONTHS) {
                const averageStatusesByWeek: any = {
                    'status': {
                        'completed': 0,
                        'partlyDone': 0,
                        'attempts': 0,
                        'noAttempt': 0,
                        'isNull':0
                    },
                    // 'tooltipStatus': {
                    //     'completed': 0,
                    //     'partlyDone': 0,
                    //     'attempts': 0,
                    //     'noAttempt': 0,
                    //     'isNull':0
                    // },
                }
                  let counter = 0;
                exercisesDataPeriod.forEach((exercise, index) => {
                  for (const [key, value] of Object.entries(averageStatusesByWeek)) {
                    for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
                      if (key2 !== 'isNull'){
                        averageStatusesByWeek[key][key2] += exercise[key][key2];
                      } else{
                        averageStatusesByWeek[key][key2] += exercise[key][key2];
                        exercise[key][key2] === 100 && counter++;
                      }
                    }
                    for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
                      if (index === exercisesDataPeriod.length - 1) {
                        averageStatusesByWeek[key][key2] = Math.round( averageStatusesByWeek[key][key2] / (exercisesDataPeriod.length-counter/2));
                        if (key2 !== 'isNull' ){
                          if(isNaN(averageStatusesByWeek[key][key2])) {
                            averageStatusesByWeek[key][key2] = 0;
                          }
                        } else{
                          if(isNaN(averageStatusesByWeek[key][key2])) {
                            averageStatusesByWeek[key][key2] = 100;
                          }
                        }
                        if(key2 === 'isNull' && averageStatusesByWeek[key][key2] < 100) {
                          averageStatusesByWeek[key][key2] = 0
                        }
                      }
                    }
                  }
                })
                
                let sumValue: any = 0;
                let previousValue: any = 0;
                for (const [key, value] of Object.entries(averageStatusesByWeek.status)) {
                    sumValue += value;
                    if (sumValue > 100) {
                        averageStatusesByWeek.status[key] = 100 - previousValue;
                        break;
                    }

                    previousValue += value;
                    averageStatusesByWeek.status[key] = value
                }
                averageStatusesByWeek.status['noAttempt'] = 0;
                // @ts-ignore
                averageStatusesByWeek.status['noAttempt'] = Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) : 0;
                // averageStatusesByWeek.tooltipStatus['noAttempt'] = 0;
                // @ts-ignore
                // averageStatusesByWeek.tooltipStatus['noAttempt'] = Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
                exercisesDataPeriod = [averageStatusesByWeek];
            }
            resultByAveragePeriod[dataKey] = exercisesDataPeriod;
        }
      }

      if (Object.keys(resultByAveragePeriod).length !== 0) {
        return ticks === isEmptyData ? {} : resultByAveragePeriod;
      }
      return resultByDayAndExercises;
    }
    return {};
  },
);

// Completion & steps tab (full screen)
// Steps by periods
export const getStepsBySelectedPeriod = createSelector(
  getStepsByPeriod,
  getSelectedPeriodsDates,
  (steps, period) => {
    const { periodFrom, dateTo, periodType } = period;
    const dates = getDatesByFormat(periodFrom, dateTo, periodType);
    const stepsArrayResult = [];
    const averageSteps = [];

    let count = 0;
    for (const [dataKey, dateArray] of Object.entries(dates)) {
      const stepsForPeriodResult: any = { steps: 0 };
      let sumValue = 0;
      dateArray.map((date: string) => {
        // @ts-ignore
        const step: StepType = steps.find((stepOne: StepType[]) => stepOne.updatedAt.indexOf(date) > -1);
        if (step) {
          sumValue += step.steps;
        }
        return null;
      });
      stepsForPeriodResult.steps = Math.round(sumValue / dateArray.length);
      averageSteps.push(stepsForPeriodResult);
    }

    for (const [dataKey, dateArray] of Object.entries(dates)) {
      const stepResult: any = {
        'steps': 0,
        'chartValue': 0,
        'dateSteps': '',
      };

      let sumValue = 0;
      dateArray.map((date: string) => {
        // @ts-ignore
        const step: StepType = steps.find((stp: StepType[]) => stp.updatedAt.indexOf(date) > -1);
        if (step) {
          sumValue += step.steps;
        }
        return null;
      });

      stepResult.steps = Math.round(sumValue / dateArray.length);
      stepResult.chartValue = Math.round((stepResult.steps / maxStepScale(averageSteps)) * 100);
      stepResult.dateSteps = dateArray.length === 1 ? dateArray[0] : dataKey;
      count += 1;
      stepResult.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
      stepsArrayResult.push(stepResult);
    }
    return stepsArrayResult;
  },
);

// CHECK and delete
export const getReasondTransformData = createSelector(
  [getStepsByPeriod, getMotivation, getDataByExercises, getSelectedReason],
  (steps, motivation, exersizedAll, period) => {
    const exersized = exersizedAll.filter((item: any) => item.exerciseDone !== null);
    // @ts-ignore
    const data = exersized.map((item: any) => JSON.parse(item.exerciseDone)); //

    const { periodFrom, dateTo, periodType } = period;

    const DatePeriod = getDatesByFormat(periodFrom, dateTo, periodType); // [ [], ]

    const dates = [
      // @ts-ignore
      ...new Set(data.map((exercise) => exercise.map((day) => Object.keys(day)[0])).flat()),
    ];

    return dates.map((currentDate, index) => {
      // @ts-ignore

      const filteredDataByCurrentDateFromAllExercises =
        data
        // @ts-ignore
          .map((currentExercises) => currentExercises.filter((a) => Object.keys(a)[0] === currentDate))
          .flat();

      const reasons = filteredDataByCurrentDateFromAllExercises.map((current: any) => {
        const hasData = !!current[currentDate];

        const reasons = hasData
          ? [
            {
              date: currentDate,
              reasons: current[currentDate].reasons,
              painLevel: current[currentDate].painLevel,
              status: current[currentDate].status,
              timesPerDay: current[currentDate].timesPerDay,
            },
          ]
          : false;
        return reasons;
      });

      const mergedReasons = reasons
        && reasons.map((currentReasons: any) => {
          const response = currentReasons[0];
          const timesPerDay = response && response.timesPerDay ? response.timesPerDay : 0;

          return {
            reasonsByDay:
              currentReasons && 'reasons' in response ? response.reasons : State.defaultReasons,
            statusByDay:
              currentReasons && 'status' in response ? response.status : State.defaultStatuses,
            paintByDay:
              currentReasons && 'painLevel' in response
                ? response.painLevel
                : State.defaultStatuses,
            timesPerDay,
          };
        });

      const avarageValue = mergedReasons
        .map((item: any) => {
          const data = item.timesPerDay;
          return data || 0;
        })
        .reduce((a: any, b: any) => a + b);

      const statusValue = State.statusDefault.map((currentStatus: string) => ({
        [currentStatus]: mergedReasons
          .map((item: any) => {
            const data = item.statusByDay;
            return currentStatus in data ? data[currentStatus] : 0;
          })
          .reduce((a: any, b: any) => {
            const awarage = a + b;
            return awarage;
          }),
      }));

      const painValues = State.exercisePainLevel.map((currentPainLevel: string) => ({
        [currentPainLevel]: mergedReasons
          .map((item: any) => {
            const data = item.paintByDay;

            if (currentPainLevel in data) {
              return data[currentPainLevel];
            }
            return 0;
          })
          .reduce((a: any, b: any) => a + b),
      }));

      const reasonValues = State.reasonTitles.map((currentReason) => ({
        [currentReason]: mergedReasons
          .map((item: any) => {
            const data = item.reasonsByDay;

            if (currentReason in data) {
              return data[currentReason];
            }
            return 0;
          })
          .reduce((a: any, b: any) => a + b), // day - no metter what is the ex
      }));

      const mockWithCorectMaxPerItem = reasons.map((current: any) => {
        const array = current[0] && current[0].reasons;
        const maxV = array && Object.values(array);
      });

      // @ts-ignore
      const step = steps
        .filter((item: any) => item && item.updatedAt && item.updatedAt.includes(currentDate))
        .reduce((a: any, b: any) => a + b.steps, 0);

      const motivations = motivation
      // @ts-ignore
        .filter((item: any) => item && item.updatedAt && item.updatedAt.includes(currentDate))
        .reduce((a: any, b: any) => a + b.type, 0);

      const number = Math.random().toString(36).substr(2, 9); //

      return {
        date: currentDate,
        reasons: reasonValues,
        painLevel: painValues,
        status: statusValue,
        timesPerDay: avarageValue,
        step,
        motivations,
        id: index + 1,
        uncName: { [number]: index + 1 }, // stiuncName[0]
      };
    });
  },
);
// Reasons for not compliting tab (Full screen)
export const getReasonsData = createSelector(
  [getExercForReasonsTab, getSelectedReason],
  (exersizeAll, selectedPeriod) => {
    // moved to graph
    // const exercises = exersizeAll.filter((item: any) => item.exerciseDone !== null);

    if (exersizeAll.length) {
      const dataParsed = exersizeAll.map((item: any) => JSON.parse(item.exerciseDone));
      const {
        periodFrom, dateTo, periodType,
      } = selectedPeriod;

      const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
      const reasonsByPeriods: any = [];

      Object.values(calcDates).map((datesFromPeriod: string[], index: number) => {
        // Create default values {key: 0}
        const reasons: any = State.reasonTitles
          .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
        let maxValueYaxis = 0;

        // Handling data within a period
        datesFromPeriod.map((date: string) => {
          // All exercises for the date
          dataParsed.map((exercise: any) => exercise.map((a: any) => {
            const keyObj = Object.keys(a)[0];
            if (keyObj === date) {
              State.reasonTitles.map((reasonName: string) => {
                const reasonFromData = a[keyObj]?.reasons?.[reasonName];
                if (reasonFromData) {
                  const accCurrentReason = reasons[reasonName] + reasonFromData;
                  reasons[reasonName] = accCurrentReason;
                  maxValueYaxis += reasonFromData;
                }
                return null;
              });
            }
            return null;
          }));
          return null;
        });

        const id = index + 1;
        const resultByPeriod: any = {
          date: datesFromPeriod.length === 1 ? datesFromPeriod[0] : id,
          id,
          maxValueYaxis,
          reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
        };
        reasonsByPeriods.push(resultByPeriod);
        return null;
      });
      return reasonsByPeriods;
    }
    return [];
  },
);

// Reasons for not compliting tab (Full screen)2
// export const getReasonsData = createSelector(
//   [getExercForReasonsTab, getSelectedReason],
//   (exercises, selectedPeriod) => {
//     if (exercises.length) {
//       const { periodFrom, dateTo, periodType } = selectedPeriod;
//       const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
//       const reasonsByPeriods: any = [];

//       Object.values(calcDates).map((datesFromPeriod: string[], index: number) => {
//         // Create default values {key: 0}
//         const reasons: any = State.reasonTitles
//           .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
//         let maxValueYaxis = 0;

//         // Handling data within a period
//         datesFromPeriod.map((date: string) => {
//           // All exercises for the date
//           exercises.map((rawExercises: any) => {
//             JSON.parse(rawExercises.exerciseDone).map((exerciseResult: any) => {
//               if (exerciseResult[date]) {
//                 State.reasonTitles.map((reasonName: string) => {
//                   const reasonFromData = exerciseResult[date].reasons?.[reasonName];
//                   if (reasonFromData) {
//                     const accCurrentReason = reasons[reasonName] + reasonFromData;
//                     reasons[reasonName] = accCurrentReason;
//                     maxValueYaxis += reasonFromData;
//                   }
//                   return null;
//                 });
//               }
//             });
//             return null;
//           });
//           return null;
//         });

//         const id = index + 1;
//         const resultByPeriod: any = {
//           date: datesFromPeriod.length === 1 ? datesFromPeriod[0] : id,
//           id,
//           maxValueYaxis,
//           reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
//         };
//         reasonsByPeriods.push(resultByPeriod);
//         return null;
//       });
//       return reasonsByPeriods;
//     }
//     return [];
//   },
// );

// Reasons for not compliting tab (Full screen)
// export const getReasonsData = createSelector(
//   [getExercForReasonsTab],
//   (exersizeAll) => {
//     const exersizes = exersizeAll.filter((item: any) => item.exerciseDone !== null);
//     const dataParsed = exersizes.map((item: any) => JSON.parse(item.exerciseDone));

//     // get unique date for period
//     const dates = [
//       // @ts-ignore
//       ...new Set(dataParsed.map((exercise) => exercise.map((day) => Object.keys(day)[0])).flat()),
//     ];

//     let maxValueYaxis = 0; // need for Reasons chart for YAxis scale

//     const reasonsByDays = dates.map((currentDate, index) => {
//       // All exercises for the date
//       const filteredDataByCurrentDateFromAllExercises = dataParsed
//         .map((exercise: any) => exercise.filter((a: any) => Object.keys(a)[0] === currentDate))
//         .flat();

//       const reasons = filteredDataByCurrentDateFromAllExercises.map((current: any) => {
//         const hasData = !!current[currentDate];

//         const reason = hasData
//           ? {
//             date: currentDate,
//             reasons: current[currentDate].reasons,
//           }
//           : false;
//         return reason;
//       });

//       const mergedReasons = reasons?.map((currentReasons: { date: string; reasons: any}) => ({
//         reasonsByDay:
//           currentReasons && 'reasons' in currentReasons ? currentReasons.reasons : State.defaultReasons,
//       }));

//       let totalReasonsByDay = 0;
//       const reasonValues = State.reasonTitles.map((reasonName: string) => {
//         const result = {
//           [reasonName]: mergedReasons.map((item: any) => {
//             const data = item.reasonsByDay;
//             if (reasonName in data) {
//               return data[reasonName];
//             }
//             return 0;
//           }).reduce((a: any, b: any) => a + b), // day - no matter what is the ex
//         };
//         totalReasonsByDay += result[reasonName];
//         return result;
//       });
//       if (maxValueYaxis < totalReasonsByDay) {
//         maxValueYaxis = totalReasonsByDay;
//       }

//       return {
//         date: currentDate,
//         reasons: reasonValues,
//         id: index + 1,
//         maxValueYaxis,
//       };
//     });
//     console.log('reasonsByDays=', reasonsByDays);

//     return reasonsByDays;
//   },
// );

// Motivation and pain tab (full screen)
export const getMotivationPainData = createSelector(
  getExercForMotivationPainTab,
  getSelectedPeriodMotivationPain,
  getMotivation,
  (exercises, periodDates, motivations: any) => {
    // moved to graph
    // const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
    const painAndMotivationResult: TDataPerPeriod[] = [];
    let emptyData = 0;
    let ticks = 0;

    if (exercises?.length || motivations?.length) {
      const dataParsed = exercises.map((item: any) => ({
        id: item.id,
        name: item.name,
        exerciseDone: JSON.parse(item.exerciseDone),
      }));
      const { periodFrom, dateTo, periodType } = periodDates;
      const dates = getDatesByFormat(periodFrom, dateTo, periodType);
      const painTypes = [
        'sumPainScoreCompleted',
        'sumPainScoreSkip',
        'sumPainScoreStop',
        'exerciseCompleted',
        'exerciseSkipped',
        'exerciseStopped',
        'exerciseSkippedAll',
        'exerciseStoppedAll',
        'sumDesiredNumberOfReps',
        'sumPainScoreBackCompleted',
        'sumPainScoreLegCompleted',
        'sumPainScoreBackSkip',
        'sumPainScoreLegSkip',
        'sumPainScoreBackStop',
        'sumPainScoreLegStop',
      ];
      let periodCounter = 0;

      // Handle all periods
      for (const [, dateArray] of Object.entries(dates)) {
        const dataPerPeriod: TDataPerPeriod = {
          motivation: 0,
          sumPainScoreCompleted: 0,
          sumPainScoreSkip: 0,
          sumPainScoreStop: 0,
          exerciseCompleted: 0,
          exerciseSkipped: 0,
          exerciseStopped: 0,
          exerciseSkippedAll: 0,
          exerciseStoppedAll: 0,
          averagePainScoreCompleted: 0,
          averagePainScoreSkipStop: 0,
          dateArray: '',
          isArchive: false,
          sumDesiredNumberOfReps: 0,
          avDesiredNumberOfReps: 0, // not used in MotivAndPain tab

          // for spinal procedure
          sumPainScoreBackCompleted: 0,
          sumPainScoreLegCompleted: 0,
          sumPainScoreBackSkip: 0,
          sumPainScoreLegSkip: 0,
          sumPainScoreBackStop: 0,
          sumPainScoreLegStop: 0,

          avPainScoreBackCompleted: 0,
          avPainScoreBackSkipStop: 0,
          avPainScoreLegCompleted: 0,
          avPainScoreLegSkipStop: 0,
        };

        let emptyDataInCurrentPeriod = 0;
        let motivationsCounter = 0;

        // Handle one period ********************
        dateArray.map((date: string) => {
          // Motivation
          const motivation = motivations.find((item: any) => item.createdAt.includes(date));
          if (motivation && !motivation.skipped) {
            motivationsCounter += 1;
            dataPerPeriod.motivation += motivation.type;
          }
          // Pain
          dataParsed.map((exercise: any) => {
            // JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
            exercise.exerciseDone.map((exerciseResult: any) => {
              if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
                for (const [key, value] of Object.entries(exerciseResult[date])) {
                  if (painTypes.includes(key)) {
                    dataPerPeriod[key as keyof TAverageMapKey] += exerciseResult[date][key];
                  }
                }
              } else {
                emptyData += 1;
              }
              if (exerciseResult[date] === null) {
                emptyDataInCurrentPeriod += 1;
              }
              ticks += 1;
              return null;
            });
            return null;
          });
          return null;
        });
        // *****************************************

        // Count average motivation per period
        if (motivationsCounter) {
          dataPerPeriod.motivation = Math.round(dataPerPeriod.motivation / motivationsCounter);
        }
        const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
        const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
          + dataPerPeriod.sumPainScoreStop;
        // dataPerPeriod.avDesiredNumberOfReps = dataPerPeriod.exerciseStoppedAll
        //   ? Math.round(dataPerPeriod.sumDesiredNumberOfReps / dataPerPeriod.exerciseStoppedAll)
        //   : 0;
        dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
          ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
          : 0;
        dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
          ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
          : 0;

        /* for Spinal */
        if (dataPerPeriod.exerciseCompleted) {
          dataPerPeriod.avPainScoreBackCompleted = Math.round(
            dataPerPeriod.sumPainScoreBackCompleted / dataPerPeriod.exerciseCompleted,
          );
          dataPerPeriod.avPainScoreLegCompleted = Math.round(
            dataPerPeriod.sumPainScoreLegCompleted / dataPerPeriod.exerciseCompleted,
          );
        }
        if (exercisesSkipStop) {
          const sumPainScoreBackSkipStop = dataPerPeriod.sumPainScoreBackSkip
            + dataPerPeriod.sumPainScoreBackStop;
          dataPerPeriod.avPainScoreBackSkipStop = Math.round(sumPainScoreBackSkipStop
            / exercisesSkipStop);
          const sumPainScoreLegSkipStop = dataPerPeriod.sumPainScoreLegSkip
            + dataPerPeriod.sumPainScoreLegStop;
          dataPerPeriod.avPainScoreLegSkipStop = Math.round(sumPainScoreLegSkipStop
            / exercisesSkipStop);
        }

        periodCounter += 1;
        dataPerPeriod.dateArray = dateArray.length > 1 ? String(periodCounter) : dateArray[0];
        if (dateArray.length === (emptyDataInCurrentPeriod / exercises.length)) {
          dataPerPeriod.isArchive = true;
        }

        // Total for one period
        painAndMotivationResult.push(dataPerPeriod);
      }
    }
    return ticks !== emptyData ? painAndMotivationResult : [];
  },
);
// Motivation and pain tab (full screen)
// export const getMotivationPainData = createSelector(
//   getExercForMotivationPainTab,
//   getSelectedPeriodMotivationPain,
//   getMotivation,
//   (exercises, periodDates, motivations: any) => {
//     // moved to graph
//     // const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
//     const { periodFrom, dateTo, periodType } = periodDates;
//     const dates = getDatesByFormat(periodFrom, dateTo, periodType);
//     const painTypes = [
//       'sumPainScoreCompleted',
//       'sumPainScoreSkip',
//       'sumPainScoreStop',
//       'exerciseCompleted',
//       'exerciseSkipped',
//       'exerciseStopped',
//       'exerciseSkippedAll',
//       'exerciseStoppedAll',
//       'sumDesiredNumberOfReps',
//       'sumPainScoreBackCompleted',
//       'sumPainScoreLegCompleted',
//       'sumPainScoreBackSkip',
//       'sumPainScoreLegSkip',
//       'sumPainScoreBackStop',
//       'sumPainScoreLegStop',
//     ];
//     const averagePainTyps = ['motivation'];
//     const painAndMotivationResult: TDataPerPeriod[] = [];
//     let emptyData = 0;
//     let ticks = 0;

//     if (exercises && exercises.length > 0) {
//       let count = 0;
//       for (const [dateKey, dateArray] of Object.entries(dates)) {

//         const dataPerPeriod: TDataPerPeriod = {
//           motivation: 0,
//           sumPainScoreCompleted: 0,
//           sumPainScoreSkip: 0,
//           sumPainScoreStop: 0,
//           exerciseCompleted: 0,
//           exerciseSkipped: 0,
//           exerciseStopped: 0,
//           exerciseSkippedAll: 0,
//           exerciseStoppedAll: 0,
//           averagePainScoreCompleted: 0,
//           averagePainScoreSkipStop: 0,
//           dateArray: '',
//           isArchive: false,
//           sumDesiredNumberOfReps: 0,
//           avDesiredNumberOfReps: 0,

//           // for spinal procedure
//           sumPainScoreBackCompleted: 0,
//           sumPainScoreLegCompleted: 0,
//           sumPainScoreBackSkip: 0,
//           sumPainScoreLegSkip: 0,
//           sumPainScoreBackStop: 0,
//           sumPainScoreLegStop: 0,

//           avPainScoreBackCompleted: 0,
//           avPainScoreBackSkipStop: 0,
//           avPainScoreLegCompleted: 0,
//           avPainScoreLegSkipStop: 0,
//         };

//         let empty = 0;
//         dateArray.map((date: string) => {
//           const motivation = motivations.find((item: any) => item.createdAt.includes(date));
//           if (motivation) {
//             dataPerPeriod.motivation += +motivation.type;
//           }
//           exercises.map((exercise: any) => {
//             JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
//               if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
//                 for (const [key, value] of Object.entries(exerciseResult[date])) {
//                   if (painTypes.includes(key)) {
//                     dataPerPeriod[key as keyof TAverageMapKey] += exerciseResult[date][key];
//                   }
//                 }
//               } else {
//                 emptyData += 1;
//               }
//               if (exerciseResult[date] === null) {
//                 empty += 1;
//               }
//               ticks += 1;
//               return null;
//             });
//             return null;
//           });
//           const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
//           const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
//             + dataPerPeriod.sumPainScoreStop;
//           if (dataPerPeriod.exerciseStoppedAll) {
//             dataPerPeriod.avDesiredNumberOfReps = Math.round(dataPerPeriod.sumDesiredNumberOfReps
//             / dataPerPeriod.exerciseStoppedAll);
//           }
//           dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
//             ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
//             : 0;
//           dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
//             ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
//             : 0;

//           /* for Spinal */
//           if (dataPerPeriod.exerciseCompleted) {
//             dataPerPeriod.avPainScoreBackCompleted = Math.round(
//               dataPerPeriod.sumPainScoreBackCompleted / dataPerPeriod.exerciseCompleted,
//             );
//             dataPerPeriod.avPainScoreLegCompleted = Math.round(
//               dataPerPeriod.sumPainScoreLegCompleted / dataPerPeriod.exerciseCompleted,
//             );
//           }
//           if (exercisesSkipStop) {
//             const sumPainScoreBackSkipStop = dataPerPeriod.sumPainScoreBackSkip
//               + dataPerPeriod.sumPainScoreBackStop;
//             dataPerPeriod.avPainScoreBackSkipStop = Math.round(sumPainScoreBackSkipStop
//               / exercisesSkipStop);
//             const sumPainScoreLegSkipStop = dataPerPeriod.sumPainScoreLegSkip
//               + dataPerPeriod.sumPainScoreLegStop;
//             dataPerPeriod.avPainScoreLegSkipStop = Math.round(sumPainScoreLegSkipStop
//               / exercisesSkipStop);
//           }
//           return null;
//         });

//         if (dateArray.length) {
//           averagePainTyps.map((key: string) => {
//             dataPerPeriod[key as keyof TAverageMapKey] = Math.round(
//               dataPerPeriod[key as keyof TAverageMapKey] / dateArray.length,
//             );
//             return null;
//           });
//         } else {
//           averagePainTyps.map((key: string) => {
//             dataPerPeriod[key as keyof TAverageMapKey] = 0;
//             return null;
//           });
//         }
//         count += 1;
//         dataPerPeriod.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
//         if (dateArray.length === (empty / exercises.length)) {
//           dataPerPeriod.isArchive = true;
//         }
//         painAndMotivationResult.push(dataPerPeriod);
//       }
//     }
//     return ticks !== emptyData ? painAndMotivationResult : [];
//   },
// );

// OVERVIEW TAB
// Reasons for not compliting chart (Overview tab)
// Old logik
// export const getReasonsDataOverviewTab = createSelector(
//   [getExercisesOverviewTab], (exercisesAll) => {
//     const exersizes = exercisesAll.filter((item: any) => item.exerciseDone !== null);
//     const data = exersizes.map((item: any) => JSON.parse(item.exerciseDone));

//     const dates = [
//       // @ts-ignore
//       ...new Set(data.map((exercise) => exercise.map((day) => Object.keys(day)[0])).flat()),
//     ];
//     let maxValueYaxis = 0; // need for Reasons chart for YAxis scale
//     return dates.map((currentDate, index) => {
//       const filteredDataByCurrentDateFromAllExercises = data
//         // @ts-ignore
//         .map((currentExercises) => currentExercises.filter((a) => Object.keys(a)[0] === currentDate))
//         .flat();

//       const reasons = filteredDataByCurrentDateFromAllExercises.map((current: any) => {
//         const hasData = !!current[currentDate];

//         const reasons = hasData
//           ? [
//             {
//               date: currentDate,
//               reasons: current[currentDate].reasons,
//               // painLevel: current[currentDate].painLevel,
//               // status: current[currentDate].status,
//               // timesPerDay: current[currentDate].timesPerDay,
//             },
//           ]
//           : false;
//         return reasons;
//       });

//       const mergedReasons = reasons
//         && reasons.map((currentReasons: any) => {
//           const response = currentReasons[0];
//           // const timesPerDay = response && response.timesPerDay ? response.timesPerDay : 0;

//           return {
//             reasonsByDay:
//               currentReasons && 'reasons' in response ? response.reasons : State.defaultReasons,
//             // statusByDay:
//             //   currentReasons && 'status' in response ? response.status : State.defaultStatuses,
//             // paintByDay:
//             //   currentReasons && 'painLevel' in response
//             //     ? response.painLevel
//             //     : State.defaultStatuses,
//             // timesPerDay,
//           };
//         });

//       // const avarageValue = mergedReasons
//       //   .map((item: any) => {
//       //     const data = item.timesPerDay;
//       //     return data || 0;
//       //   })
//       //   .reduce((a: any, b: any) => a + b);

//       // const statusValue = State.statusDefault.map((currentStatus: string) => ({
//       //   [currentStatus]: mergedReasons
//       //     .map((item: any) => {
//       //       const data = item.statusByDay;
//       //       return currentStatus in data ? data[currentStatus] : 0;
//       //     })
//       //     .reduce((a: any, b: any) => {
//       //       const awarage = a + b;
//       //       return awarage;
//       //     }),
//       // }));

//       // const painValues = State.exercisePainLevel.map((currentPainLevel: string) => ({
//       //   [currentPainLevel]: mergedReasons
//       //     .map((item: any) => {
//       //       const dataPain = item.paintByDay;

//       //       if (currentPainLevel in dataPain) {
//       //         return dataPain[currentPainLevel];
//       //       }
//       //       return 0;
//       //     })
//       //     .reduce((a: any, b: any) => a + b),
//       // }));
//       let totalReasonsByDay = 0;
//       const reasonValues = State.reasonTitles.map((currentReason) => {
//         const result = ({
//           [currentReason]: mergedReasons
//             .map((item: any) => {
//               const dataReas = item.reasonsByDay;
//               if (currentReason in dataReas) {
//                 return dataReas[currentReason];
//               }
//               return 0;
//             })
//             .reduce((a: any, b: any) => a + b), // day - no metter what is the ex
//         });
//         totalReasonsByDay += result[currentReason];
//         return result;
//       });
//       if (maxValueYaxis < totalReasonsByDay) {
//         maxValueYaxis = totalReasonsByDay;
//       }

//       // const number = Math.random().toString(36).substr(2, 9); //

//       return {
//         date: currentDate,
//         reasons: reasonValues,
//         // painLevel: painValues,
//         // status: statusValue,
//         // timesPerDay: avarageValue,
//         // step,
//         // motivations,
//         id: index + 1,
//         // uncName: { [number]: index + 1 },
//         maxValueYaxis,
//       };
//     });
//   },
// );

// Motivation & Pain (Overview tab)
export const getMotivationPainDataOverviewTab = createSelector(
  getSelectedPeriodOverviewTab,
  getExercisesOverviewTab,
  getMotivationOverviewTab,
  (periodDates, exercises, motivations: any) => {
    const painAndMotivationResult: TDataPerPeriod[] = [];
    let emptyData = 0;
    let ticks = 0;

    if (exercises?.length || motivations?.length) {
      const { periodFrom, dateTo, periodType } = periodDates;
      const dates = getDatesByFormat(periodFrom, dateTo, periodType);
      const painTypes = [
        'sumPainScoreCompleted',
        'sumPainScoreSkip',
        'sumPainScoreStop',
        'exerciseCompleted',
        'exerciseSkipped',
        'exerciseStopped',
        'exerciseSkippedAll',
        'exerciseStoppedAll',
        'sumDesiredNumberOfReps',
        'sumPainScoreBackCompleted',
        'sumPainScoreLegCompleted',
        'sumPainScoreBackSkip',
        'sumPainScoreLegSkip',
        'sumPainScoreBackStop',
        'sumPainScoreLegStop',
      ];
      let periodCounter = 0;

      // Handle all periods
      for (const [, dateArray] of Object.entries(dates)) {
        const dataPerPeriod: TDataPerPeriod = {
          motivation: 0,
          sumPainScoreCompleted: 0,
          sumPainScoreSkip: 0,
          sumPainScoreStop: 0,
          exerciseCompleted: 0,
          exerciseSkipped: 0,
          exerciseStopped: 0,
          exerciseSkippedAll: 0,
          exerciseStoppedAll: 0,
          averagePainScoreCompleted: 0,
          averagePainScoreSkipStop: 0,
          dateArray: '',
          isArchive: false,
          sumDesiredNumberOfReps: 0,
          avDesiredNumberOfReps: 0, // not used in MotivAndPain tab

          // for spinal procedure
          sumPainScoreBackCompleted: 0,
          sumPainScoreLegCompleted: 0,
          sumPainScoreBackSkip: 0,
          sumPainScoreLegSkip: 0,
          sumPainScoreBackStop: 0,
          sumPainScoreLegStop: 0,

          avPainScoreBackCompleted: 0,
          avPainScoreBackSkipStop: 0,
          avPainScoreLegCompleted: 0,
          avPainScoreLegSkipStop: 0,
        };

        let emptyDataInCurrentPeriod = 0;
        let motivationsCounter = 0;

        // Handle one period ********************
        dateArray.map((date: string) => {
          // Motivation
          const motivation = motivations.find((item: any) => item.createdAt.includes(date));
          if (motivation && !motivation.skipped) {
            motivationsCounter += 1;
            dataPerPeriod.motivation += motivation.type;
          }
          // Pain
          exercises.map((exercise: any) => {
            exercise.exerciseDone.map((exerciseResult: any) => {
              if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
                for (const [key, value] of Object.entries(exerciseResult[date])) {
                  if (painTypes.includes(key)) {
                    dataPerPeriod[key as keyof TAverageMapKey] += exerciseResult[date][key];
                  }
                }
              } else {
                emptyData += 1;
              }
              if (exerciseResult[date] === null) {
                emptyDataInCurrentPeriod += 1;
              }
              ticks += 1;
              return null;
            });
            return null;
          });
          return null;
        });
        // *****************************************

        // Count average motivation per period
        if (motivationsCounter) {
          dataPerPeriod.motivation = Math.round(dataPerPeriod.motivation / motivationsCounter);
        }
        const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
        const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
          + dataPerPeriod.sumPainScoreStop;
        // dataPerPeriod.avDesiredNumberOfReps = dataPerPeriod.exerciseStoppedAll
        //   ? Math.round(dataPerPeriod.sumDesiredNumberOfReps / dataPerPeriod.exerciseStoppedAll)
        //   : 0;
        dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
          ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
          : 0;
        dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
          ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
          : 0;

        /* for Spinal */
        if (dataPerPeriod.exerciseCompleted) {
          dataPerPeriod.avPainScoreBackCompleted = Math.round(
            dataPerPeriod.sumPainScoreBackCompleted / dataPerPeriod.exerciseCompleted,
          );
          dataPerPeriod.avPainScoreLegCompleted = Math.round(
            dataPerPeriod.sumPainScoreLegCompleted / dataPerPeriod.exerciseCompleted,
          );
        }
        if (exercisesSkipStop) {
          const sumPainScoreBackSkipStop = dataPerPeriod.sumPainScoreBackSkip
            + dataPerPeriod.sumPainScoreBackStop;
          dataPerPeriod.avPainScoreBackSkipStop = Math.round(sumPainScoreBackSkipStop
            / exercisesSkipStop);
          const sumPainScoreLegSkipStop = dataPerPeriod.sumPainScoreLegSkip
            + dataPerPeriod.sumPainScoreLegStop;
          dataPerPeriod.avPainScoreLegSkipStop = Math.round(sumPainScoreLegSkipStop
            / exercisesSkipStop);
        }

        periodCounter += 1;
        dataPerPeriod.dateArray = dateArray.length > 1 ? String(periodCounter) : dateArray[0];
        if (dateArray.length === (emptyDataInCurrentPeriod / exercises.length)) {
          dataPerPeriod.isArchive = true;
        }

        // Total for one period
        painAndMotivationResult.push(dataPerPeriod);
      }
    }
    return ticks !== emptyData ? painAndMotivationResult : [];
  },
);
// export const getMotivationPainDataOverviewTab = createSelector(
//   getExercisesOverviewTab,
//   getSelectedPeriodOverviewTab,
//   getMotivationOverviewTab,
//   (exercisesArrayAll, periodDates, motivations: any) => {
//     const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);

//     const { periodFrom, dateTo, periodType } = periodDates;
//     const dates = getDatesByFormat(periodFrom, dateTo, periodType);
//     const painTypes = [
//       'sumPainScoreCompleted',
//       'sumPainScoreSkip',
//       'sumPainScoreStop',
//       'exerciseCompleted',
//       'exerciseSkipped',
//       'exerciseStopped',
//       'exerciseSkippedAll',
//       'exerciseStoppedAll',
//       'sumDesiredNumberOfReps',
//       'sumPainScoreBackCompleted',
//       'sumPainScoreLegCompleted',
//       'sumPainScoreBackSkip',
//       'sumPainScoreLegSkip',
//       'sumPainScoreBackStop',
//       'sumPainScoreLegStop',
//     ];
//     // const averagePainTyps = ['motivation'];
//     const painAndMotivationResult: TDataPerPeriod[] = [];
//     let emptyData = 0;
//     let ticks = 0;

//     if (exercises && exercises.length > 0) {
//       // let count = 0;
//       for (const [dateKey, dateArray] of Object.entries(dates)) {
//         const dataPerPeriod: TDataPerPeriod = {
//           motivation: 0,
//           sumPainScoreCompleted: 0,
//           sumPainScoreSkip: 0,
//           sumPainScoreStop: 0,
//           exerciseCompleted: 0,
//           exerciseSkipped: 0,
//           exerciseStopped: 0,
//           exerciseSkippedAll: 0,
//           exerciseStoppedAll: 0,
//           averagePainScoreCompleted: 0,
//           averagePainScoreSkipStop: 0,
//           dateArray: '',
//           isArchive: false,
//           sumDesiredNumberOfReps: 0,
//           avDesiredNumberOfReps: 0,
//           sumPainScoreBackCompleted: 0,
//           sumPainScoreLegCompleted: 0,
//           sumPainScoreBackSkip: 0,
//           sumPainScoreLegSkip: 0,
//           sumPainScoreBackStop: 0,
//           sumPainScoreLegStop: 0,
//           avPainScoreBackCompleted: 0,
//           avPainScoreBackSkipStop: 0,
//           avPainScoreLegCompleted: 0,
//           avPainScoreLegSkipStop: 0,
//         };

//         let empty = 0;
//         dateArray.map((date: string) => {
//           const motivation = motivations.find((item: any) => item.createdAt.includes(date));
//           if (motivation) {
//             dataPerPeriod.motivation += +motivation.type;
//           }
//           exercises.map((exercise: any) => {
//             JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
//               if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
//                 for (const [key, value] of Object.entries(exerciseResult[date])) {
//                   if (painTypes.includes(key)) {
//                     dataPerPeriod[key as keyof TAverageMapKey] += exerciseResult[date][key];
//                   }
//                 }
//               } else {
//                 emptyData += 1;
//               }
//               if (exerciseResult[date] === null) {
//                 empty += 1;
//               }
//               ticks += 1;
//               return null;
//             });
//             return null;
//           });
//           const exercisesSkipStop = dataPerPeriod.exerciseSkipped + dataPerPeriod.exerciseStopped;
//           const sumPainScoreSkipStop = dataPerPeriod.sumPainScoreSkip
//             + dataPerPeriod.sumPainScoreStop;
//           if (dataPerPeriod.exerciseStoppedAll) {
//             dataPerPeriod.avDesiredNumberOfReps = Math.round(dataPerPeriod.sumDesiredNumberOfReps
//             / dataPerPeriod.exerciseStoppedAll);
//           }
//           dataPerPeriod.averagePainScoreCompleted = dataPerPeriod.exerciseCompleted
//             ? Math.round(dataPerPeriod.sumPainScoreCompleted / dataPerPeriod.exerciseCompleted)
//             : 0;
//           dataPerPeriod.averagePainScoreSkipStop = exercisesSkipStop
//             ? Math.round(sumPainScoreSkipStop / exercisesSkipStop)
//             : 0;

//           /* for Spinal */
//           if (dataPerPeriod.exerciseCompleted) {
//             dataPerPeriod.avPainScoreBackCompleted = Math.round(
//               dataPerPeriod.sumPainScoreBackCompleted / dataPerPeriod.exerciseCompleted,
//             );
//             dataPerPeriod.avPainScoreLegCompleted = Math.round(
//               dataPerPeriod.sumPainScoreLegCompleted / dataPerPeriod.exerciseCompleted,
//             );
//           }
//           if (exercisesSkipStop) {
//             const sumPainScoreBackSkipStop = dataPerPeriod.sumPainScoreBackSkip
//               + dataPerPeriod.sumPainScoreBackStop;
//             dataPerPeriod.avPainScoreBackSkipStop = Math.round(sumPainScoreBackSkipStop
//               / exercisesSkipStop);
//             const sumPainScoreLegSkipStop = dataPerPeriod.sumPainScoreLegSkip
//               + dataPerPeriod.sumPainScoreLegStop;
//             dataPerPeriod.avPainScoreLegSkipStop = Math.round(sumPainScoreLegSkipStop
//               / exercisesSkipStop);
//           }
//           return null;
//         });

//         // if (dateArray.length) {
//         //   averagePainTyps.map((key: string) => {
//         //     dataPerPeriod[key as keyof TAverageMapKey] = Math.round(
//         //       dataPerPeriod[key as keyof TAverageMapKey] / dateArray.length,
//         //     );
//         //     return null;
//         //   });
//         // } else {
//         //   averagePainTyps.map((key: string) => {
//         //     dataPerPeriod[key as keyof TAverageMapKey] = 0;
//         //     return null;
//         //   });
//         // }
//         // count += 1;
//         // dataPerPeriod.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
//         dataPerPeriod.dateArray = dateArray[0];
//         if (dateArray.length === (empty / exercises.length)) {
//           dataPerPeriod.isArchive = true;
//         }
//         painAndMotivationResult.push(dataPerPeriod);
//       }
//     }
//     return ticks !== emptyData ? painAndMotivationResult : [];
//   },
// );

// Compl & steps (Overview tab - Steps)
export const getStepsDataOverviewTab = createSelector(
  getStepsOverviewTab,
  getSelectedPeriodOverviewTab,
  (steps, period) => {
    const stepsArrayResult = [];

    if (steps?.length) {
      const { periodFrom, dateTo, periodType } = period;
      const dates = getDatesByFormat(periodFrom, dateTo, periodType);
      const averageSteps = [];
      let count = 0;

      for (const [, dateArray] of Object.entries(dates)) {
        const stepsForPeriodResult: any = { steps: 0 };
        let sumValue = 0;
        dateArray.map((date: string) => {
          const step: StepType = steps
            .find((stepOne: StepType) => stepOne.updatedAt.indexOf(date) > -1);
          if (step) {
            sumValue += step.steps;
          }
          return null;
        });
        stepsForPeriodResult.steps = Math.round(sumValue / dateArray.length);
        averageSteps.push(stepsForPeriodResult);
      }
      for (const [dataKey, dateArray] of Object.entries(dates)) {
        const stepResult: any = {
          steps: 0,
          chartValue: 0,
          dateSteps: '',
        };

        let sumValue = 0;
        dateArray.map((date: string) => {
          // @ts-ignore
          const step: StepType = steps.find((stp: StepType[]) => stp.updatedAt.indexOf(date) > -1);
          if (step) {
            sumValue += step.steps;
          }
          return null;
        });

        stepResult.steps = Math.round(sumValue / dateArray.length);
        stepResult.chartValue = Math.round((stepResult.steps / maxStepScale(averageSteps)) * 100);
        stepResult.dateSteps = dateArray.length === 1 ? dateArray[0] : dataKey;
        count += 1;
        stepResult.dateArray = dateArray.length > 1 ? String(count) : dateArray[0];
        stepsArrayResult.push(stepResult);
      }
    }
    return stepsArrayResult;
  },
);

// Complitation by periods (Overview tab - exercises)
export const getExercDataOverviewTab = createSelector(
  getSelectedPeriodOverviewTab,
  getExercisesOverviewTab,
  (getSelectedDates, exercises) => {
    const reasonsByPeriods: any = [];

    if (exercises.length) {
      const { periodFrom, dateTo, periodType, value } = getSelectedDates;
      const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
      const resultByDayAndExercises: any = {};
      const resultByAveragePeriod: any = {};
      let isEmptyData = 0;
      let ticks = 0;
      let periodNumber = 0;

      for (const [dataKey, dateArray] of Object.entries(calcDates)) {
        // reasons logik start
        // Create reasons default values {key: 0}
        const reasons: any = State.reasonTitles
          .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
        let maxValueYaxis = 0;
        // reasons logik end

        dateArray.map((date: string) => {
          exercises.map((exercise: any) => {
            const exercisesResult: any = {
              status: {
                completed: 0,
                partlyDone: 0,
                attempts: 0,
                noAttempt: 0,
                isNull: 0,
              },
              // tooltipStatus: {
              //   completed: 0,
              //   partlyDone: 0,
              //   attempts: 0,
              //   noAttempt: 0,
              //   isNull: 0,
              // },
              name: exercise.name,
            };
            exercise.exerciseDone.map((exerciseResult: any) => {
              const { status } = exercisesResult;

              /* Reasons logik start */
              if (exerciseResult[date]) {
                State.reasonTitles.map((reasonName: string) => {
                  const reasonFromData = exerciseResult[date].reasons?.[reasonName];
                  if (reasonFromData) {
                    const accCurrentReason = reasons[reasonName] + reasonFromData;
                    reasons[reasonName] = accCurrentReason;
                    maxValueYaxis += reasonFromData;
                  }
                  return null;
                });
              }
              /* Reasons logik end */

              if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
                for (const [key, val] of Object.entries(exerciseResult[date])) {
                  let sumPreviousValues: any = 0;
                  let sumValue: any = 0;
                  if (typeof val === 'object') {
                    for (const [key2, value2] of Object.entries(exerciseResult[date][key])) {
                      if (key === 'status') {
                        sumValue += value2;
                        if (sumValue > 100) {
                          // tooltipStatus[key2] = 100 - sumPreviousValues;
                          status[key2] = 100 - sumPreviousValues;
                          break;
                        }
                        sumPreviousValues += value2;
                        // tooltipStatus[key2] = value2;
                        status[key2] = value2;
                      }
                    }
                  }
                }
              } else {
                isEmptyData += 1;
              }
              if (exerciseResult[date] === null) {
                // tooltipStatus.isNull = 100;
                status.isNull = 100;
              }

              ticks += 1;

              status.noAttempt = 0;
              // tooltipStatus.noAttempt = 0;
              // @ts-ignore
              // tooltipStatus.noAttempt = Object.values(tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(tooltipStatus).reduce((a, b) => a + b, 0) : 0;
              // @ts-ignore
              status.noAttempt = Object.values(status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(status).reduce((a, b) => a + b, 0) : 0;
              return null;
            });

            if (!resultByDayAndExercises[date]) {
              resultByDayAndExercises[date] = [];
            }
            // exercisesResult.tooltipStatus = { ...exercisesResult.status };
            resultByDayAndExercises[date].push(exercisesResult);
            return null;
          });
          return null;
        });

        /* Reasons logik start */
        periodNumber += 1;
        const resultByPeriod: any = {
          date: dateArray.length === 1 ? dateArray[0] : periodNumber,
          id: periodNumber,
          maxValueYaxis,
          reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
        };
        reasonsByPeriods.push(resultByPeriod);
        /* Reasons logik end */

        // By weeks or months
        if (dateArray.length > 1) {
          let exercisesDataPeriod: any[] = [];
          exercises.forEach((exercise: any, index: number) => {
            const totalStatusesForTheEntirePeriod: any = {
              status: {
                completed: 0,
                partlyDone: 0,
                attempts: 0,
                noAttempt: 0,
                isNull: 0,
              },
              // tooltipStatus: {
              //   completed: 0,
              //   partlyDone: 0,
              //   attempts: 0,
              //   noAttempt: 0,
              //   isNull: 0,
              // },
              name: '',
            };
            dateArray.map((day: any) => {
              for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
                totalStatusesForTheEntirePeriod.status[key] += resultByDayAndExercises[day][index].status[key];
                // totalStatusesForTheEntirePeriod.tooltipStatus[key] += resultByDayAndExercises[day][index].tooltipStatus[key];
              }
              totalStatusesForTheEntirePeriod.name = resultByDayAndExercises[day][index].name;
              return null;
            });
            for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
              // @ts-ignore
              totalStatusesForTheEntirePeriod.status[key] = Math.round(value / (dateArray.length));
              if (key === 'isNull' && totalStatusesForTheEntirePeriod.status[key] < 100) {
                totalStatusesForTheEntirePeriod.status[key] = 0;
              }
            }
            // for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
            //   // @ts-ignore
            //   totalStatusesForTheEntirePeriod.tooltipStatus[key] = Math.round(value / (dateArray.length));
            //   if (key === 'isNull' && totalStatusesForTheEntirePeriod.tooltipStatus[key] < 100) {
            //     totalStatusesForTheEntirePeriod.tooltipStatus[key] = 0;
            //   }
            // }

            totalStatusesForTheEntirePeriod.status.noAttempt = 0;
            // totalStatusesForTheEntirePeriod.tooltipStatus.noAttempt = 0;
            let sumPreviousValuesAverage = 0;
            let sumValueAverage = 0;

            for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
              // @ts-ignore
              sumValueAverage += value2;
              // @ts-ignore
              if (sumValueAverage > 100) {
                totalStatusesForTheEntirePeriod.status[key2] = 100 - sumPreviousValuesAverage;
                break;
              }
              // @ts-ignore
              sumPreviousValuesAverage += value2;
              totalStatusesForTheEntirePeriod.status[key2] = value2;
            }

            // let sumPreviousValuesAverageTooltip = 0;
            // let sumValueAverageTooltip = 0;
            // for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
            //   // @ts-ignore
            //   sumValueAverageTooltip += value2;
            //   // @ts-ignore
            //   if (sumValueAverageTooltip > 100) {
            //     totalStatusesForTheEntirePeriod.tooltipStatus[key2] = 100 - sumPreviousValuesAverageTooltip;
            //     break;
            //   }
            //   // @ts-ignore
            //   sumPreviousValuesAverageTooltip += value2;
            //   totalStatusesForTheEntirePeriod.tooltipStatus[key2] = value2;
            // }
            // @ts-ignore
            totalStatusesForTheEntirePeriod.status.noAttempt = Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) : 0;
            // @ts-ignore
            // totalStatusesForTheEntirePeriod.tooltipStatus.noAttempt = Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
            // totalStatusesForTheEntirePeriod.tooltipStatus = { ...totalStatusesForTheEntirePeriod.status };
            exercisesDataPeriod.push(totalStatusesForTheEntirePeriod);
          });

          if (value === LAST_3_MONTHS || value === LAST_6_MONTHS) {
            const averageStatusesByWeek: any = {
              status: {
                completed: 0,
                partlyDone: 0,
                attempts: 0,
                noAttempt: 0,
                isNull: 0,
              },
              // tooltipStatus: {
              //   completed: 0,
              //   partlyDone: 0,
              //   attempts: 0,
              //   noAttempt: 0,
              //   isNull: 0,
              // },
            };
            let counter = 0;
            exercisesDataPeriod.forEach((exercise, index) => {
              for (const [key, value] of Object.entries(averageStatusesByWeek)) {
                for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
                  if (key2 !== 'isNull') {
                    averageStatusesByWeek[key][key2] += exercise[key][key2];
                  } else {
                    averageStatusesByWeek[key][key2] += exercise[key][key2];
                    if (exercise[key][key2] === 100) {
                      counter += 1;
                    }
                  }
                }
                for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
                  if (index === exercisesDataPeriod.length - 1) {
                    averageStatusesByWeek[key][key2] = Math.round(
                      averageStatusesByWeek[key][key2] / (exercisesDataPeriod.length - counter / 2),
                    );
                    if (key2 !== 'isNull') {
                      if (isNaN(averageStatusesByWeek[key][key2])) {
                        averageStatusesByWeek[key][key2] = 0;
                      }
                    } else if (isNaN(averageStatusesByWeek[key][key2])) {
                      averageStatusesByWeek[key][key2] = 100;
                    }
                    if (key2 === 'isNull' && averageStatusesByWeek[key][key2] < 100) {
                      averageStatusesByWeek[key][key2] = 0;
                    }
                  }
                }
              }
            });

            let sumValue: any = 0;
            let previousValue: any = 0;
            for (const [key, value] of Object.entries(averageStatusesByWeek.status)) {
              sumValue += value;
              if (sumValue > 100) {
                averageStatusesByWeek.status[key] = 100 - previousValue;
                break;
              }
              previousValue += value;
              averageStatusesByWeek.status[key] = value;
            }
            averageStatusesByWeek.status.noAttempt = 0;
            // @ts-ignore
            averageStatusesByWeek.status.noAttempt = Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) : 0;
            // averageStatusesByWeek.tooltipStatus.noAttempt = 0;
            // @ts-ignore
            // averageStatusesByWeek.tooltipStatus.noAttempt = Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
            // averageStatusesByWeek.tooltipStatus = { ...averageStatusesByWeek.status };
            exercisesDataPeriod = [averageStatusesByWeek];
          }
          resultByAveragePeriod[dataKey] = exercisesDataPeriod;
        }
      }

      if (Object.keys(resultByAveragePeriod).length !== 0) {
        return ticks === isEmptyData
          ? { reasonsByPeriods, completationData: {} }
          : { reasonsByPeriods, completationData: resultByAveragePeriod };
        // return ticks === isEmptyData ? {} : resultByAveragePeriod;
      }
      return { reasonsByPeriods, completationData: resultByDayAndExercises };
    }
    return { reasonsByPeriods, completationData: {} };
  },
);
/*****************************27sec */
// Complitation by periods (Overview tab - exercises)
// export const getExercDataOverviewTab = createSelector(
//   getSelectedPeriodOverviewTab,
//   getExercisesOverviewTab,
//   (getSelectedDates, exercises) => {
//     const reasonsByPeriods: any = [];
//     const { periodFrom, dateTo, periodType, value } = getSelectedDates;

//     if (exercises.length) {
//       const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
//       const resultByDayAndExercises: any = {};
//       const resultByAveragePeriod: any = {};
//       let isEmptyData = 0;
//       let ticks = 0;
//       let periodNumber = 0;

//       for (const [dataKey, dateArray] of Object.entries(calcDates)) {
//         // reasons logik start
//         // Create reasons default values {key: 0}
//         const reasons: any = State.reasonTitles
//           .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
//         let maxValueYaxis = 0;
//         // reasons logik end

//         dateArray.map((date: string) => {
//           exercises.map((exercise: any) => {
//             const exercisesResult: any = {
//               status: {
//                 completed: 0,
//                 partlyDone: 0,
//                 attempts: 0,
//                 noAttempt: 0,
//                 isNull: 0,
//               },
//               tooltipStatus: {
//                 completed: 0,
//                 partlyDone: 0,
//                 attempts: 0,
//                 noAttempt: 0,
//                 isNull: 0,
//               },
//               name: exercise.name,
//             };
//             JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
//               const { status, tooltipStatus } = exercisesResult;

//               /* Reasons logik start */
//               if (exerciseResult[date]) {
//                 State.reasonTitles.map((reasonName: string) => {
//                   const reasonFromData = exerciseResult[date].reasons?.[reasonName];
//                   if (reasonFromData) {
//                     const accCurrentReason = reasons[reasonName] + reasonFromData;
//                     reasons[reasonName] = accCurrentReason;
//                     maxValueYaxis += reasonFromData;
//                   }
//                   return null;
//                 });
//               }
//               /* Reasons logik end */

//               if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
//                 for (const [key, val] of Object.entries(exerciseResult[date])) {
//                   let sumPreviousValues: any = 0;
//                   let sumValue: any = 0;
//                   if (typeof val === 'object') {
//                     for (const [key2, value2] of Object.entries(exerciseResult[date][key])) {
//                       if (key === 'status') {
//                         sumValue += value2;
//                         if (sumValue > 100) {
//                           tooltipStatus[key2] = 100 - sumPreviousValues;
//                           status[key2] = 100 - sumPreviousValues;
//                           break;
//                         }
//                         sumPreviousValues += value2;
//                         tooltipStatus[key2] = value2;
//                         status[key2] = value2;
//                       }
//                     }
//                   }
//                 }
//               } else {
//                 isEmptyData += 1;
//               }
//               if (exerciseResult[date] === null) {
//                 tooltipStatus.isNull = 100;
//                 status.isNull = 100;
//               }

//               ticks += 1;

//               status.noAttempt = 0;
//               tooltipStatus.noAttempt = 0;
//               // @ts-ignore
//               tooltipStatus.noAttempt = Object.values(tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(tooltipStatus).reduce((a, b) => a + b, 0) : 0;
//               // @ts-ignore
//               status.noAttempt = Object.values(status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(status).reduce((a, b) => a + b, 0) : 0;
//               return null;
//             });

//             if (!resultByDayAndExercises[date]) {
//               resultByDayAndExercises[date] = [];
//             }
//             resultByDayAndExercises[date].push(exercisesResult);
//             return null;
//           });
//           return null;
//         });

//         /* Reasons logik start */
//         periodNumber += 1;
//         const resultByPeriod: any = {
//           date: dateArray.length === 1 ? dateArray[0] : periodNumber,
//           id: periodNumber,
//           maxValueYaxis,
//           reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
//         };
//         reasonsByPeriods.push(resultByPeriod);
//         /* Reasons logik end */

//         // By weeks or months
//         if (dateArray.length > 1) {
//           let exercisesDataPeriod: any[] = [];
//           exercises.forEach((exercise: any, index: number) => {
//             const totalStatusesForTheEntirePeriod: any = {
//               status: {
//                 completed: 0,
//                 partlyDone: 0,
//                 attempts: 0,
//                 noAttempt: 0,
//                 isNull: 0,
//               },
//               tooltipStatus: {
//                 completed: 0,
//                 partlyDone: 0,
//                 attempts: 0,
//                 noAttempt: 0,
//                 isNull: 0,
//               },
//               name: '',
//             };
//             dateArray.map((day: any) => {
//               for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
//                 totalStatusesForTheEntirePeriod.status[key] += resultByDayAndExercises[day][index].status[key];
//                 totalStatusesForTheEntirePeriod.tooltipStatus[key] += resultByDayAndExercises[day][index].tooltipStatus[key];
//               }
//               totalStatusesForTheEntirePeriod.name = resultByDayAndExercises[day][index].name;
//               return null;
//             });
//             for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
//               // @ts-ignore
//               totalStatusesForTheEntirePeriod.status[key] = Math.round(value / (dateArray.length));
//               if (key === 'isNull' && totalStatusesForTheEntirePeriod.status[key] < 100) {
//                 totalStatusesForTheEntirePeriod.status[key] = 0;
//               }
//             }
//             for (const [key, value] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
//               // @ts-ignore
//               totalStatusesForTheEntirePeriod.tooltipStatus[key] = Math.round(value / (dateArray.length));
//               if (key === 'isNull' && totalStatusesForTheEntirePeriod.tooltipStatus[key] < 100) {
//                 totalStatusesForTheEntirePeriod.tooltipStatus[key] = 0;
//               }
//             }

//             totalStatusesForTheEntirePeriod.status.noAttempt = 0;
//             totalStatusesForTheEntirePeriod.tooltipStatus.noAttempt = 0;
//             let sumPreviousValuesAverage = 0;
//             let sumValueAverage = 0;

//             for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.status)) {
//               // @ts-ignore
//               sumValueAverage += value2;
//               // @ts-ignore
//               if (sumValueAverage > 100) {
//                 totalStatusesForTheEntirePeriod.status[key2] = 100 - sumPreviousValuesAverage;
//                 break;
//               }
//               // @ts-ignore
//               sumPreviousValuesAverage += value2;
//               totalStatusesForTheEntirePeriod.status[key2] = value2;
//             }

//             let sumPreviousValuesAverageTooltip = 0;
//             let sumValueAverageTooltip = 0;
//             for (const [key2, value2] of Object.entries(totalStatusesForTheEntirePeriod.tooltipStatus)) {
//               // @ts-ignore
//               sumValueAverageTooltip += value2;
//               // @ts-ignore
//               if (sumValueAverageTooltip > 100) {
//                 totalStatusesForTheEntirePeriod.tooltipStatus[key2] = 100 - sumPreviousValuesAverageTooltip;
//                 break;
//               }
//               // @ts-ignore
//               sumPreviousValuesAverageTooltip += value2;
//               totalStatusesForTheEntirePeriod.tooltipStatus[key2] = value2;
//             }
//             // @ts-ignore
//             totalStatusesForTheEntirePeriod.status.noAttempt = Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.status).reduce((a, b) => a + b, 0) : 0;
//             // @ts-ignore
//             totalStatusesForTheEntirePeriod.tooltipStatus.noAttempt = Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(totalStatusesForTheEntirePeriod.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
//             exercisesDataPeriod.push(totalStatusesForTheEntirePeriod);
//           });

//           if (value === LAST_3_MONTHS || value === LAST_6_MONTHS) {
//             const averageStatusesByWeek: any = {
//               status: {
//                 completed: 0,
//                 partlyDone: 0,
//                 attempts: 0,
//                 noAttempt: 0,
//                 isNull: 0,
//               },
//               tooltipStatus: {
//                 completed: 0,
//                 partlyDone: 0,
//                 attempts: 0,
//                 noAttempt: 0,
//                 isNull: 0,
//               },
//             };
//             let counter = 0;
//             exercisesDataPeriod.forEach((exercise, index) => {
//               for (const [key, value] of Object.entries(averageStatusesByWeek)) {
//                 for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
//                   if (key2 !== 'isNull') {
//                     averageStatusesByWeek[key][key2] += exercise[key][key2];
//                   } else {
//                     averageStatusesByWeek[key][key2] += exercise[key][key2];
//                     if (exercise[key][key2] === 100) {
//                       counter += 1;
//                     }
//                   }
//                 }
//                 for (const [key2, value2] of Object.entries(averageStatusesByWeek[key])) {
//                   if (index === exercisesDataPeriod.length - 1) {
//                     averageStatusesByWeek[key][key2] = Math.round(
//                       averageStatusesByWeek[key][key2] / (exercisesDataPeriod.length - counter / 2),
//                     );
//                     if (key2 !== 'isNull') {
//                       if (isNaN(averageStatusesByWeek[key][key2])) {
//                         averageStatusesByWeek[key][key2] = 0;
//                       }
//                     } else if (isNaN(averageStatusesByWeek[key][key2])) {
//                       averageStatusesByWeek[key][key2] = 100;
//                     }
//                     if (key2 === 'isNull' && averageStatusesByWeek[key][key2] < 100) {
//                       averageStatusesByWeek[key][key2] = 0;
//                     }
//                   }
//                 }
//               }
//             });

//             let sumValue: any = 0;
//             let previousValue: any = 0;
//             for (const [key, value] of Object.entries(averageStatusesByWeek.status)) {
//               sumValue += value;
//               if (sumValue > 100) {
//                 averageStatusesByWeek.status[key] = 100 - previousValue;
//                 break;
//               }
//               previousValue += value;
//               averageStatusesByWeek.status[key] = value;
//             }
//             averageStatusesByWeek.status.noAttempt = 0;
//             // @ts-ignore
//             averageStatusesByWeek.status.noAttempt = Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.status).reduce((a, b) => a + b, 0) : 0;
//             averageStatusesByWeek.tooltipStatus.noAttempt = 0;
//             // @ts-ignore
//             averageStatusesByWeek.tooltipStatus.noAttempt = Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(averageStatusesByWeek.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
//             exercisesDataPeriod = [averageStatusesByWeek];
//           }
//           resultByAveragePeriod[dataKey] = exercisesDataPeriod;
//         }
//       }

//       if (Object.keys(resultByAveragePeriod).length !== 0) {
//         return ticks === isEmptyData
//           ? { reasonsByPeriods, completationData: {} }
//           : { reasonsByPeriods, completationData: resultByAveragePeriod };
//         // return ticks === isEmptyData ? {} : resultByAveragePeriod;
//       }
//       return { reasonsByPeriods, completationData: resultByDayAndExercises };
//     }
//     return { reasonsByPeriods, completationData: {} };
//   },
// );
/*****************************27sec */

// DRAFT
// export const getExercDataOverviewTab = createSelector(
//   getSelectedPeriodOverviewTab,
//   getExercisesOverviewTab,
//   (getSelectedDates, exercises) => {
//     const reasonsByPeriods: any = [];

//     if (exercises.length) {
//       const { periodFrom, dateTo, periodType, value } = getSelectedDates;
//       const calcDates = getDatesByFormat(periodFrom, dateTo, periodType);
//       const result: any = {};
//       let isEmptyDate = 0;
//       let ticks = 0;

//       console.log('***exercises=', exercises);
//       console.log('***calcDates=', calcDates);

//       for (const [dataKey, dateArray] of Object.entries(calcDates)) {
//         const periodName = dateArray.length > 1 ? dataKey : dateArray[0];
//         // reasons logik start
//         // Create reasons default values {key: 0}
//         const reasons: any = State.reasonTitles
//           .reduce((object, reasonTitle) => ({ ...object, [reasonTitle]: 0 }), {});
//         let maxValueYaxis = 0;
//         // reasons logik end

//         exercises.map((exercise: any) => {
//           const exerciseResultPerPeriod: any = {
//             status: {
//               completed: 0,
//               partlyDone: 0,
//               attempts: 0,
//               noAttempt: 0,
//               isNull: 0,
//             },
//             name: '',
//           };
//           const { status } = exerciseResultPerPeriod;

//           exerciseResultPerPeriod.name = exercise.name;
//           dateArray.map((date: string) => {
//             exercise.exerciseDone.map((exerciseDone: any) => {
//               /* Reasons logik start */
//               if (exerciseDone[date]) {
//                 State.reasonTitles.map((reasonName: string) => {
//                   const reasonFromData = exerciseDone[date].reasons?.[reasonName];
//                   if (reasonFromData) {
//                     const accCurrentReason = reasons[reasonName] + reasonFromData;
//                     reasons[reasonName] = accCurrentReason;
//                     maxValueYaxis += reasonFromData;
//                   }
//                   return null;
//                 });
//               }
//               /* Reasons logik end */

//               if (exerciseDone[date] !== null && exerciseDone[date] !== undefined && exerciseDone[date]?.status) {
//                 for (const [statusName, statusValue] of Object.entries(exerciseDone[date].status)) {
//                   status[statusName] = statusValue;
//                 }
//               } else {
//                 isEmptyDate += 1;
//               }
//               if (exerciseDone[date] === null) {
//                 status.isNull = 100;
//               }
//               ticks += 1;
//               return null;
//             });
//             return null;
//           });

//           for (const [name, statusValue] of Object.entries(status)) {
//             // @ts-ignore
//             status[name] = Math.round(statusValue / (dateArray.length));
//             if (name === 'isNull' && status[name] < 100) {
//               status[name] = 0;
//             }
//           }

//           status.noAttempt = 0;
//           // @ts-ignore
//           status.noAttempt = Object.values(status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(status).reduce((a, b) => a + b, 0) : 0;


//           if (!result[periodName]) {
//             result[periodName] = [];
//           }
//           exerciseResultPerPeriod.tooltipStatus = { ...exerciseResultPerPeriod.status };
//           result[periodName].push(exerciseResultPerPeriod);
//           return null;
//         });

//         /* Reasons logik start */
//         const resultByPeriod: any = {
//           date: periodName,
//           id: periodName,
//           maxValueYaxis,
//           reasons: Object.keys(reasons).map((key: string) => ({ [key]: reasons[key] })),
//         };
//         reasonsByPeriods.push(resultByPeriod);
//         /* Reasons logik end */
//       }
//       console.log('***result=', result);

//       return ticks === isEmptyDate
//         ? { reasonsByPeriods, completationData: {} }
//         : { reasonsByPeriods, completationData: result };
//     }
//     return { reasonsByPeriods, completationData: {} };
//   },
// );




// Compl & steps (Overview tab - exercises) OLD
// export const getExercDataOverviewTab = createSelector(
//   getSelectedPeriodOverviewTab,
//   getExercisesOverviewTab,
//   (getSelectedDates, exercisesArrayAll) => {
//     const exercises = exercisesArrayAll.filter((item: any) => item.exerciseDone !== null);
//     const {
//       periodFrom, dateTo, periodType,
//     } = getSelectedDates;
//     const dates = getDatesByFormat(periodFrom, dateTo, periodType);

//     const resultByDayAndExercises: any = {};
//     const resultByAveragePeriod: any = {};
//     let isEmptyData = 0;
//     let ticks = 0;
//     for (const [dataKey, dateArray] of Object.entries(dates)) {
//       dateArray.map((date: any) => {
//         exercises.map((exercise: any) => {
//           const exercisesResult: any = {
//             status: {
//               completed: 0,
//               partlyDone: 0,
//               attempts: 0,
//               noAttempt: 0,
//               isNull: 0,
//             },
//             tooltipStatus: {
//               completed: 0,
//               partlyDone: 0,
//               attempts: 0,
//               noAttempt: 0,
//               isNull: 0,
//             },
//             name: exercise.name,
//           };
//           JSON.parse(exercise.exerciseDone).map((exerciseResult: any) => {
//             if (exerciseResult[date] !== null && exerciseResult[date] !== undefined) {
//               for (const [key, val] of Object.entries(exerciseResult[date])) {
//                 let sumPreviousValues: any = 0;
//                 let sumValue: any = 0;
//                 if (typeof val === 'object') {
//                   for (const [key2, value2] of Object.entries(exerciseResult[date][key])) {
//                     if (key === 'status') {
//                       sumValue += value2;
//                       if (sumValue > 100) {
//                         exercisesResult.tooltipStatus[key2] = 100 - sumPreviousValues;
//                         exercisesResult.status[key2] = 100 - sumPreviousValues;
//                         break;
//                       }
//                       sumPreviousValues += value2;
//                       exercisesResult.tooltipStatus[key2] = value2;
//                       exercisesResult.status[key2] = value2;
//                     }
//                   }
//                 }
//               }
//             } else {
//               isEmptyData += 1;
//             }
//             if (exerciseResult[date] === null) {
//               exercisesResult.tooltipStatus.isNull = 100;
//               exercisesResult.status.isNull = 100;
//             }

//             ticks += 1;

//             exercisesResult.status.noAttempt = 0;
//             exercisesResult.tooltipStatus.noAttempt = 0;
//             // @ts-ignore
//             exercisesResult.tooltipStatus.noAttempt = Object.values(exercisesResult.tooltipStatus).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exercisesResult.tooltipStatus).reduce((a, b) => a + b, 0) : 0;
//             // @ts-ignore
//             exercisesResult.status.noAttempt = Object.values(exercisesResult.status).reduce((a, b) => a + b, 0) < 100 ? 100 - Object.values(exercisesResult.status).reduce((a, b) => a + b, 0) : 0;
//           });
//           if (!resultByDayAndExercises[date]) {
//             resultByDayAndExercises[date] = [];
//           }
//           resultByDayAndExercises[date].push(exercisesResult);
//           return null;
//         });
//         return null;
//       });
//     }

//     if (Object.keys(resultByAveragePeriod).length !== 0) {
//       return ticks === isEmptyData ? {} : resultByAveragePeriod;
//     }
//     return resultByDayAndExercises;
//   },
// );
