import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { NotificationsIcon, NotificationsNoneIcon } from '../../../../theme/icons';
import { BellIconProps } from '../../types';

const BellIcon = (props: BellIconProps): ReactElement => {
  const {
    displayNotifications,
    fontSize = 'default',
    countUnread,
    notificationsList,
    visibleNotificationList,
  } = props;

  return (
    <div
      role="presentation"
      onClick={displayNotifications}
      className={cx({
        [styles['notification-icon-container']]: true,
        [styles['notification-icon-container-open']]: visibleNotificationList,
      })}
    >
      {notificationsList && countUnread ? (
        <NotificationsIcon color="primary" fontSize={fontSize} />
      ) : (
        <NotificationsNoneIcon color="primary" fontSize={fontSize} />
      )}
      <div
        className={cx({
          [styles['notification-counter']]: true,
          [styles.visible]: notificationsList?.length && countUnread,
        })}
      >
        {countUnread > 9 ? '9+' : countUnread}
      </div>
    </div>
  );
};

export default BellIcon;
