export const path = Object.freeze({
  home: '/',
  admin: '/admin',

  dashboard: '/dashboard',
  adminDashboard: '/admin/dashboard',

  signUp: '/invitation/:token',
  // mob link, to show notification
  signUpForMobile: '/patient/invitation/:token',
  questionnaireForMobile: '/patient/questionnaire',
  resetPasswordMobile: '/patient/reset/password/:token',
  sharingPatientResult: '/patient/sharing-result/:hcpToShareId',
  resetPinMobile: '/patient/reset/pincode/:pinCode',
  connectPatientWithCodeMobile: '/patient/connect-with-code/signup/:code',
  loginPatientWithCodeMobile: '/patient/connect-with-code/login/:code',
  redirectToQuestionnairesMobile: '/patient/questionnaires',
  redirectToAchievementsMobile: '/patient/achievements',
  redirectToExerciseLibraryMobile: '/patient/exerciseLibrary',
  redirectToRewardsMobile: '/patient/rewards',
  redirectToProcedureAndTeamMobile: '/patient/procedureAndTeam',
  patientVerifyEmail: '/patient/verify-email/:token',
  signinPageMobile: '/patient/signin',
  signupPageMobile: '/patient/signup',

  signIn: '/signin',
  adminSignIn: '/admin/signin',

  resetEmail: '/reset',
  resetPassword: '/reset/password/:token',
  resetPasswordHcpByHcp: '/reset/hcp-password',

  resetAdminEmail: '/admin/reset',
  resetAdminPassword: '/admin/reset/password/:token',

  profile_patient: '/profile/patient/:user',

  profile_patient_admin: '/admin/profile/patient/:user',
  profile_patient_from_hcp_admin: '/admin/profile/hcp/relevantToPatient/:user',
  profile_patient_from_hcp: '/profile/hcp/relevantToPatient/:user',
  profile_hcp_admin: '/admin/profile/hcp/:user',
  profile_hcp_from_hcp: '/profile/hcp/:user',

  profile: '/profile',

  connectPatientAsHcp: '/connect/patient',

  inviteHcpAsHcp: '/invite/hcp',
  inviteHcpAsAdmin: '/admin/invite/hcp',

  notFoud: '/page-not-found',
  success_delete_hcp_account: '/success-delete-hcp-account',

  pending_patient_invitations: '/invitations',
  manage_notifications: '/manage-notifications',
  manage_rewards_hcp: '/manage-rewards',
  manage_patient_information: '/manage-patient-information',
  upload_patient_information: '/upload-patient-information',
  upload_my_video: '/dashboard/team-video-bank/upload-video',
  record_my_video: '/dashboard/team-video-bank/record-video',
  upload_video_for_patient: '/profile/patient/:user/upload-video-for-patient',
  record_video_for_patient: '/profile/patient/:user/record-video-for-patient',
  upload_my_photo: '/dashboard/team-video-bank/upload-photo',
  take_photo: '/dashboard/team-video-bank/take-photo',
  team_video_bank: '/dashboard/team-video-bank',
  team_video_bank_folder: '/dashboard/team-video-bank/:folder',
  prescription_history: '/profile/patient/:user/prescription-history',
  from_patient: '/profile/patient/:user/from-patient',
  manage_exercises: '/manage-exercises',
  manage_teams: '/manage-teams',
  create_team: '/create-team',
  my_teams: '/my-teams',

  manage_organisations: '/admin/manage-organisations',
  manage_professions: '/admin/manage-professions',
  manage_proms: '/admin/manage-proms',
  manage_rewards_admin: '/admin/manage-rewards',
  manage_patient_information_admin: '/admin/manage-patient-information',
  upload_patient_information_admin: '/admin/upload-patient-information',
  manage_departments: '/admin/manage-departments',
});

// TODO
export const constants = {
  admin: 'admin',
  hcp: 'hcp',
  geolacation: process.env.REACT_APP_LOCATION,
  mobileSchema: (url: string) => `${process.env.REACT_APP_BUNDLE_ID || 'onthemend'}://${url}`,
};
