import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Pagination } from 'antd';

import { Button, Loading } from '../../common';
import styles from './styles.module.css';
import '../../theme/pagination.css';
import { getCurrenLang } from '../../redux/selector';
import PatientInvitation from './components/patientInvitation';
import TableTitle from './components/tableTitle';
import { PAGE_SIZE_OPTIONS_FOR_PENDING_PATIENT_INVITATIONS } from '../../utils/variables';
import { PandingPatientInvitationsProp } from './types';
import { TSortdirectionProps } from '../../utils/model';
import { storage } from '../../utils';
import { useAcceptPendingPatientInvitations } from '../../graphql/invite';

const PandingPatientInvitations = ({
  isModal = false,
  patientsInvitations,
  _getPatientInvitations,
  total,
  listLoading,
}: PandingPatientInvitationsProp): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const pending_patient_invitations = t?.menu.pending_patient_invitations;
  const please_review = t?.notifications.please_review;
  const accept_all = t?.notifications.accept_all;
  const no_invitations = t?.notifications.no_invitations;

  const showModalDate = storage.get('show-modal-date');
  const { patientsIds, today } = showModalDate;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);
  const [openSearch, setOpenSearch] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [patientIdsAction, setPatientIdsAction] = useState<any>([]);

  const {
    _acceptPendingPatientInvitations,
    solutionLoading,
    solutionSaved,
  } = useAcceptPendingPatientInvitations();

  useEffect(() => {
    if (!isModal) {
      _getPatientInvitations({
        variables: {
          listProps: {
            page: 1,
            itemsPerPage: pageSize,
          },
          searchProps: [
            {
              searchField: `patient.${searchName}` || '',
              searchValue: searchValue || '',
            },
          ],
          pending: true,
          searchByDate: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (solutionSaved) {
      // Delete id from local storage
      if (patientsIds?.length > 0) {
        const newPatientsIds = [...patientsIds];
        patientIdsAction.map((id: number) => {
          const position = newPatientsIds.indexOf(id);
          if (position >= 0) {
            newPatientsIds.splice(position, 1);
          }
        });
        storage.save('show-modal-date', {
          today,
          patientsIds: newPatientsIds,
        });
      }

      // Update List
      _getPatientInvitations({
        variables: {
          listProps: {
            page: 1,
            itemsPerPage: pageSize,
          },
          searchProps: [
            {
              searchField: `patient.${searchName}` || '',
              searchValue: searchValue || '',
            },
          ],
          pending: true,
          searchByDate: [],
        },
      });
    }
  }, [solutionSaved]);

  const searchHandler = (name: string): void => {
    setSearchName(name);
    setSearchValue(inputValue);
    _getPatientInvitations({
      variables: {
        listProps: {
          page: 1,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: `patient.${name}` || '',
            searchValue: inputValue || '',
          },
        ],
        pending: true,
        searchByDate: [],
      },
    });
    setOpenSearch('');
    setInputValue('');
    setCurrentPage(1);
  };

  const resetHandler = (): void => {
    _getPatientInvitations({
      variables: {
        listProps: {
          page: 1,
          itemsPerPage: pageSize,
        },
        searchProps: [
          {
            searchField: '',
            searchValue: '',
          },
        ],
        pending: true,
        searchByDate: [],
      },
    });
    setOpenSearch('');
    setInputValue('');
    setSearchName('');
    setSearchValue('');
    setCurrentPage(1);
  };

  const loadingBolean = listLoading || solutionLoading || false;
  const loadingJSX = (
    listLoading || solutionLoading
  ) && <Loading />;

  const acceptHandler = (type: string, patientId?: number): void => {
    let solution = type;
    const patientIds: any = [];
    if (solution === 'acceptAll') {
      patientsInvitations.map((patient: {id: number}) => {
        patientIds.push(patient.id);
        return null;
      });
      solution = 'accept';
    } else {
      patientIds.push(patientId);
    }
    setPatientIdsAction(patientIds);
    _acceptPendingPatientInvitations(solution, patientIds);
  };

  const onChangePagination = (page: any, itemsPerPage: any): void => {
    setPageSize(itemsPerPage);
    setCurrentPage(page);
    const listProps: TSortdirectionProps = {
      page,
      itemsPerPage,
    };
    _getPatientInvitations({
      variables: {
        listProps: {
          ...listProps,
        },
        searchProps: [
          {
            searchField: `patient.${searchName}` || '',
            searchValue: searchValue || '',
          },
        ],
        pending: true,
        searchByDate: [],
      },
    });
  };

  const acceptAllBtn = (
    <Button
      buttonName={accept_all}
      buttonType="button"
      buttonClass={styles['btn__accept-all']}
      buttonMethod={(): void => acceptHandler('acceptAll')}
      disabledButton={loadingBolean || !patientsInvitations?.length}
      colorStyle="green"
    />
  );
  const noData = !patientsInvitations?.length && !listLoading && (
    <div className={styles.noData}>{no_invitations}</div>
  );

  document.onkeyup = (event: any): void => {
    if (event.code === 'Escape') setOpenSearch('');
    if (event.code.includes('Enter')) {
      searchHandler(openSearch);
    }
  };
  document.onclick = (event: any): boolean | any => {
    const target: string = event?.target?.className;
    if (typeof target === 'string') {
      if (!target.includes('search')) {
        setOpenSearch('');
      }
    }
  };

  return (
    <>
      {loadingJSX}
      <div
        className={cx({
          [styles.title]: true,
          [styles['title-modal']]: isModal,
        })}
      >
        {pending_patient_invitations}
      </div>
      <div
        className={cx({
          [styles['sub-title__row']]: true,
          [styles['sub-title__row-modal']]: isModal,
        })}
      >
        <span className={styles['sub-title']}>{please_review}</span>
        <div
          className={cx({
            [styles['full-screen']]: !isModal,
            [styles['hide-in-modal']]: isModal,
          })}
        >
          {acceptAllBtn}
        </div>
      </div>

      <div
        className={cx({
          [styles.table]: true,
          [styles['table-modal']]: isModal,
        })}
      >
        {!isModal
          && (
            <TableTitle
              searchHandler={searchHandler}
              resetHandler={resetHandler}
              inputValue={inputValue}
              setInputValue={setInputValue}
              openSearch={openSearch}
              setOpenSearch={setOpenSearch}
            />
          )}
        {noData}
        {patientsInvitations && patientsInvitations.map((invitation: any, i: number) => (
          <PatientInvitation
            key={`PatientInvitation${String(i)}`}
            invitation={invitation}
            isModal={isModal}
            acceptHandler={acceptHandler}
            loading={loadingBolean}
          />
        ))}

      </div>
      <div
        className={cx({
          [styles.hide]: !isModal,
          [styles['bottom-btn']]: true,
        })}
      >
        {acceptAllBtn}
      </div>
      {!noData && !loadingBolean && (
        <div className="wrapper_pagination">
          <Pagination
            size="small"
            current={currentPage}
            pageSize={pageSize}
            total={total}
            onChange={onChangePagination}
            pageSizeOptions={PAGE_SIZE_OPTIONS_FOR_PENDING_PATIENT_INVITATIONS}
            showSizeChanger
          />
        </div>
      )}
    </>
  );
};

export default PandingPatientInvitations;
