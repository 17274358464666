// Core
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
  UseQueryInviteePatientProfile,
  UseQueryPatientProfile,
  UseQueryPatientProfileByEmail,
  UseQueryPendingPatientProfile,
} from './types';
import { fillPatientProfile, loadingPatientProfile } from '../../redux/patient';
import { config } from '../../utils/configs';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';

// Query
const queryProfile = loader('./gql/queryGetPatientById.graphql');
const queryProfileInviteePatient = loader('./gql/queryGetInviteePatientById.graphql');
const queryProfilePendingPatient = loader('./gql/queryGetPendingPatientById.graphql');
const queryGetPatientsListByHcpId = loader('./gql/getPatientsListByHcpId.graphql');
const queryProfileByEmail = loader('./gql/queryGetPatientByEmail.graphql');
const gqlPatient = loader('./gql/queryGetPatientsList.graphql');
const getPatientInvitations = loader('./gql/queryGetPatientsInvitations.graphql');

//  Mutation
const mutationUnlockPatientAccountByAdmin = loader('./gql/mutationUnlockPatientAccountByAdmin.graphql');

// get patient profile from DB
export const useQueryPatientProfile = (): UseQueryPatientProfile => {
  const [_getProfileById, { loading, error, data }] = useLazyQuery(queryProfile, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const dispatch = useDispatch();
  const response = data && data.getPatientById;

  useEffect(() => {
    if (response) {
      dispatch(fillPatientProfile(response));
    }
  }, [response]);

  useEffect(() => {
    dispatch(loadingPatientProfile(loading));
  }, [loading]);

  return {
    _getProfileById,
    loading,
    error,
    patient: response,
  };
};

// get invitee patient profile from DB
export const useQueryInviteePatientProfile = (): UseQueryInviteePatientProfile => {
  const [_getInviteeProfileById, { loading, error, data }] = useLazyQuery(
    queryProfileInviteePatient,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  );

  const dispatch = useDispatch();
  const response = data && data.getInviteePatientById;

  useEffect(() => {
    if (response) {
      dispatch(fillPatientProfile(response));
    }
  }, [response]);

  useEffect(() => {
    dispatch(loadingPatientProfile(loading));
  }, [loading]);

  return {
    _getInviteeProfileById,
    inviteeLoading: loading,
    inviteeError: error,
    inviteePatient: data && data.getInviteePatientById,
  };
};

// get pending patient profile from DB
export const useQueryPendingPatientProfile = (): UseQueryPendingPatientProfile => {
  const [_getPendingPatientProfileById, { loading, error, data }] = useLazyQuery(
    queryProfilePendingPatient,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  );

  const dispatch = useDispatch();
  const response = data && data.getPendingPatientById;

  useEffect(() => {
    if (response) {
      dispatch(fillPatientProfile(response));
    }
  }, [response]);

  useEffect(() => {
    dispatch(loadingPatientProfile(loading));
  }, [loading]);

  return {
    _getPendingPatientProfileById,
    pendingLoading: loading,
    pendingError: error,
    pendingPatient: data && data.getPendingPatientById,
  };
};

// Get patient list for HCP profile
export const useQueryGetPatientsListByHcpId = (): any => {
  const [_getPatientById, { loading, error, data }] = useLazyQuery(queryGetPatientsListByHcpId, {
    errorPolicy: 'all',
  });

  const response = data && !error && !loading && data.getPatientsListByHcpId;

  if (response) {
    const {
      listLength, totalPages, list, totalItems,
    } = response;
    return {
      _getPatientById,
      loading,
      data,
      error,
      patients: list,
      listLength,
      totalPages,
      totalItems,
    };
  }
  return {
    _getPatientById,
    loading,
    data,
    error,
    patients: [],
    listLength: 0,
    totalPages: 0,
    totalItems: 0,
  };
};

// get patient profile from DB
export const useQueryPatientProfileByEmail = (): UseQueryPatientProfileByEmail => {
  const [_getProfileByEmail, { loading, error, data }] = useLazyQuery(queryProfileByEmail, {
    // errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
  const response = data && data.getPatientByEmail;

  return {
    _getProfileByEmail,
    loading,
    error: (error?.graphQLErrors?.[0]?.message) || error,
    patient: response,
  };
};

// Get all patients
export const usePatientsList = () => {
  const [_getListPatient, { loading, error, data }] = useLazyQuery(gqlPatient, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    // pollInterval: config.pollInterval,
  });
  const dataList = data && data.getPatientsList;
  if (dataList) {
    const {
      list, listLength, totalPages, totalItems,
    } = dataList;
    return {
      dataPatients: data,
      patients: list,
      total: totalItems,
      pages: totalPages,
      pageSize: listLength,
      _getListPatient,
      listError: error,
      listLoading: loading,
    };
  }
  return {
    dataPatients: data,
    patients: [],
    total: 0,
    pageSize: 0,
    _getListPatient,
    listError: error,
    listLoading: loading,
  };
};

// Get pending patients Invitations
export const usePatientsPendingInvitations = (): any => {
  const [_getPatientInvitations, { loading, error, data }] = useLazyQuery(getPatientInvitations, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    pollInterval: config.pollInterval,
  });
  const dataList = data && data.getPatientsList;
  if (dataList) {
    const {
      list, listLength, totalPages, totalItems,
    } = dataList;
    return {
      patientsInvitations: list,
      total: totalItems,
      pages: totalPages,
      pageSize: listLength,
      _getPatientInvitations,
      listError: error,
      listLoading: loading,
    };
  }
  return {
    patientsInvitations: [],
    total: 0,
    pageSize: 0,
    _getPatientInvitations,
    listError: error,
    listLoading: loading,
  };
};

// Unlock patients account by Admin
export const useUnlockPatientAccountByAdmin = () => {
  const [
    _onUnlockPatientAccountByAdmin,
    { data, errorText, loading },
  ] = useMutationRequest<{unlockPatientAccountByAdmin: boolean}>(
    mutationUnlockPatientAccountByAdmin, { fetchPolicy: 'no-cache' },
  );
  const _unlockPatientAccountByAdmin = (patientId: number): void => {
    _onUnlockPatientAccountByAdmin({
      variables: { patientId },
    });
  };

  return {
    _unlockPatientAccountByAdmin,
    unlockPatientAccountData: data && data.unlockPatientAccountByAdmin,
    unlockPatientAccountError: errorText,
    unlockPatientAccountLoading: loading,
  };
};
