import { loader } from 'graphql.macro';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { UseLogoutProps } from '../types';

// Mutations
const mutationLogoutAdmin = loader('./gql/mutationAdminLogout.graphql');
const mutationLogoutHcp = loader('./gql/mutationLogout.graphql');

// Logout
export const useLogout = (isAdmin: boolean): UseLogoutProps => {
  const [logout, { errorText }] = useMutationRequest<{}>(
    isAdmin ? mutationLogoutAdmin : mutationLogoutHcp,
    {},
  );

  const onLogout = (): void => {
    logout();
  };
  return {
    error: errorText,
    onLogout,
  };
};
