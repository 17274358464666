import { listOptionInit } from '../../utils/share/options';
import { PeriodListOptionValue } from '../../utils/model';

// Type
const SELECT_DROPDOWN = 'SELECT_DROPDOWN';
// const SELECT_FILTER = 'SELECT_FILTER';
const SELECT_DROPDOWN_COMPLETIONS = 'SELECT_DROPDOWN_COMPLETIONS';
const SELECT_DROPDOWN_REASONS = 'SELECT_DROPDOWN_REASONS';
const SELECT_DROPDOWN_MOTIVATION_PAIN = 'SELECT_DROPDOWN_MOTIVATION_PAIN';
const SELECT_PERIOD_OVERVIEW_TAB = 'SELECT_PERIOD_OVERVIEW_TAB';

export interface TPeriodsOptionsType {
  dateTo: string;
  label: string;
  periodFrom: string;
  periodType: string;
  value: string;
}

// TS
interface PropType {
  type: typeof SELECT_DROPDOWN
    | typeof SELECT_DROPDOWN_MOTIVATION_PAIN
    | typeof SELECT_DROPDOWN_REASONS
    | typeof SELECT_PERIOD_OVERVIEW_TAB;
  payload: TPeriodsOptionsType;
}

interface ReasonSelectorType {
  type: typeof SELECT_DROPDOWN_REASONS;
  payload: string;
}

// interface FilterType {
//   type: typeof SELECT_FILTER;
//   payload: string;
// }
interface TypeCompletion {
  type: typeof SELECT_DROPDOWN_COMPLETIONS;
  payload: TPeriodsOptionsType;
}

type ActionTypes = PropType | TypeCompletion | ReasonSelectorType;

// Action
export const setSelectedCompletion = (data: TPeriodsOptionsType): TypeCompletion => ({
  type: SELECT_DROPDOWN_COMPLETIONS,
  payload: data,
});

export const setSelectedReasons = (data: PeriodListOptionValue): PropType => ({
  type: SELECT_DROPDOWN_REASONS,
  payload: data,
});

export const setSelectedDropdown = (data: TPeriodsOptionsType): PropType => ({
  type: SELECT_DROPDOWN,
  payload: data,
});

// export const setSFilterDropdown = (value: string): FilterType => ({
//   type: SELECT_FILTER,
//   payload: value,
// });

export const setSelectedDropdownForMotivationPain = (data: TPeriodsOptionsType): PropType => ({
  type: SELECT_DROPDOWN_MOTIVATION_PAIN,
  payload: data,
});

export const setSelectedPeriodOverviewTab = (data: TPeriodsOptionsType): PropType => ({
  type: SELECT_PERIOD_OVERVIEW_TAB,
  payload: data,
});

export interface SelectedPeriodType {
  completion: PeriodListOptionValue;
  period: PeriodListOptionValue;
  // filter: object;
  reasons: PeriodListOptionValue;
  motivationPain: PeriodListOptionValue;
  periodOverviewTab: PeriodListOptionValue;
}

const init: SelectedPeriodType = {
  completion: listOptionInit,
  period: listOptionInit,
  // filter: { value: '', label: 'Last 7 days' },
  reasons: listOptionInit,
  motivationPain: listOptionInit,
  periodOverviewTab: listOptionInit,
};

// Reducer
export const dropdownReduser = (state = init, { type, payload }: ActionTypes) => {
  switch (type) {
    case SELECT_DROPDOWN:
      return {
        ...state,
        period: payload,
      };
    case SELECT_DROPDOWN_COMPLETIONS:
      return {
        ...state,
        completion: payload,
      };
    // case SELECT_FILTER:
    //   return {
    //     ...state,
    //     filter: payload,
    //   };
    case SELECT_DROPDOWN_REASONS:
      return {
        ...state,
        reasons: payload,
      };
    case SELECT_DROPDOWN_MOTIVATION_PAIN:
      return {
        ...state,
        motivationPain: payload,
      };

    case SELECT_PERIOD_OVERVIEW_TAB:
      return {
        ...state,
        periodOverviewTab: payload,
      };

    default:
      return state;
  }
};
