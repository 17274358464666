export const FILL_STEPS_EXERCISES = 'FILL_STEPS_EXERCISES';
export const FILL_STEPS_OVERVIEW_TAB = 'FILL_STEPS_OVERVIEW_TAB';
export const FILL_STEPS_BY_EXERCISES = 'FILL_STEPS_BY_EXERCISES';
export const START_FETCHING_PATIENT = 'START_FETCHING_PATIENT';
export const FILL_PATIENT_EXERCISES = 'FILL_PATIENT_EXERCISES';
export const FILL_EXERCISES_OVERVIEW_TAB = 'FILL_EXERCISES_OVERVIEW_TAB';
export const FILL_EXERCISES_MOTIVATION_PAIN_TAB = 'FILL_EXERCISES_MOTIVATION_PAIN_TAB';
export const FILL_EXERCISES_REASONS_TAB = 'FILL_EXERCISES_REASONS_TAB';
export const FILL_DATA_BY_EXERCISES = 'FILL_DATA_BY_EXERCISES';
export const FILL_PATIENT_COMPLETINS = 'FILL_PATIENT_COMPLETINS';
export const SET_EXERCISE_NAME = 'SET_EXERCISE_NAME';
export const SET_IS_EXERCISE = 'SET_IS_EXERCISE';
export const STEPS_LOADING = 'STEPS_LOADING';
export const REASONS_LEGEND_VALUE = 'REASONS_LEGEND_VALUE';
export const MOTIVATIONS_LEGEND_VALUE = 'MOTIVATIONS_LEGEND_VALUE';
export const BY_EXERCISES_LEGEND_VALUE = 'BY_EXERCISES_LEGEND_VALUE';
export const COMPLETATION_STEPS_LEGEND_VALUE = 'COMPLETATION_STEPS_LEGEND_VALUE';
export const COMPLETATION_SKIP_STOP_LEGEND_VALUE = 'COMPLETATION_SKIP_STOP_LEGEND_VALUE';

export type ErrorHttpAction = string;
export interface DataType {
  data: object[];
}

export interface PatientFillStep {
  type: typeof FILL_STEPS_EXERCISES
    | typeof FILL_STEPS_OVERVIEW_TAB
    | typeof FILL_STEPS_BY_EXERCISES;
  payload: DataType;
}

export interface PatientStartFetching {
  type: typeof START_FETCHING_PATIENT | typeof STEPS_LOADING;
  payload: boolean;
}

export interface PatientFillAction {
  type: typeof FILL_PATIENT_EXERCISES
    | typeof FILL_EXERCISES_OVERVIEW_TAB
    | typeof FILL_EXERCISES_MOTIVATION_PAIN_TAB
    | typeof FILL_EXERCISES_REASONS_TAB
    | typeof REASONS_LEGEND_VALUE
    | typeof MOTIVATIONS_LEGEND_VALUE
    | typeof BY_EXERCISES_LEGEND_VALUE
    | typeof COMPLETATION_STEPS_LEGEND_VALUE
    | typeof COMPLETATION_SKIP_STOP_LEGEND_VALUE;
  payload: DataType;
}
export interface ExercisesFillAction {
  type: typeof FILL_EXERCISES_OVERVIEW_TAB;
  payload: [] | object[];
}

export interface PatientFillPeriondAction {
  type: typeof FILL_DATA_BY_EXERCISES;
  payload: DataType;
}

export interface PatientCompletionsFillAction {
  type: typeof FILL_PATIENT_COMPLETINS;
  payload: DataType;
}

export const ERROR_PATIENT_COMPLETED_EXERCISES = 'error/patient_completed_exercises';
export interface PatientErrorFetching {
  type: typeof ERROR_PATIENT_COMPLETED_EXERCISES;
  payload: ErrorHttpAction;
}

export interface PatientExerciseName {
  type: typeof SET_EXERCISE_NAME;
  payload: string | null;
}

export interface IsExerciseChart {
  type: typeof SET_IS_EXERCISE;
  payload: boolean;
}

export type ActionTypes =
  | PatientStartFetching
  | PatientFillAction
  | PatientCompletionsFillAction
  | PatientErrorFetching
  | PatientFillPeriondAction
  | PatientFillStep
  | PatientExerciseName
  | IsExerciseChart
  | ExercisesFillAction

export interface ExercisesType {
  byExercises: any;
  exercisesPerPeriod: any;
  isExercise: boolean;
  error: false | ErrorHttpAction;
  isFetching: boolean;
  exerciseName: null | string;
  exercisesOverviewTab: any;
  exercMotivPainTab: any;
  exercReasonsTab: any;
  step: any;
  stepOverviewTab: any;
  stepByExercises: any;
  stepsLoading: boolean;
  reasonsLegendValue: any;
  motivationsLegendValue: any;
  complByExercLegendValue: any;
  complStepsLegendValue: any;
  complSkipStopLegendValue: any;
}
