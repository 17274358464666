import {
  makeStyles, green, lightBlue, createMuiTheme,
} from '../theme/icons';

export const useStyles = makeStyles({
  root: {
    '&$checked': {
      color: '#1c9ce4',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {
    backgroundColor: 'transparent',
  },
});

export const useHeaderStyles = makeStyles((theme) => ({
  user: {
    margin: theme.spacing(1),
    color: '#1C9CE4',
  },
  expand: {
    margin: '10px 0 0 10px',
    cursor: 'pointer',
  },
}));

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[500],
    },
    secondary: {
      main: green[500],
    },
  },
});
