import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { SearchOutlined } from '@ant-design/icons';

import styles from '../styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { setPlaceholder } from '../../../utils/helper';
import { SearchPatientPendingInvitationProp } from '../types';

const SearchPatientPendingInvitation = ({
  name,
  searchHandler,
  resetHandler,
  inputValue,
  inputHandler,
}: SearchPatientPendingInvitationProp): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const search_t = t && t.common.search;
  const reset_t = t && t.common.reset;

  return (
    <div
      className={cx({
        [styles['search-container']]: true,
      })}
    >
      <input
        className={styles['search-input']}
        name={name}
        type="text"
        value={inputValue}
        placeholder={`${search_t} ${setPlaceholder(name, t)}`}
        onChange={(e: any): void => inputHandler(e)}
      />
      <div className={styles['search__btn-container']}>
        <button
          type="button"
          className={cx({
            [styles.btn]: true,
            [styles['btn-search']]: true,
          })}
          onClick={(): void => searchHandler(name)}
        >
          <SearchOutlined className={styles['search-btn-icon']} />
          {search_t}
        </button>
        <button
          type="button"
          className={cx({
            [styles.btn]: true,
            [styles['btn-reset']]: true,
          })}
          onClick={resetHandler}
        >
          {reset_t}
        </button>

      </div>
    </div>
  );
};

export default SearchPatientPendingInvitation;
