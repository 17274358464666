import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from './Context';
import storage from '../utils/storage';

// for the routers
export const AuthContext = ({ children }: any): ReactElement => {
  const [user, setUser] = useState('');

  const { Provider } = Context;

  const userFromLs = storage.get('user');

  const role = userFromLs && userFromLs.role;
  const isHcp = role === 'hcp';
  const isAdmin = role === 'admin';

  const logIn = (property: any): void => {
    setUser(property);
  };

  const logOut = (): void => {
    setUser('');
    storage.save('user', '');
  };

  const data = {
    user,
    onLogIn: logIn,
    onLogOut: logOut,
    userName: 'John Delphy',
    userAdmin: isAdmin,
    userHcp: isHcp,
    role,
  };
  return <Provider value={data}>{children}</Provider>;
};

export const withAuthContext = (WrappedComponent: any) =>
  function WithAuthContext(props: any): ReactElement {
    const { Consumer } = Context;
    const location = useLocation();
    const path = location && location.pathname;

    return (
      <Consumer>
        {(ctx): ReactElement => <WrappedComponent {...props} auth={ctx} path={path} />}
      </Consumer>
    );
  };
