export enum PATH_TYPE {
  PROFILE = 'profile',
  PENDING_PATIENT_INVITATIONS= 'pending-patient-invitations',
  MANAGE_NOTIFICATIONS= 'manage-notifications',
  MANAGE_ORGANISATION = 'manage-organisation',
  MANAGE_PROFESSIONS = 'manage-professions',
  MANAGE_PROMS = 'manage-proms',
  MANAGE_REWARDS = 'manage-rewards',
  MANAGE_EXERCISES = 'manage-exercises',
  MANAGE_PATIENT_INFORMATION= 'manage-patient-information',
  // MANAGE_PATIENT_LIST_ACCESS= 'manage-patient-list-access',
  MANAGE_DEPARTMENTS= 'manage-department',
  MANAGE_TEAMS= 'manage-teams',
}

export enum VIDEO_BANK_TYPE {
  TEAM_VIDEO_BANK = 'team-video-bank',
  PATIENT_VIDEO_BANK = 'patient-video-bank',
  INVITEE_PATIENT = 'invitee-patient',
  PREVIEW = 'preview',
  NESTED = 'nested',
  NESTED_INVITEE = 'nested-invitee',
  TEAM_VIDEO_BANK_FOLDER = 'team-video-bank-folder',
}

export enum StatusKeyNameEnum {
  'active' = 'active',
  'inactive' = 'inactive',
  'invited' = 'invited',
  'expired' = 'expired',
  'disable' = 'disable',
  'connected' = 'connected',
  'disconnected' = 'disconnected',
  'treatment_completed' = 'treatment_completed',
  'pending' = 'pending',
  'invite_sent' = 'invite_sent',
  'declined' = 'declined',
}

export enum NOTIF_STATUS {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum EXPORT_PDF_TYPE {
  SEND_TO_PATIENT = 'send_to_patient',
  UPLOAD = 'UPLOAD',
}

export enum LANG {
  EN_GB = 'en-gb',
  EN_US = 'en-us',
  DE = 'de',
}

export enum ROLE {
  HCP = 'hcp',
  PATIENT = 'patient',
}
export enum UserCountryEnum {
  US = 'us',
  GB = 'gb',
  DE = 'de'
}

export enum LIST_TYPE {
  HCP = 'hcp',
  HCP_ADMIN = 'admin-hcp',
  PATIENT = 'patient',
  PATIENT_ADMIN = 'admin-patient',
}

export enum HEIGHT {
  cm = 'cm',
  ftinches = 'ft/inch',
}

export enum Weight {
  Kg = 'kg',
  Stone = 'stone/lbs',
  Lbs = 'lbs',
}

export enum PATIENT_PROFILE_TABS {
  PATIENT_OVERVIEW = '1',
  COMPLETION_STEPS = '2',
  REASONS_FOR_NOT_COMPLETENG = '3',
  MOTIVATION = '4',
  PROMS = '5',
  VIDEO_BANK = '6',

  // Subtabs
  BY_EXERCISES = 'BY_EXERCISES',
  BY_PERIODS = 'BY_PERIODS',
  STEPS_CHART = 'STEPS_CHART',
  SKIP_STOP_CHART = 'SKIP_STOP_CHART',
  QUICK_DASH = 'QUICK_DASH',
  PATIENT_INFORMATION = 'patient-information',
}

export enum proceduresEnum {
  HIP = 'PROCEDURE_1',
  KNEE = 'PROCEDURE_2',
  SHOULDER = 'PROCEDURE_3',
  OTHER_PHYSICAL_REHAB = 'PROCEDURE_4',
  FOOT_AND_ANKLE = 'PROCEDURE_5',
  SPINAL = 'PROCEDURE_6',
  ELBOW = 'PROCEDURE_7',
  HAND_AND_WRIST = 'PROCEDURE_8',
}

export enum PROMS_NAME {
  AOFAS = 'AOFAS',
  EQ5D5L = 'EQ-5D-5L',
  MEPS = 'MEPS',
  MOXFQ = 'MOXFQ',
  ODI = 'ODI',
  PainDetect = 'PainDetect',
  PCS = 'PCS',
  PRWE = 'PRWE',
  QuickDash = 'QuickDash',
  SF36 = 'SF-36',
  OxfordHipScore = 'OxfordHipScore',
  OxfordKneeScore = 'OxfordKneeScore',
  OxfordShoulderScore = 'OxfordShoulderScore',
  KOOS = 'KOOS',
  HOOS = 'HOOS',
}

export enum statusQuestionnaries {
  COMPLETED = 'completed',
  SKIPPED = 'skipped completely',
}

export enum PERMISSIONS {
  EDIT_PATIENT_PROFILE_INFO_OTM = 'editPatientProfileInfo_otm',
  INVITE_OR_ADD_HCP_OTM = 'inviteOrAddHcp_otm',
  EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_OTM = 'editOrPrescribeExerciseVideos_otm',
  ADD_VIDEOS_TO_TEAM_VIDEOBANK_OTM = 'addVideosToTeamVideoBank_otm',
  CREATE_TEAM_OTM = 'createTeam_otm',
  EDIT_PATIENT_PROFILE_INFO_HCP = 'editPatientProfileInfo_hcp',
  INVITE_OR_ADD_HCP_HCP = 'inviteOrAddHcp_hcp',
  EDIT_OR_PRESCRIBE_EXERCISE_VIDEOS_HCP = 'editOrPrescribeExerciseVideos_hcp',
  ADD_VIDEOS_TO_TEAM_VIDEOBANK_HCP = 'addVideosToTeamVideoBank_hcp',
}

export enum MEDIA {
  VIDEO = 'videoLink',
  FILE = 'fileLink',
}

export enum REWARDS_OPTIONS {
  OPTION_1 = 1,
  OPTION_2 = 2,
}

export enum STATUS {
  active = 'STATUS_1',
  inactive = 'STATUS_2',
  expired = 'STATUS_3',
  invited = 'STATUS_4',
  pending = 'STATUS_5',
  connected = 'STATUS_6',
  disconnected = 'STATUS_7',
  treatment_completed = 'STATUS_8',
  invite_sent = 'STATUS_9',
  declined = 'STATUS_11',
}

export enum EXERCISE_TYPE {
  PHOTO = 'photo',
  VIDEO = 'video',
  FOLDER = 'folder',
}

export enum PERIOD_TYPE {
  WEEK = 1,
  MONTH = 2,
}

export enum SCHEDULE_UNIT {
  DAYS = 1,
  WEEKS = 2,
  MONTHS = 3,
}

export enum REMINDER_PERIOD {
  WEEK_1 = 'WEEK_1',
  WEEK_2 = 'WEEK_2',
  WEEK_3 = 'WEEK_3',
  WEEK_4 = 'WEEK_4',
  WEEK_5 = 'WEEK_5',
  WEEK_6 = 'WEEK_6',
  WEEK_7 = 'WEEK_7',
  WEEK_8 = 'WEEK_8',
  WEEK_9 = 'WEEK_9',
  WEEK_10 = 'WEEK_10',
  WEEK_11 = 'WEEK_11',
  WEEK_12 = 'WEEK_12',
  MONTH_4 = 'MONTH_4',
  MONTH_5 = 'MONTH_5',
  MONTH_6 = 'MONTH_6',
  MONTH_7 = 'MONTH_7',
  MONTH_8 = 'MONTH_8',
  MONTH_9 = 'MONTH_9',
  MONTH_10 = 'MONTH_10',
  MONTH_11 = 'MONTH_11',
  MONTH_12 = 'MONTH_12',
}
export enum MEMBER_TYPE {
  PATIENTS = 'patients',
  HCPS = 'hcps',
}
