import moment from 'moment';
import { Options, PeriodListOption, ReminderPeriodOptionsType, TPainChartConfig } from '../model';
import { BarChartsConfig } from '../../components/BarCharts/types';
import { PERIOD_TYPE, REMINDER_PERIOD } from '../enums';

const daysStringFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const listOptionInit = {
  value: '7',
  label: 'Last 7 days',
  periodType: 'days',
  periodFrom: moment().add(-7, 'days').format(daysStringFormat),
  dateTo: moment().format(daysStringFormat),
};

export const periodListOption = (t: any): PeriodListOption => ({
  '7Days': {
    value: '7',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_7_days,
    periodType: 'days',
    periodFrom: moment().add(-7, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '14Days': {
    value: '14',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_14_days,
    periodType: 'days',
    periodFrom: moment().add(-13, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '30Days': {
    value: '30',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_30_days,
    periodType: 'days',
    periodFrom: moment().add(-29, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
});

export const completionList = (t: any): PeriodListOption => ({
  '7Days': {
    value: '7',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_7_days,
    periodType: 'days',
    periodFrom: moment().add(-7, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '14Days': {
    value: '14',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_14_days,
    periodType: 'days',
    periodFrom: moment().add(-13, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '30Days': {
    value: '30',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_30_days,
    periodType: 'days',
    periodFrom: moment().add(-29, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '6Weeks': {
    value: '6',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_6_weeks,
    periodType: 'weeks',
    periodFrom: moment().add(-6, 'weeks').startOf('weeks').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '3Months': {
    value: '13',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_3_months,
    periodType: 'weeks',
    periodFrom: moment().add(-13, 'weeks').startOf('week').format(daysStringFormat),
    // periodFrom: moment().add(-3, 'month').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '6Months': {
    value: '26',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_6_months,
    periodType: 'weeks',
    periodFrom: moment().add(-26, 'weeks').startOf('week').format(daysStringFormat),
    // periodFrom: moment().add(-6, 'month').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '12Months': {
    value: '12',
    label: t && t.dashboard.hcp.profile_patient.completion_and_steps.last_12_months,
    periodType: 'months',
    periodFrom: moment().add(-12, 'month').startOf('month').format(daysStringFormat),
    dateTo: moment().add(-1, 'month').endOf('month').format(daysStringFormat),
  },
  '24Months': {
    value: '24',
    label: t?.dashboard.hcp.profile_patient.completion_and_steps.last_24_months,
    periodType: 'months',
    periodFrom: moment().add(-24, 'month').startOf('month').format(daysStringFormat),
    dateTo: moment().add(-1, 'month').endOf('month').format(daysStringFormat),
  },
});

export const periodsForChart = (optionLabels: any): PeriodListOption => ({
  '7Days': {
    value: '7',
    label: optionLabels?.last_7_days,
    periodType: 'days',
    periodFrom: moment().add(-7, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '14Days': {
    value: '14',
    label: optionLabels?.last_14_days,
    periodType: 'days',
    periodFrom: moment().add(-13, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '30Days': {
    value: '30',
    label: optionLabels?.last_30_days,
    periodType: 'days',
    periodFrom: moment().add(-29, 'days').format(daysStringFormat),
    dateTo: moment().format(daysStringFormat),
  },
  '6Weeks': {
    value: '6',
    label: optionLabels?.last_6_weeks,
    periodType: 'weeks',
    periodFrom: moment().add(-6, 'weeks').startOf('weeks').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '3Months': {
    value: '13',
    label: optionLabels?.last_3_months,
    periodType: 'weeks',
    periodFrom: moment().add(-13, 'weeks').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '6Months': {
    value: '26',
    label: optionLabels?.last_6_months,
    periodType: 'weeks',
    periodFrom: moment().add(-26, 'weeks').startOf('week').format(daysStringFormat),
    dateTo: moment().add(-1, 'weeks').endOf('weeks').format(daysStringFormat),
  },
  '12Months': {
    value: '12',
    label: optionLabels?.last_12_months,
    periodType: 'months',
    periodFrom: moment().add(-12, 'month').startOf('month').format(daysStringFormat),
    dateTo: moment().add(-1, 'month').endOf('month').format(daysStringFormat),
  },
});

export const painChartConfig: TPainChartConfig[] = [
  {
    name: 'Pain',
    labelClass: 'period-pain-label',
    noDataPosition:
      {
        bottom: '86%',
        left: '47%',
      },
    barChart: {
      yaxis: 'averagePainScore',
      barConfig:
        [
          {
            dataKey: 'motivation',
            fill: '#44C1D8',
          },
          {
            dataKey: 'painScoreCompleted',
            fill: '#FF6464',
          },
          {
            dataKey: 'painScoreSkipStop',
            fill: '#993c3c',
          },
        ],

    },
  },
  {
    name: 'SkipStop',
    labelClass: 'period-exercises-skip-stop-label',
    noDataPosition:
      {
        bottom: '53%',
        left: '47%',
      },
    barChart: {
      yaxis: 'SkipStopNumber',
      barConfig:
        [
          {
            dataKey: 'numberOfExercisesSkipped',
            fill: '#FFA500',
          },
          {
            dataKey: 'numberOfExercisesStopped',
            fill: '#FFD27F',
          },
          {
            dataKey: 'avDesiredNumberOfReps',
            fill: '#B27300',
          },
        ],
    },
  },
  {
    name: 'Steps',
    labelClass: 'steps-label',
    noDataPosition:
      {
        bottom: '20%',
        left: '47%',
      },
    barChart: {
      yaxis: 'Steps',
      barConfig:
        [
          {
            dataKey: 'steps',
            fill: '#44C1D8',
          },
        ],

    },
  },
];

export const stepChartConfig = (t: any): BarChartsConfig => {
  const step_count = t?.dashboard.hcp.profile_patient.completion_and_steps.step_count;
  const steps_t = t?.dashboard.hcp.profile_patient.completion_and_steps.steps;
  return (
    {
      yaxis: 'Steps',
      yaxisName: step_count,
      bars: [
        {
          name: steps_t,
          color: '#44C1D8',
          value: 0,
        },
      ],
    }
  );
};

export const skipStopChartConfig = (t: any): BarChartsConfig => {
  const number_of_times = t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_times;
  const number_of_exercises_skipped = t?.dashboard.hcp.profile_patient.completion_and_steps
    .number_of_exercises_skipped;
  const number_of_exercises_stopped = t?.dashboard.hcp.profile_patient.completion_and_steps
    .number_of_exercises_stopped;
  const number_of_reps = t?.dashboard.hcp.profile_patient.completion_and_steps.number_of_reps;

  return (
    {
      yaxis: 'SkipStopNumber',
      yaxisName: number_of_times,
      bars: [
        {
          name: number_of_exercises_skipped,
          color: '#FFA500',
          value: 0,
        },
        {
          name: number_of_exercises_stopped,
          color: '#FFD27F',
          value: 0,
        },
        {
          name: number_of_reps,
          color: '#B27300',
          value: 0,
        },
      ],
    }
  );
};

export const getMailingFrequencyOptions = (t: any): Options[] => ([
  {
    value: 1,
    label: t?.hcp.manage.daily,
  },
  {
    value: 2,
    label: t?.hcp.manage.weekly,
  },
  {
    value: 3,
    label: t?.hcp.manage.monthly,
  },
]);

export const getReminderPeriodOptions = (t: any): ReminderPeriodOptionsType[] => {
  const week = t?.dashboard.hcp.profile_patient.completion_and_steps.week;
  const weeks = t?.dashboard.hcp.profile_patient.completion_and_steps.weeks;
  const months = t?.dashboard.hcp.profile_patient.completion_and_steps.months;

  const configWeek = [
    {
      value: REMINDER_PERIOD.WEEK_1,
      label: `${1} ${week}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 1,
    },
    {
      value: REMINDER_PERIOD.WEEK_2,
      label: `${2} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 2,
    },
    {
      value: REMINDER_PERIOD.WEEK_3,
      label: `${3} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 3,
    },
    {
      value: REMINDER_PERIOD.WEEK_4,
      label: `${4} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 4,
    },
    {
      value: REMINDER_PERIOD.WEEK_5,
      label: `${5} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 5,
    },
    {
      value: REMINDER_PERIOD.WEEK_6,
      label: `${6} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 6,
    },
    {
      value: REMINDER_PERIOD.WEEK_7,
      label: `${7} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 7,
    },
    {
      value: REMINDER_PERIOD.WEEK_8,
      label: `${8} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 8,
    },
    {
      value: REMINDER_PERIOD.WEEK_9,
      label: `${9} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 9,
    },
    {
      value: REMINDER_PERIOD.WEEK_10,
      label: `${10} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 10,
    },
    {
      value: REMINDER_PERIOD.WEEK_11,
      label: `${11} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 11,
    },
    {
      value: REMINDER_PERIOD.WEEK_12,
      label: `${12} ${weeks}`,
      timePeriod: PERIOD_TYPE.WEEK,
      amount: 12,
    },
  ];
  const configMonth = [
    {
      value: REMINDER_PERIOD.MONTH_4,
      label: `${4} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 4,
    },
    {
      value: REMINDER_PERIOD.MONTH_5,
      label: `${5} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 5,
    },
    {
      value: REMINDER_PERIOD.MONTH_6,
      label: `${6} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 6,
    },
    {
      value: REMINDER_PERIOD.MONTH_7,
      label: `${7} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 7,
    },
    {
      value: REMINDER_PERIOD.MONTH_8,
      label: `${8} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 8,
    },
    {
      value: REMINDER_PERIOD.MONTH_9,
      label: `${9} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 9,
    },
    {
      value: REMINDER_PERIOD.MONTH_10,
      label: `${10} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 10,
    },
    {
      value: REMINDER_PERIOD.MONTH_11,
      label: `${11} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 11,
    },
    {
      value: REMINDER_PERIOD.MONTH_12,
      label: `${12} ${months}`,
      timePeriod: PERIOD_TYPE.MONTH,
      amount: 12,
    },
  ];

  return [...configWeek, ...configMonth];
};

export const getScheduleNumberOptions = (): Options[] => {
  const options = [];
  for (let i = 1; i <= 15; i += 1) {
    options.push({
      value: i,
      label: String(i),
    });
  }
  return options;
};

export const getUnitOptions = (
  t_compl_steps: {days: string; weeks: string; months: string},
): Options[] => {
  const days_t = t_compl_steps?.days;
  const weeks = t_compl_steps?.weeks;
  const months = t_compl_steps?.months;
  return [
    { value: 1, label: days_t }, { value: 2, label: weeks }, { value: 3, label: months },
  ];
};

export const timerPeriod = {
  1: 'Days',
  2: 'Weeks',
  3: 'Months',
};
