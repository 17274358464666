/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Core
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { toast } from 'react-toastify';
// Components
import {
  GetState,
  getCurrenLang,
  getHcpName,
  getIsNotSavedPatientInfo,
  getIsNotSavedPatientProfile,
} from '../../redux/selector';
import { withAuthContext } from '../../context/withAuthContext';
import { useLogout } from '../../graphql/auth';
import { useHeaderStyles } from '../../hooks/useStyles';
import LoginHeaderApp from '../../common/Header/index';
// Instruments
import {
  ExpandMoreIcon,
  PersonIcon,
  MenuItem,
  Menu,
  AppBar,
  ProfileIcon,
  InvitationIcon,
  ManageNotificationsIcon,
  ExitIcon,
  PencilIcon,
  CloseIcon,
  RewardIcon,
  ExerciseIcon,
  TeamIcon,
} from '../../theme/icons';
import { path } from '../../utils/routers/book';
import { useQueryAdminCountry, useQueryUserName } from '../../graphql/hcpProfile';
import storage from '../../utils/storage';
import styles from './styles.module.css';
import shareStyle from '../../pages/ManageExercises/styles.module.css';
import Logo from '../../theme/image/logoOTM.svg';
import LogoBig from '../../theme/image/logo.svg';
import GlobalNotification from './globalNotification';
import { LIST_TYPE, PATH_TYPE } from '../../utils/enums';
import { getQueryParams, initialPage } from '../../utils/helper';
import { hcpArray, patientArray } from '../../utils/variables';
import { PopupWithTwoButtons } from '../../common/Popup';
import { Button, ConfirmatioModal, Loading, Modal, WarningNotification } from '../../common';
import PandingPatientInvitations from '../patientInvitations/patientInvitations';
import { usePatientsPendingInvitations } from '../../graphql/patients';
import {
  useGetSharingNotification,
  useSetHcpInformedAboutSharing,
  useSharingTeamVideobank,
} from '../../graphql/hcp';
import { NotificationSucceess } from '../../common/NotificationModal';
import Feedback from '../Feedback';
import useCheckRole from '../../hooks/useCheckRole';

const HeaderApp: FC = () => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const admin = t?.header.admin || 'Admin';
  const logout_btn = t?.menu.logout;
  const profile = t?.menu.profile;
  const pending_patient_invitations = t?.menu.pending_patient_invitations;
  const manage_notifications = t?.menu.manage_notifications;
  const manage_organisations = t?.menu.manage_organisations;
  const patient_list = t?.title.patient_list;
  const hcp_list = t?.title.hcp_list;
  const cancel = t?.common.cancel;
  const exit = t?.common.exit;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;
  const manage_professions = t?.menu.manage_professions;
  const manage_proms = t?.menu.manage_proms;
  const manage_rewards = t?.menu.manage_rewards;
  const manage_exercises = t?.menu.manage_exercises;
  const yes = t?.common.yes;
  const no = t?.common.no;
  const ok = t?.common.ok;
  const success = t?.profile_hcp.success;
  const video_successfully_shared = t?.hcp.manage.video_successfully_shared;
  const share = t?.common.share;
  const all_video_will_be_available = t?.hcp.manage.all_video_will_be_available;
  const manage_patient_nformation = t?.menu.manage_patient_nformation;
  const manage_departments = t?.menu.manage_departments;
  const manage_teams = t?.menu.manage_teams;

  // Local states
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isModalOpen, setModal] = useState(false);
  const [isModalShareVideo, setModalShareVideo] = useState(false);
  const [currentRedirectTabType, setCurrentRedirectTabType] = useState('');
  const [isShareWarning, setWarning] = useState(false);
  const [isSharedSuccess, setSharedSuccess] = useState(false);

  const { user, expand } = useHeaderStyles();
  const isNotSavedPatientProfile = useSelector<any>((state) => getIsNotSavedPatientProfile(state));
  const isNotSavedPatientInfo = useSelector((state: GetState) => getIsNotSavedPatientInfo(state));
  const hcp = useSelector((state: GetState) => getHcpName(state));

  const location = useLocation();
  const history = useHistory();
  const fromLS = storage.get('user');
  const currentHcpEmail = fromLS && fromLS.email;
  const { isAdmin, isHcp } = useCheckRole();
  const homePath = isAdmin ? path.adminDashboard : path.dashboard;
  const today = moment().format('DD-MM-YYYY');
  const showModalDate = storage.get('show-modal-date');
  const { category } = getQueryParams(location.search);

  // const {_getUserName, loading, hcp } = useQueryUserName(!isHcp);
  const { _getUserName, loading } = useQueryUserName(!hcp.recipients?.length);
  useQueryAdminCountry(!isAdmin);
  const {
    _getPatientInvitations,
    patientsInvitations,
    total,
    listLoading,
  } = usePatientsPendingInvitations();
  const { _getSharingNotification, dataSharingNotif } = useGetSharingNotification();
  const {
    _setHcpInformedAboutSharing,
    setHcpInformedLoading,
    setHcpInformedError,
    isSetHcpInformed,
  } = useSetHcpInformedAboutSharing();

  // Share VideoBank to another HCP(Hospital)
  const { _shareVideobank, isSharingSuccess, shareLoading, shareError } = useSharingTeamVideobank();

  const categoryFromQuery = String(category);
  const isPatientCategory = patientArray.includes(categoryFromQuery);
  const isHCPCategory = hcpArray.includes(categoryFromQuery);

  const { onLogout } = useLogout(isAdmin);
  const open = Boolean(anchorEl);

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const logout = (): void => {
    onLogout();
    const redirectTo = isAdmin ? path.adminSignIn : path.signIn;

    history.push(redirectTo);
    storage.save('user', '');
    storage.save(currentHcpEmail, today);
  };

  const handlePath = (type: string): void => {
    switch (type) {
      case PATH_TYPE.PROFILE:
        history.push({ pathname: path.profile });
        break;
      case PATH_TYPE.PENDING_PATIENT_INVITATIONS:
        history.push({ pathname: path.pending_patient_invitations });
        break;
      case PATH_TYPE.MANAGE_NOTIFICATIONS:
        history.push({ pathname: path.manage_notifications });
        break;
      case PATH_TYPE.MANAGE_ORGANISATION:
        history.push({ pathname: path.manage_organisations });
        break;
      case PATH_TYPE.MANAGE_PROFESSIONS:
        history.push({ pathname: path.manage_professions });
        break;
      case PATH_TYPE.MANAGE_PROMS:
        history.push({ pathname: path.manage_proms });
        break;
      case PATH_TYPE.MANAGE_REWARDS:
        if (isAdmin) {
          history.push({ pathname: path.manage_rewards_admin });
        }
        if (isHcp) {
          history.push({ pathname: path.manage_rewards_hcp });
        }
        break;
      case PATH_TYPE.MANAGE_EXERCISES:
        history.push({ pathname: path.manage_exercises });
        break;
      case PATH_TYPE.MANAGE_DEPARTMENTS:
        history.push({ pathname: path.manage_departments });
        break;
      case PATH_TYPE.MANAGE_TEAMS:
        history.push({ pathname: path.manage_teams });
        break;
      case LIST_TYPE.PATIENT:
        if (isAdmin) {
          history.push({
            pathname: path.adminDashboard,
            search: `category=${LIST_TYPE.PATIENT_ADMIN}&page=${initialPage}`,
          });
        } else {
          history.push({
            pathname: path.dashboard,
            search: `category=${LIST_TYPE.PATIENT}&page=${initialPage}`,
          });
        }
        break;
      case LIST_TYPE.HCP:
        if (isAdmin) {
          history.push({
            pathname: path.adminDashboard,
            search: `category=${LIST_TYPE.HCP_ADMIN}&page=${initialPage}`,
          });
        } else {
          history.push({
            pathname: path.dashboard,
            search: `category=${LIST_TYPE.HCP}&page=${initialPage}`,
          });
        }
        break;
      case PATH_TYPE.MANAGE_PATIENT_INFORMATION: {
        const pathname = isAdmin
          ? path.manage_patient_information_admin
          : path.manage_patient_information;
        history.push({ pathname });
        break;
      }
      default:
        history.push({ pathname: homePath });
    }
  };

  // Check unsaved Patient profile
  const checkUnsavedPatientProfile = (tabType: string): void => {
    if (isNotSavedPatientProfile || isNotSavedPatientInfo) {
      setCurrentRedirectTabType(tabType);
      return setShowPopup(() => true);
    }
    if (tabType === 'logout') {
      return logout();
    }
    return handlePath(tabType);
  };

  // Clouse popup
  const onClose = (): void => {
    setCurrentRedirectTabType('');
    setShowPopup(() => false);
  };

  // Confirm method
  const onConfirm = (): void => {
    if (currentRedirectTabType === 'logout') {
      logout();
    } else {
      handlePath(currentRedirectTabType);
    }
    onClose();
  };

  const clickLogoHandler = (): void => {
    const key = isAdmin ? LIST_TYPE.HCP : LIST_TYPE.PATIENT;
    checkUnsavedPatientProfile(key);
  };

  useEffect(() => {
    // if (!isAdmin && showModalDate !== today) {
    if (!isAdmin) {
      _getUserName();
      _getPatientInvitations({
        variables: {
          listProps: {
            page: 1,
            itemsPerPage: 15,
          },
          pending: true,
          searchByDate: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (patientsInvitations && patientsInvitations.length > 0) {
      let isNewRequest = false;

      if (showModalDate.patientsIds) {
        patientsInvitations.map((patient: { id: number }) => {
          if (!showModalDate.patientsIds.includes(patient.id)) {
            isNewRequest = true;
          }
          return null;
        });
      }

      if (isNewRequest) {
        setModal(() => true);
        return;
      }

      if (showModalDate.today !== today) {
        setModal(() => true);
      }
    }
  }, [patientsInvitations]);

  const onclose = (): void => {
    const patientsIds = patientsInvitations.map((patient: { id: number }) => patient.id);
    storage.save('show-modal-date', {
      today,
      patientsIds,
    });
    setModal(() => false);
  };

  // If share error then show message.
  useEffect(() => {
    if (isSharingSuccess) {
      setWarning(() => false);
      setSharedSuccess(() => true);
      _setHcpInformedAboutSharing(hcp.recipients[0].id);
    }
  }, [isSharingSuccess]);

  // If share error then show message.
  useEffect(() => {
    if (shareError) {
      toast.error(shareError);
      _setHcpInformedAboutSharing(hcp.recipients[0].id);
    }
  }, [shareError]);

  // If success then update User params.
  useEffect(() => {
    if (isSetHcpInformed && !isAdmin) {
      _getUserName();
    }
  }, [isSetHcpInformed]);

  // If error show message.
  useEffect(() => {
    if (setHcpInformedError && !isAdmin) {
      toast.error(setHcpInformedError);
      _getUserName();
    }
  }, [setHcpInformedError]);

  // Check if there are any unread notifications about sharing TeamVideobank from another hospital.
  useEffect(() => {
    if (dataSharingNotif) {
      setModalShareVideo(() => true);
    }
  }, [dataSharingNotif]);

  // Check if there are any unread notifications about sharing TeamVideobank from another hospital.
  useEffect(() => {
    if (hcp?.recipients.length > 0) {
      _getSharingNotification({
        variables: {
          hcpId: hcp.recipients[0].initiatorId,
        },
      });
    }
  }, [hcp.recipients]);

  // Clouse shareModal and send to DB cancel
  const onCancelShare = (): void => {
    setModalShareVideo(() => false);
    setWarning(() => false);
    _setHcpInformedAboutSharing(hcp.recipients[0].id);
  };

  // Share team video bank to another organisation
  const showWarningToShareVideo = (): void => {
    setModalShareVideo(() => false);
    setWarning(() => true);
  };
  // Send request to DB
  const shareVideoBank = (): void => {
    _shareVideobank(hcp.recipients[0].initiatorId);
  };

  // JSX
  const hcpMenuItems = (
    <>
      {isHcp && (
        <>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.PROFILE)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={ProfileIcon} alt="profile" />
              </div>
              {profile}
            </MenuItem>
          </div>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.PENDING_PATIENT_INVITATIONS)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={InvitationIcon} alt="pending" />
              </div>
              {pending_patient_invitations}
            </MenuItem>
          </div>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_NOTIFICATIONS)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={ManageNotificationsIcon} alt="manage" />
              </div>
              {manage_notifications}
            </MenuItem>
          </div>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_EXERCISES)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={ExerciseIcon} alt="manage" />
              </div>
              {manage_exercises}
            </MenuItem>
          </div>
          {/* <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_PATIENT_LIST_ACCESS)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={ManyPeopleIcon} alt="manage" />
              </div>
              {manage_patient_list_access}
            </MenuItem>
          </div> */}
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_TEAMS)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={TeamIcon} alt="teams" />
              </div>
              {manage_teams}
            </MenuItem>
          </div>
        </>
      )}
    </>
  );
  const adminMenuItems = (
    <>
      {isAdmin && (
        <>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_ORGANISATION)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={PencilIcon} alt="organisation" />
              </div>
              {manage_organisations}
            </MenuItem>
          </div>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_PROFESSIONS)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={PencilIcon} alt="professions" />
              </div>
              {manage_professions}
            </MenuItem>
          </div>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_PROMS)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={PencilIcon} alt="professions" />
              </div>
              {manage_proms}
            </MenuItem>
          </div>
          <div
            onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_DEPARTMENTS)}
            role="presentation"
          >
            <MenuItem onClick={handleClose}>
              <div className={styles.menu__icon}>
                <img src={TeamIcon} alt="professions" />
              </div>
              {manage_departments}
            </MenuItem>
          </div>
        </>
      )}
    </>
  );
  const hcpPatientTabsList = (
    <div className={styles['header__tabs-container-list']}>
      {/* Patients TAB */}
      <div className={styles['header__tab-container']}>
        <div
          className={styles.header__tab}
          onClick={(): void => checkUnsavedPatientProfile(LIST_TYPE.PATIENT)}
          role="tabpanel"
        >
          {patient_list}
        </div>
        <div
          className={cx({
            [styles['header__tab-underline']]: true,
            [styles['header__tab--active']]: isPatientCategory,
          })}
        />
      </div>

      {/* Hcp TAB */}
      <div className={styles['header__tab-container']}>
        <div
          className={styles.header__tab}
          onClick={(): void => checkUnsavedPatientProfile(LIST_TYPE.HCP)}
          role="tabpanel"
        >
          {hcp_list}
        </div>
        <div
          className={cx({
            [styles['header__tab-underline']]: true,
            [styles['header__tab--active']]: isHCPCategory,
          })}
        />
      </div>
    </div>
  );

  const loadingJSX = setHcpInformedLoading || shareLoading ? <Loading /> : <></>;

  return (
    <>
      {loadingJSX}
      {(isAdmin || isHcp) ? (
        <AppBar color="transparent">
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles['header__logo-tabs-container']}>
                {/* LOGO */}
                <img
                  src={Logo}
                  alt="logo web app"
                  onClick={clickLogoHandler}
                  className={cx({
                    [styles.logo]: true,
                    [styles.visibleLogo]: true,
                  })}
                />
                <img
                  src={LogoBig}
                  alt="logo web app"
                  onClick={clickLogoHandler}
                  className={cx({
                    [styles.logo]: true,
                    [styles.hiddenLogo]: true,
                  })}
                />

                {/* LIST TABS (full screen) */}
                <div className={styles.fullScreenTabs}>{hcpPatientTabsList}</div>
              </div>
              <div className={styles['menu-container']}>
                {isHcp && <GlobalNotification />}
                {isAdmin ? <span className={styles.greetingAdmin}>{admin}</span> : <></>}
                <div
                  className={styles['user-container']}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  role="presentation"
                >
                  <PersonIcon className={user} />
                  <span className={styles.user}>
                    {!loading && hcp && (
                      <>
                        {hcp.firstName} {hcp.lastName}
                      </>
                    )}{' '}
                  </span>
                  <div className={styles['expand-icon']}>
                    <ExpandMoreIcon className={expand} />
                  </div>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                >
                  <div className={styles.contant_profile}>
                    {hcpMenuItems}
                    {adminMenuItems}
                    <div
                      onClick={(): void => checkUnsavedPatientProfile(PATH_TYPE.MANAGE_REWARDS)}
                      role="presentation"
                    >
                      <MenuItem onClick={handleClose}>
                        <div className={styles.menu__icon}>
                          <img src={RewardIcon} alt="rewards" />
                        </div>
                        {manage_rewards}
                      </MenuItem>
                    </div>
                    <div
                      onClick={(): void => checkUnsavedPatientProfile(
                        PATH_TYPE.MANAGE_PATIENT_INFORMATION,
                      )}
                      role="presentation"
                    >
                      <MenuItem onClick={handleClose}>
                        <div className={styles.menu__icon}>
                          <img src={PencilIcon} alt="manage video" />
                        </div>
                        {manage_patient_nformation}
                      </MenuItem>
                    </div>
                    <MenuItem onClick={(): void => checkUnsavedPatientProfile('logout')}>
                      <div className={styles.menu__icon}>
                        <img src={ExitIcon} alt="exit" />
                      </div>
                      {logout_btn}
                    </MenuItem>
                  </div>
                </Menu>
              </div>
            </div>

            {/* LIST TABS (full screen) */}
            <div className={styles.mobileScreenTabs}>{hcpPatientTabsList}</div>
          </div>
        </AppBar>
      ) : (
        <LoginHeaderApp />
      )}
      {showPopup && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirm}
          onClosePopup={onClose}
        />
      )}

      {/* Popup for pending patients */}
      {isModalOpen && !isModalShareVideo && !isShareWarning && !isSharedSuccess && (
        <>
          <Modal onClose={onclose} style={styles['pending-modal']}>
            <div className={styles.close}>
              <CloseIcon onClick={onclose} />
            </div>
            <PandingPatientInvitations
              isModal
              _getPatientInvitations={_getPatientInvitations}
              patientsInvitations={patientsInvitations}
              total={total}
              listLoading={listLoading}
            />
          </Modal>
        </>
      )}

      {/* Popup if share team videobank anoter HCP */}
      {isModalShareVideo && !isModalOpen && !isSharedSuccess && (
        <Modal style={styles['share-modal']} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <div>
            <NotificationSucceess
              title={dataSharingNotif?.title || ''}
              contant={dataSharingNotif?.body || ''}
            />
            <div className={styles['share-modal__btn-wrapper']}>
              <Button
                buttonType="button"
                buttonName={dataSharingNotif.isTwoWaySharing ? ok : no}
                buttonMethod={onCancelShare}
              />
              {!dataSharingNotif.isTwoWaySharing && (
                <Button
                  buttonType="button"
                  buttonName={yes}
                  buttonMethod={showWarningToShareVideo}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
      {isShareWarning && (
        <Modal style={shareStyle.modal} onClose={onCancelShare}>
          <div className="btn-close">
            <CloseIcon onClick={onCancelShare} />
          </div>
          <WarningNotification
            title=""
            contant={all_video_will_be_available?.replace(
              '<organisationName>', hcp.recipients?.[0]?.hcpInitiator?.hospital?.name || ''
            ) || ''}
            buttonName={share}
            onCancel={onCancelShare}
            onSubmit={shareVideoBank}
          />
        </Modal>
      )}
      {isSharedSuccess && (
        <Modal
          style={shareStyle.modal__success}
          onClose={(): void => setSharedSuccess(() => false)}
        >
          <ConfirmatioModal
            title={success}
            contant={video_successfully_shared}
            buttonTitle={ok}
            buttonMethod={(): void => setSharedSuccess(() => false)}
          />
        </Modal>
      )}
      {isHcp ? <Feedback /> : <></>}
    </>
  );
};

export default withAuthContext(HeaderApp);
