import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Tooltip } from 'antd';

import { Button } from '../../../common';
import styles from '../styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import { PatientInvitationProp } from '../types';

const PatientInvitation = ({
  invitation, isModal, acceptHandler, loading,
}: PatientInvitationProp): ReactElement => {
  const windowClientWidth = useSelector((state: any) => state.common.screen.innerWidth);
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const accept = t?.notifications.accept;
  const decline = t?.notifications.decline;
  const {
    id, firstName, lastName, email,
  } = invitation;

  const concatName = (): string => {
    if (windowClientWidth > 950) {
      if (!isModal) {
        return firstName;
      }
      return `${firstName} ${lastName}`;
    }
    return `${firstName} ${lastName}`;
  };

  return (
    <div
      className={cx({
        [styles.table__row]: true,
        [styles['table__row-modal']]: isModal,
      })}
    >
      <Tooltip title={windowClientWidth > 950 ? firstName : `${firstName} ${lastName}`}>
        <div
          className={cx({
            [styles['table__column-item-size']]: true,
            [styles['table__column-item']]: true,
          })}
        >
          {concatName()}
        </div>
      </Tooltip>
      <Tooltip title={lastName}>
        <div
          className={cx({
            [styles['table__column-item-size']]: true,
            [styles['table__column-item']]: true,
            [styles['full-screen']]: true,
            [styles['hide-in-modal']]: isModal,
          })}
        >
          {lastName}
        </div>
      </Tooltip>
      <Tooltip title={email}>
        <div
          className={cx({
            [styles['table__column-email-size']]: true,
            [styles['table__column-item']]: true,
            [styles['table__column-email']]: true,
          })}
        >
          {email}
        </div>
      </Tooltip>
      <div
        className={cx({
          [styles['table__column-btn-cont-size']]: !isModal,
          [styles['table__column-btn-cont-size-modal']]: isModal,
          [styles['table__row-btn-container']]: true,
        })}
      >
        <Button
          buttonName={accept}
          buttonType="button"
          buttonClass={styles['btn__accept-invitation']}
          buttonMethod={(): void => acceptHandler('accept', id)}
          disabledButton={loading}
          colorStyle="green"
        />
        <Button
          buttonName={decline}
          buttonType="button"
          buttonClass={styles['btn__decline-invitation']}
          buttonMethod={(): void => acceptHandler('decline', id)}
          disabledButton={loading}
        />
      </div>
    </div>
  );
};

export default PatientInvitation;
