/* eslint-disable react/button-has-type */
import React, { ReactElement } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { ButtonProps } from './types';
import { Plus, PlusGray } from '../../theme/icons';

export const Button = ({
  buttonName,
  buttonMethod,
  disabledButton,
  buttonType,
  children,
  active = false,
  buttonClass,
  colorStyle = 'orange',
}: ButtonProps): ReactElement => (
  <button
    className={cx({
      [styles.button]: true,
      [styles.disabled]: disabledButton,
      [styles[`active_${colorStyle}`]]: active,
      [buttonClass]: buttonClass,
      [styles[colorStyle]]: true,
    })}
    type={buttonType}
    disabled={disabledButton && disabledButton}
    onClick={buttonMethod && buttonMethod}
  >
    {buttonName && buttonName}
    {children}
  </button>
);

export const CommonButton = (props: any): ReactElement => {
  const { children, ...rest } = props;
  return (
    <button {...rest}>
      {children}
    </button>
  );
};

export const PlusNewItem = ({
  description,
  buttonMethod,
  disable = false,
}: any): ReactElement => (
  <div
    className={cx({
      [styles.edit__icon]: true,
      [styles['edit__descr--disable']]: disable,
    })}
    onClick={disable ? (): null => (null) : buttonMethod}
    role="presentation"
  >
    <div className={styles['edit__icon-plus']}>
      {disable ? <PlusGray /> : <Plus />}
    </div>
    <span className={cx({
      [styles.edit__descr]: true,
      [styles['edit__descr--disable']]: disable,
    })}
    >
      {description}
    </span>
  </div>
);
