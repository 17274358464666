import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from '../styles.module.css';
import {
  setNotificationsList,
  setvisibleNotificationList,
  setNotificationsStatus,
  pulling,
  getTimer,
  changeNotificationStatus,
} from '../../../redux/notifications';
import { ITEMS_PER_PAGE } from '../../../utils/variables';
import BellIcon from './bellIcon';
import NotificationsList from './notifications';
import { NOTIF_STATUS } from '../../../utils/enums';

const GlobalNotification: FC = () => {
  const countUnread: any = useSelector<any>((state) => state.notifications.countUnread);
  const notificationsList: any = useSelector<any>((state) => state.notifications.notificationsList);
  const visibleNotificationList: any = useSelector<any>((state) => state
    .notifications.visibleNotificationList);
  const notificationsStatus: any = useSelector<any>((state) => state
    .notifications.notificationsStatus);
  const totalPages: any = useSelector<any>((state) => state.notifications.totalPages);
  const itemsPerPageCumulative: any = useSelector<any>((state) => state
    .notifications.itemsPerPageCumulative);
  const checkedSwitch = notificationsStatus === NOTIF_STATUS.UNREAD;

  const dispatch = useDispatch();

  // Get notifications data from DB
  useEffect(() => {
    dispatch(setNotificationsList(ITEMS_PER_PAGE));
  }, [notificationsStatus]);

  useEffect(() => {
    dispatch(pulling());
    return (): void => {
      clearTimeout(getTimer());
    };
  }, []);

  // Open/close notification window
  const displayNotifications = (): void => {
    dispatch(setvisibleNotificationList(!visibleNotificationList));
  };

  // Get next page from DB
  const setNextPage = (): void => {
    dispatch(setNotificationsList(itemsPerPageCumulative + ITEMS_PER_PAGE));
  };

  // Switch notifikation status (read/unread)
  const switchHandler = (checked: boolean): void => {
    if (checked) dispatch(setNotificationsStatus(NOTIF_STATUS.UNREAD));
    else dispatch(setNotificationsStatus(NOTIF_STATUS.READ));
  };

  // Change current notification status (read/unread)
  const changeStatusHandler = (hcpNotificationId: number): void => {
    dispatch(changeNotificationStatus(hcpNotificationId));
  };

  document.onkeyup = (event: any): void => {
    if (event.code) dispatch(setvisibleNotificationList(false));
  };
  document.onclick = (event: any): boolean | any => {
    const target: string = event?.target?.className;
    if (typeof target === 'string') {
      if (!target.includes('notification')) {
        if (!target.includes('ant-switch')) {
          if (!target.includes('ant-tooltip')) {
            dispatch(setvisibleNotificationList(false));
          }
        }
      }
    }
  };

  return (
    <>
      <div className={styles['notification-container']}>

        {/* ICON BELL */}
        <BellIcon
          notificationsList={notificationsList}
          visibleNotificationList={visibleNotificationList}
          countUnread={countUnread}
          displayNotifications={displayNotifications}
        />

        {/* NOTIFICATIONS LIST */}
        <NotificationsList
          notificationsList={notificationsList}
          visibleNotificationList={visibleNotificationList}
          totalPages={totalPages}
          switchHandler={switchHandler}
          setNextPage={setNextPage}
          changeStatusHandler={changeStatusHandler}
          checkedSwitch={checkedSwitch}
        />
      </div>
    </>
  );
};

export default GlobalNotification;
