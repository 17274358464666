// CONSTANTS
export const FILL_PATIENT_PROFILE = 'FILL_PATIENT_PROFILE';

const UPLOAD_VIDEO_FIRST_STEP = 'UPLOAD_VIDEO_FIRST_STEP';
const CLEAR_UPLOAD_VIDEO_STATE = 'CLEAR_UPLOAD_VIDEO_STATE';
const LOADING_PATIENT_PROFILE = 'LOADING_PATIENT_PROFILE';
const SET_NOT_SAVED_PATIENT_PROFILE = 'SET_NOT_SAVED_PATIENT_PROFILE';
const SET_NOT_SAVED_PATIENT_INFO = 'SET_NOT_SAVED_PATIENT_INFO';

// TYPES
export interface PatientFillPeriondAction {
  type: typeof FILL_PATIENT_PROFILE;
  payload: DataType;
}
export interface DataType {
  data: object;
}
interface TTabNumber {
  type: string;
  payload: any;
}
export interface PatientState {
  profile: any;
  loading: boolean;
  isNotSavedProfileData: boolean;
  isNotSavedPatientInfo: boolean;
  videoBank: {
    candidat: any;
  };
}

// ACTIONS
export function fillPatientProfile(payload: any): PatientFillPeriondAction {
  return {
    type: FILL_PATIENT_PROFILE,
    payload,
  };
}
export function loadingPatientProfile(payload: any): any {
  return {
    type: LOADING_PATIENT_PROFILE,
    payload,
  };
}
export const uploadVideoFirstStep = (value: any): TTabNumber => ({
  type: UPLOAD_VIDEO_FIRST_STEP,
  payload: value,
});
export const interruptDownloadProcess = (): any => ({
  type: CLEAR_UPLOAD_VIDEO_STATE,
});
export const setNotSavedPatientProfile = (value: any): TTabNumber => ({
  type: SET_NOT_SAVED_PATIENT_PROFILE,
  payload: value,
});
export const setNotSavedPatientInfo = (value: any): TTabNumber => ({
  type: SET_NOT_SAVED_PATIENT_INFO,
  payload: value,
});

const init: PatientState = {
  profile: null,
  loading: false,
  isNotSavedProfileData: false,
  isNotSavedPatientInfo: false,
  videoBank: {
    candidat: {
      exerciseArchived: null,
      exerciseCategories: null,
      exerciseComment: null,
      exerciseId: null,
      exerciseName: null,
      exerciseReps: null,
      exerciseRestDays: null,
      exerciseSets: null,
      exerciseThumbnailUploaded: null,
      exerciseTime: null,
      exerciseTimesPerDay: null,
      exerciseVideoUploaded: null,
      id: null,
      name: '',
      status: 'Inactive',
      thumbnailName: '',
      videoName: '',
      isUploadProcess: false,
      typeFile: '',
      fileNameOrigin: '',
    },
  },
};

// Reducer
export const patientdReduser = (state = init, action: any) => {
  switch (action.type) {
    case FILL_PATIENT_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case LOADING_PATIENT_PROFILE:
      return {
        ...state,
        loading: action.payload,
      };
    case UPLOAD_VIDEO_FIRST_STEP:
      return {
        ...state,
        videoBank: { candidat: action.payload },
      };
    case CLEAR_UPLOAD_VIDEO_STATE:
      return {
        ...state,
        videoBank: { candidat: init.videoBank.candidat },
      };
    case SET_NOT_SAVED_PATIENT_PROFILE:
      return {
        ...state,
        isNotSavedProfileData: action.payload,
      };
    case SET_NOT_SAVED_PATIENT_INFO:
      return {
        ...state,
        isNotSavedPatientInfo: action.payload,
      };
    default:
      return state;
  }
};
