/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Tooltip } from 'antd';
import styles from '../../styles.module.css';
import { GetState, getCurrenLang, getIsNotSavedPatientInfo, getIsNotSavedPatientProfile } from '../../../../redux/selector';
import { GlobalNotificationItemProp, TRedirectHandlerProp } from '../../types';
import { NOTIF_STATUS, PATIENT_PROFILE_TABS } from '../../../../utils/enums';
import { initialPage, setQueryParams } from '../../../../utils/helper';
import { PopupWithTwoButtons } from '../../../../common/Popup';
import { path } from '../../../../utils';

const GlobalNotificationItem: FC<GlobalNotificationItemProp> = ({
  notification,
  visibleNotificationList,
  changeStatusHandler,
}: GlobalNotificationItemProp) => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const markAsRead = t?.header.mark_as_read;
  const markAsUnread = t?.header.mark_as_unread;
  const cancel = t?.common.cancel;
  const exit = t?.common.exit;
  const warning_unsaved_changes = t?.common.warning_unsaved_changes;

  const history = useHistory();
  const isNotSavedPatientProfile = useSelector<any>((state) => getIsNotSavedPatientProfile(state));
  const isNotSavedPatientInfo = useSelector((state: GetState) => getIsNotSavedPatientInfo(state));
  const [showPopup, setShowPopup] = useState(false);
  const [currentRedirectTabType, setCurrentRedirectTabType] = useState<TRedirectHandlerProp | null>(null);

  // Redirect from notification message
  const redirectHandler = ({
    type,
    patientId,
    hcpNotificationId,
    readState,
  }: TRedirectHandlerProp): void => {
    if (readState === NOTIF_STATUS.UNREAD) {
      changeStatusHandler(hcpNotificationId);
    }
    switch (type) {
      case 'VIDEOBANK':
        history.push({
          pathname: `/profile/patient/${patientId}`,
          search: `tab=${PATIENT_PROFILE_TABS.VIDEO_BANK}`,
        });
        return;
      case 'PERSONAL_INFORMATION':
      case 'GOALS':
      case 'EMAIL_NOTIFICATION':
        history.push({
          pathname: `/profile/patient/${patientId}`,
          search: `tab=${PATIENT_PROFILE_TABS.PATIENT_OVERVIEW}`,
        });
        return;
      case 'DOCUMENT':
        history.push({
          pathname: `/profile/patient/${patientId}/from-patient`,
          search: `tab=${PATIENT_PROFILE_TABS.VIDEO_BANK}`,
        });
        return;
      case 'TEAMS':
        history.push({
          pathname: path.manage_teams,
        });
        return;
      default:
        history.push({
          pathname: '/dashboard',
          search: setQueryParams({
            category: 'patient',
            page: initialPage,
          }),
        });
    }
  };

  // Check unsaved Patient profile
  const checkUnsavedPatientProfile = (tabType: TRedirectHandlerProp): void => {
    if (isNotSavedPatientProfile || isNotSavedPatientInfo) {
      setCurrentRedirectTabType(tabType);
      return setShowPopup(() => true);
    }
    return redirectHandler(tabType);
  };

  // Clouse popup
  const onClose = (): void => {
    setCurrentRedirectTabType(null);
    setShowPopup(() => false);
  };

  // Confirm method
  const onConfirm = (): void => {
    if (currentRedirectTabType) {
      redirectHandler(currentRedirectTabType);
    }
    onClose();
  };

  return (
    <div className={styles['notification-row']}>
      <div
        role="presentation"
        className={styles['notification-text-container']}
        onClick={(): void => checkUnsavedPatientProfile(notification)}
      >
        <div className={styles['notification-text-title']}>{notification.title}</div>
        <div className={styles['notification-text']}>{notification.message}</div>
      </div>
      <Tooltip
        overlayStyle={{ zIndex: 4010 }}
        placement="left"
        title={notification.readState === NOTIF_STATUS.UNREAD ? markAsRead : markAsUnread}
      >
        <button
          className={styles['notification-status']}
          type="button"
          onClick={(): void => changeStatusHandler(notification.hcpNotificationId)}
        >
          <div
            className={cx({
              [styles['notification-read-status']]: true,
              [styles.visible]: visibleNotificationList
                && notification.readState === NOTIF_STATUS.UNREAD,
            })}
          />
        </button>
      </Tooltip>
      {showPopup && (
        <PopupWithTwoButtons
          title={warning_unsaved_changes}
          content=""
          confirmButtonName={exit}
          closeButtonName={cancel}
          onConfirm={onConfirm}
          onClosePopup={onClose}
        />
      )}
    </div>
  );
};

export default GlobalNotificationItem;
