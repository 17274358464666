import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { GetInviteePatientExercisePlansProps, GetPatientExercisePlansProps, UseGetAllFolders, UseSaveFolder, VideoExerciseForOverviewPage } from '../types';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { config } from '../../utils/configs';
import { QueryTeamVideoExercises } from '../../components/Video/TeamVideoBank/types';
import { DataVideoExercises, DataVideoExercisesInviteePatient } from '../../components/Video/VideoExercise/types';

// Query
const videoExercisesListOverviewPage = loader('./gql/getVideoExercisesOverviewPage.graphql');
const thumbnailLink = loader('./gql/getThumbnailLink.graphql');
const thumbnailPic = loader('./gql/getThumbnailPic.graphql');
const video = loader('./gql/getVideoLink.graphql');
const photoLink = loader('./gql/getPhotoLink.graphql');
const photoPic = loader('./gql/getPhotoPic.graphql');
const exerciseCategories = loader('./gql/getExerciseCategories.graphql');
const teamVideoBankExercisesList = loader('./gql/getCurrentTeamVideobank.graphql');
const teamVideoBankExercisesMatch = loader('./gql/getCurrentTeamVideobankMatch.graphql');
const queryCurrentFolder = loader('./gql/getCurrentHcpFolder.graphql');
const queryGetAllFolders = loader('./gql/queryGetAllHcpsFolders.graphql');
const videoBankExercisesList = loader('./gql/getCurrentPatientVideobank.graphql');
const videoBankExercisesMatches = loader('./gql/getCurrentPatientVideobankMatches.graphql');
const videoExercListForInviteePatient = loader('./gql/getCurrentInviteePatientVideobank.graphql');
const activeVideoExercListForInviteePatient = loader('./gql/getInviteePatientVideobankForActiveExercises.graphql');
const activeVideoExercListForPatient = loader('./gql/getPatientVideobankForActiveExercises.graphql');
const videoBankExercisesMatchesInvitee = loader('./gql/getCurrentPatientVideobankMatchesInvitee.graphql');
// PDF plans
const getPatientExercisePlans = loader('./gql/queryGetPatientExercisePlans.graphql');
const getInviteePatientExercisePlans = loader('./gql/queryGetInviteePatientExercisePlans.graphql');
const getUrl = loader('./gql/queryGetLink.graphql');

// Mutation
const hcpExercise = loader('./gql/mutationSaveHcpExercise.graphql');
const gqlCreateExerciseForPatient = loader('./gql/mutationCreateExerciseForPatient.graphql');
const hcpExerciseInFolderTeam = loader('./gql/mutationSaveHcpExerciseInFolder.graphql');
const ExerciseByHcp = loader('./gql/mutationSaveExerciseByHcp.graphql');
const saveExerciseFromFolder = loader('./gql/mutationSaveExerciseFromFolderToPatient.graphql');
const saveExerciseFromFolderInvitee = loader('./gql/mutationSaveExerciseFromFolderToInviteePatient.graphql');
const ChangeArchivedStatusExerciseByHcp = loader(
  './gql/mutationChangeArchivedStatusExerciseByHcp.graphql',
);
const deleteExerciseByHcp = loader('./gql/mutationDeleteExerciseByHcp.graphql');
const inviteePatientExercise = loader('./gql/mutationSaveExerciseForInviteePatient.graphql');
const changeArchivedStatusExerciseForInviteePatient = loader(
  './gql/mutationChangeArchivedStatusExerciseForInviteePatient.graphql',
);
const deleteExerciseByHcpForInviteePatient = loader('./gql/mutationDeleteExerciseForInviteePatient.graphql');
const deleteExerciseFromVideobank = loader('./gql/mutationDeleteExerciseFromVideobank.graphql');
const mutationSaveFolder = loader('./gql/mutationSaveHcpFolder.graphql');
const mutationAddExercToFolder = loader('./gql/mutationAddExercisesToHcpFolder.graphql');
const mutationDeleteFolder = loader('./gql/mutationDeleteHcpFolder.graphql');
const mutationSaveFolderSettings = loader('./gql/mutationSaveFolderSettings.graphql');
const mutationSaveAndSendFolderToPatient = loader('./gql/mutationSaveFolderWithExercisesToPatient.graphql');
const mutationSaveAndSendFolderToInviteePatient = loader('./gql/mutationSaveFolderWithExercisesToInviteePatient.graphql');
const emailExercisePlanToInviteePatient = loader('./gql/mutationEmailExercisePlanToInviteePatient.graphql');
const emailExercisePlanToPatient = loader('./gql/mutationEmailExercisePlanToPatient.graphql');

// get thumbnail link
export const useQueryThumbnail = () => {
  const [_getThumbnailByName, { loading, error, data }] = useLazyQuery(thumbnailLink, {
    // fetchPolicy: 'no-cache',
  });
  return {
    _getThumbnailByName,
    loadingThumbnail: loading,
    errorThumbnail: error,
    thumbnail: data && data.getThumbnailLink,
  };
};

// get thumbnail picture
export const useQueryThumbnailPic = () => {
  const [_getThumbnailPic, { loading, error, data }] = useLazyQuery(thumbnailPic, {
    // fetchPolicy: 'no-cache',
  });
  return {
    _getThumbnailPic,
    loadingThumbnailPic: loading,
    errorThumbnailPic: error,
    thumbnailPic: data && data.getThumbnailPic,
  };
};

// get video file link
export const useQueryVideo = () => {
  const [_getVideoByName, { loading, error, data }] = useLazyQuery(video, {
    fetchPolicy: 'no-cache',
  });
  return {
    _getVideoByName,
    loadingVideo: loading,
    errorVideo: error,
    videoSrc: data && data.getVideoLink,
  };
};

// get photo link (from videobank)
export const useQueryPhotoLink = () => {
  const [_getPhotoLink, { loading, error, data }] = useLazyQuery(photoLink, {});
  return {
    _getPhotoLink,
    loadingPhotoLink: loading,
    errorPhotoLink: error,
    photoLink: data && data.getPhotoLink,
  };
};

// get photo pic (from videobank base64)
export const useQueryPhotoPic = () => {
  const [_getPhotoPic, { loading, error, data }] = useLazyQuery(photoPic, {});
  return {
    _getPhotoPic,
    loadingPhotoPic: loading,
    errorPhotoPic: error,
    photoPic: data && data.getPhotoPic,
  };
};

// get exercise categories object (focuses)
export const useQueryExerciseCategories = () => {
  const [_getExerciseCategoriesList, { loading, error, data }] = useLazyQuery(exerciseCategories);
  return {
    _getExerciseCategoriesList,
    loadingExerciseCategoriesList: loading,
    errorExerciseCategoriesList: error,
    exerciseCategoriesList: data && data.getExerciseCategories,
  };
};

// get list video exercises for Overview page
export const useQueryVideoExercisesOverview = (): VideoExerciseForOverviewPage => {
  const [_getVideoExercisesByIdOverview, { loading, error, data }] = useLazyQuery(
    videoExercisesListOverviewPage, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      // pollInterval: config.pollInterval,
    },
  );
  return {
    _getVideoExercisesByIdOverview,
    loading,
    error,
    videoExersises: data && data.getCurrentPatientVideobank,
    videoExersiceData: data,
  };
};

// save video exercises by HCP to Team video bank in DB
export const useSaveHcpExercise = () => {
  const [_save, { data, errorText, loading }] = useMutationRequest<{ saveHcpExercise?: boolean }>(
    hcpExercise,
    {},
  );

  const _saveHcpExercise = (request: DataVideoExercises): void => {
    _save({
      variables: {
        isVideoOwnerHcp: request.isVideoOwnerHcp,
        exerciseCategoriesId: request.exerciseCategoriesId,
        exerciseProcedureTypesId: request.exerciseProcedureTypesId,
        exerciseData: request.exerciseData,
        photos: request.photos,
      },
    });
  };
  return {
    _saveHcpExercise,
    errorSaveExercise: errorText,
    loadingSaveExercise: loading,
    savedExerciseForTeam: data && data.saveHcpExercise,
  };
};

// save video exercises by HCP to Team video bank in DB
export const useSaveHcpExerciseInFolder = () => {
  const [
    _save,
    { data, errorText, loading },
  ] = useMutationRequest<{ saveHcpExerciseInFolder?: boolean }>(hcpExerciseInFolderTeam, {});

  const _saveHcpExerciseInFolderTeam = (request: DataVideoExercises): void => {
    _save({
      variables: {
        folderId: request.folderId,
        exerciseCategoriesId: request.exerciseCategoriesId,
        exerciseProcedureTypesId: request.exerciseProcedureTypesId,
        exerciseData: request.exerciseData,
        photos: request.photos,
      },
    });
  };
  return {
    _saveHcpExerciseInFolderTeam,
    errorSaveExerciseInFolder: errorText,
    loadingSaveExerciseInFolder: loading,
    savedExerciseForTeamInFolder: data && data.saveHcpExerciseInFolder,
  };
};

// get list video exercises for Team video bank page from DB
export const useQueryTeamVideoExercises = (): QueryTeamVideoExercises => {
  const [_getTeamVideoExercises, { loading, error, data }] = useLazyQuery(
    teamVideoBankExercisesList, {
      fetchPolicy: 'no-cache',
      pollInterval: config.pollInterval,
    },
  );
  return {
    _getTeamVideoExercises,
    loading,
    error,
    teamVideoExersises: data && data.getCurrentTeamVideobank,
  };
};

// get list video exercises for Team video bank page from DB
export const useQueryTeamVideoExercisesMatch = () => {
  const [_getTeamVideoExercisesMatch, { loading, data }] = useLazyQuery(
    teamVideoBankExercisesMatch, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  return {
    _getTeamVideoExercisesMatch,
    loadingMatch: loading,
    teamVideoExercisesMatch: data && data.getCurrentTeamVideobank.totalItems,
  };
};

// delete video exercises from Team Videobank
export const useDeleteExerciseFromTeamVideobank = () => {
  const [_deleteExerciseFromTeamVideoBank, { data, error, loading }] = useMutation(
    deleteExerciseFromVideobank,
  );
  return {
    _deleteExerciseFromTeamVideoBank,
    deleteFromTeamData: data && data.deleteExerciseFromVideobank,
    deleteFromTeamError: error,
    deleteFromTeamLoading: loading,
  };
};

// Get Folder settings for Team videobank current folder page
export const useQueryCurrentFolder = () => {
  const [_getFolderSettings, { loading, data, error }] = useLazyQuery(
    queryCurrentFolder, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );
  return {
    _getFolderSettings,
    dataFolder: data && data.getCurrentHcpFolder,
    loadingFolder: loading,
    errorFolder: error,
  };
};

// Save (update) folder settings by HCP from Team video bank
export const useSaveFolder = (): UseSaveFolder => {
  const [
    _saveFolder,
    { data, errorText, loading },
  ] = useMutationRequest<{ saveHcpFolder?: boolean }>(mutationSaveFolder, {});

  return {
    _saveFolder,
    errorSaveFolder: errorText,
    loadingSaveFolder: loading,
    savedFolder: data && data.saveHcpFolder,
  };
};

// Add exercises to exist folder
export const useAddExercisesToFolder = () => {
  const [
    _addExerciseToFolder,
    { data, errorText, loading },
  ] = useMutationRequest<{ addExercisesToHcpFolder?: boolean }>(mutationAddExercToFolder, {});

  return {
    _addExerciseToFolder,
    errorUpdateFolder: errorText,
    loadingUpdateFolder: loading,
    updatedFolder: data && data.addExercisesToHcpFolder,
  };
};

// Delete folder by HCP from Team video bank
export const useDeleteFolder = () => {
  const [
    _deleteFolder,
    { data, errorText, loading },
  ] = useMutationRequest<{ deleteHcpFolder: boolean }>(mutationDeleteFolder, {});

  return {
    _deleteFolder,
    errorDelFolder: errorText,
    loadingDelFolder: loading,
    folderDeleted: data && data.deleteHcpFolder,
  };
};

// Get a list of all folders
export const useGetAllFolderNames = (): UseGetAllFolders => {
  const { loading, error, data } = useQuery(queryGetAllFolders, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  const allFoldersList = data ? data.getAllHcpsFolders : null;

  return {
    allFoldersList,
    errorAllFolders: error,
    loadingAllFolders: loading,
  };
};

// VIDEO BANK
// save and send to patient video exercises
export const useSaveVideoExerciseByHcp = () => {
  const [_save, { data, errorText, loading }] = useMutationRequest<{ saveExerciseByHcp?: boolean }>(
    ExerciseByHcp,
    {},
  );

  const _saveVideoExerciseByHcp = (request: DataVideoExercises): any => {
    _save({
      variables: {
        patientId: request.patientId,
        isVideoOwnerHcp: request.isVideoOwnerHcp,
        exerciseCategoriesId: request.exerciseCategoriesId,
        exerciseProcedureTypesId: request.exerciseProcedureTypesId,
        exerciseData: request.exerciseData,
        photos: request.photos,
      },
    });
  };
  return {
    _saveVideoExerciseByHcp,
    errorMessage: errorText,
    updating: loading,
    savedVideoExercise: data && data.saveExerciseByHcp,
  };
};

// save and send to patient video exercises from folder
export const useSaveExerciseFromFolderToPatient = () => {
  const [
    _save,
    { data, errorText, loading },
  ] = useMutationRequest<{ saveExerciseFromFolderToPatient?: boolean }>(
    saveExerciseFromFolder, {},
  );

  const _saveExerciseFromFolderToPatient = (request: DataVideoExercises): any => {
    _save({
      variables: {
        patientId: request.patientId,
        folderId: request.folderId,
        exerciseCategoriesId: request.exerciseCategoriesId,
        exerciseProcedureTypesId: request.exerciseProcedureTypesId,
        exerciseData: request.exerciseData,
        photos: request.photos,
      },
    });
  };
  return {
    _saveExerciseFromFolderToPatient,
    errorMessageFromFolder: errorText,
    loadingFromFolder: loading,
    savedExerciseFromFolder: data && data.saveExerciseFromFolderToPatient,
  };
};

// Save and send to INVITEE patient video exercises from folder
export const useSaveExerciseFromFolderToInviteePatient = () => {
  const [
    _save,
    { data, errorText, loading },
  ] = useMutationRequest<{ saveExerciseFromFolderToInviteePatient?: boolean }>(
    saveExerciseFromFolderInvitee, {},
  );

  const _saveExerciseFromFolderToInviteePatient = (request: DataVideoExercises): any => {
    _save({
      variables: {
        patientId: request.patientId,
        folderId: request.folderId,
        exerciseCategoriesId: request.exerciseCategoriesId,
        exerciseProcedureTypesId: request.exerciseProcedureTypesId,
        exerciseData: request.exerciseData,
        photos: request.photos,
      },
    });
  };
  return {
    _saveExerciseFromFolderToInviteePatient,
    errorMessageFromFolderInvitee: errorText,
    loadingFromFolderInvitee: loading,
    savedExerciseFromFolderInvitee: data && data.saveExerciseFromFolderToInviteePatient,
  };
};

// archive video exercises
export const useChangeArchivedStatusExerciseByHcp = () => {
  const [_archiving, { data, error, loading }] = useMutation(ChangeArchivedStatusExerciseByHcp);
  return {
    _archiving,
    archiveData: data && data.changeArchivedStatusExerciseByHcp,
    archiveError: error,
    archiveLoading: loading,
  };
};

// change status to inactive video exercises
export const useDeleteExerciseByHcp = () => {
  const [_deleting, { data, error, loading }] = useMutation(deleteExerciseByHcp);
  return {
    _deleting,
    deletedData: data && data.deleteExerciseByHcp,
    deleteError: error,
    deleteLoading: loading,
  };
};

// save video exercises for invitee patient
export const useSaveVideoExerciseForInviteePatient = () => {
  const [_save, { data, errorText, loading }] = useMutationRequest(
    inviteePatientExercise,
    {},
  );

  const _saveVideoExerciseInviteePatient = (request: DataVideoExercisesInviteePatient): any => {
    _save({
      variables: {
        patientId: request.patientId,
        exerciseCategoriesId: request.exerciseCategoriesId,
        exerciseProcedureTypesId: request.exerciseProcedureTypesId,
        exerciseData: request.exerciseData,
        photos: request.photos,
      },
    });
  };
  return {
    _saveVideoExerciseInviteePatient,
    errorMessage: errorText,
    updating: loading,
    savedVideoExercise: data && data.saveExerciseForInviteePatient,
  };
};

// archive video exercises for invitee patient
export const useChangeArchivedStatusExerciseForInviteePatient = () => {
  const [_archiving, { data, error, loading }] = useMutation(
    changeArchivedStatusExerciseForInviteePatient,
  );
  return {
    _archiving,
    archiveData: data && data.changeArchivedStatusExerciseForInviteePatient,
    archiveError: error,
    archiveLoading: loading,
  };
};

// change status to inactive video exercises for invitee patient
export const useDeleteExerciseForInviteePatient = () => {
  const [_deleting, { data, error, loading }] = useMutation(deleteExerciseByHcpForInviteePatient);
  return {
    _deleting,
    deletedData: data && data.deleteExerciseForInviteePatient,
    deleteError: error,
    deleteLoading: loading,
  };
};

// Save (update) folder settings by HCP from patient video bank
export const useSaveFolderSettings = () => {
  const [
    _saveFolderSettings,
    { data, errorText, loading },
  ] = useMutationRequest<{ saveFolderSettings?: boolean }>(mutationSaveFolderSettings, {});

  return {
    _saveFolderSettings,
    errorSaveFolderSettings: errorText,
    loadingSaveFolderSettings: loading,
    folderSettingsSaved: data && data.saveFolderSettings,
  };
};

// Save (update) and send to patient folder settings by HCP from patient video bank
export const useSaveAndSandFolderToPatint = () => {
  const [
    _saveAndSendFolder,
    { data, errorText, loading },
  ] = useMutationRequest<{ saveFolderWithExercisesToPatient?: boolean }>(
    mutationSaveAndSendFolderToPatient, {},
  );

  return {
    _saveAndSendFolder,
    errorSaveAndSendFolder: errorText,
    loadingSaveAndSendFolder: loading,
    folderSavedAndSent: data && data.saveFolderWithExercisesToPatient,
  };
};

// Save (update) and send to Invitee patient folder settings by HCP from patient video bank
export const useSaveAndSandFolderToInviteePatint = () => {
  const [
    _saveAndSendFolderToInviteePatient,
    { data, errorText, loading },
  ] = useMutationRequest<{ saveFolderWithExercisesToInviteePatient?: boolean }>(
    mutationSaveAndSendFolderToInviteePatient, {},
  );

  return {
    _saveAndSendFolderToInviteePatient,
    errorSaveAndSendFolderToInvitee: errorText,
    loadingSaveAndSendFolderToInvitee: loading,
    folderSavedAndSentToInvitee: data && data.saveFolderWithExercisesToInviteePatient,
  };
};

// Get list video exercises for patient videobank
export const useQueryVideoExercises = (isPollInterval = true) => {
  const [_getVideoExercisesById, { loading, error, data }] = useLazyQuery(videoBankExercisesList, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    pollInterval: isPollInterval ? 15000 : 0,
  });
  return {
    _getVideoExercisesById,
    loading,
    error,
    videoExersises: data && data.getCurrentPatientVideobank,
    videoExersisesData: data,
  };
};

// Number of exercises in the database matching the search in patient videobank
export const useFindMatchesVideoExercises = () => {
  const [_getVideoExercisesByIdMatches, { loading, error, data }] = useLazyQuery(
    videoBankExercisesMatches, { fetchPolicy: 'no-cache', errorPolicy: 'all' },
  );
  return {
    _getVideoExercisesByIdMatches,
    findExercisesLoading: loading,
    error,
    totalExercises: data?.getCurrentPatientVideobank.totalItems,
  };
};

// get list active video exercises for patient
export const useQueryActiveVideoExercises = () => {
  const [_getActiveVideoExercises, { loading, error, data }] = useLazyQuery(
    activeVideoExercListForPatient, { fetchPolicy: 'no-cache' },
  );
  return {
    _getActiveVideoExercises,
    loadingActiveExercises: loading,
    errorActiveExercises: error,
    activeVideoExersises: data && data.getPatientVideobankForActiveExercises,
  };
};

// get list video exercises for invitee patient
export const useQueryVideoExercisesInviteePatient = (isPollInterval = true) => {
  const [_getInviteePatientVideoExercisesById, { loading, error, data }] = useLazyQuery(
    videoExercListForInviteePatient, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      pollInterval: isPollInterval ? 15000 : 0,
    },
  );

  return {
    _getInviteePatientVideoExercisesById,
    loadingInvitee: loading,
    errorInvitee: error,
    videoExersisesInvitee: data && data.getCurrentInviteePatientVideobank,
    videoExersisesInviteeData: data,
  };
};
// get list active video exercises for invitee patient for export to PDF
export const useQueryInviteePatientVideoBankForActiveExercises = () => {
  const [_getInviteePatientVideoBankForActiveExercises, { loading, error, data }] = useLazyQuery(
    activeVideoExercListForInviteePatient, {
      fetchPolicy: 'no-cache',
    },
  );

  return {
    _getInviteePatientVideoBankForActiveExercises,
    loadingActiveExercForInvitee: loading,
    errorActiveExercisesForInvitee: error,
    activeVideoExersisesInvitee: data && data.getInviteePatientVideobankForActiveExercises,
  };
};

// Number of exercises in the database matching the search
export const useFindMatchesVideoExercisesInvitee = () => {
  const [_getInviteePatientVideoExercisesByIdMatches, { loading, error, data }] = useLazyQuery(
    videoBankExercisesMatchesInvitee, { fetchPolicy: 'no-cache', errorPolicy: 'all' },
  );
  return {
    _getInviteePatientVideoExercisesByIdMatches,
    findExercisesInviteeLoading: loading,
    error,
    totalExercisesInvitee: data?.getCurrentInviteePatientVideobank.totalItems,
  };
};

// send PDF plan to invitee patient by email
export const useEmailExercisePlanToInviteePatient = () => {
  const [_sendPlanByEmailToInviteePatient, { data, error, loading }] = useMutation(
    emailExercisePlanToInviteePatient,
  );
  return {
    _sendPlanByEmailToInviteePatient,
    emailSentToInviteePatient: data && data.emailExercisePlanToInviteePatient,
    sendPlanToInviteePatientError: error,
    sendPlanToInviteePatientLoading: loading,
  };
};

// send PDF plan to patient by email
export const useEmailExercisePlanToPatient = () => {
  const [_sendPlanByEmailToPatient, { data, error, loading }] = useMutation(
    emailExercisePlanToPatient,
  );
  return {
    _sendPlanByEmailToPatient,
    emailSentToPatient: data && data.emailExercisePlanToPatient,
    sendPlanToPatientError: error,
    sendPlanToPatientLoading: loading,
  };
};

// PDF PLANS
// get list prescriptions to patient for Exercise prescription history report page from DB
export const useGetLink = (): any => {
  const [_getLink, { loading, error, data }] = useLazyQuery(getUrl);
  return {
    _getLink,
    data: data?.getExercisePlanPicLink,
    loading,
    error,
  };
};

// get plans for patient for Exercise prescription history report page from DB
export const useGetPatientExercisePlans = (): GetPatientExercisePlansProps => {
  const [_getPatientExercisePlans, { loading, error, data }] = useLazyQuery(
    getPatientExercisePlans, {
      errorPolicy: 'all',
    },
  );

  let dataList = [];
  let total = null;
  let totalPages = null;
  let totalItems = null;

  if (data) {
    const response = data.getPatientExercisePlans;
    dataList = response.list;
    total = response.listLength;
    totalPages = response.totalPages;
    totalItems = response.totalItems;
  }

  return {
    _getPatientExercisePlans,
    loading,
    error,
    patientPlans: dataList,
    total,
    totalPages,
    totalItems,
  };
};

// get plans for invitee patient for Exercise prescription history report page from DB
export const useGetInviteePatientExercisePlans = (): GetInviteePatientExercisePlansProps => {
  const [_getInviteePatientExercisePlans, { loading, error, data }] = useLazyQuery(
    getInviteePatientExercisePlans, {
      errorPolicy: 'all',
    },
  );

  let dataList = [];
  let total = null;
  let totalPages = null;
  let totalItems = null;

  if (data) {
    const response = data.getInviteePatientExercisePlans;
    dataList = response.list;
    total = response.listLength;
    totalPages = response.totalPages;
    totalItems = response.totalItems;
  }

  return {
    _getInviteePatientExercisePlans,
    loadingInvitee: loading,
    errorInvitee: error,
    patientInviteePlans: dataList,
    totalInvitee: total,
    totalPagesInvitee: totalPages,
    totalItemsInvitee: totalItems,
  };
};

// Create/update video exercises by HCP individual for patient
export const useCreateExerciseForPatient = () => {
  const [
    _saveExerciseForPatient,
    { data, errorText, loading },
  ] = useMutationRequest<{ createExerciseForPatient?: boolean }>(gqlCreateExerciseForPatient, {});

  const _saveHcpExerciseForPatient = (request: any): void => {
    const exercData = request.exerciseData;
    _saveExerciseForPatient({
      variables: {
        exerciseCategoriesId: request.exerciseCategoriesId,
        exerciseProcedureTypesId: request.exerciseProcedureTypesId,
        patientId: request.patientId,
        exerciseData: {
          name: exercData.name,
          thumbnailName: exercData.thumbnailName,
          videoName: exercData.videoName,
          videobankId: exercData.videobankId,
          sets: exercData.sets,
          reps: exercData.reps,
          time: exercData.time,
          timesPerDay: exercData.timesPerDay,
          restDays: exercData.restDays,
          comment: exercData.comment,
        },
      },
    });
  };
  return {
    _saveHcpExerciseForPatient,
    errorSaveHcpExerciseForPatient: errorText,
    loadingSaveHcpExerciseForPatient: loading,
    savedHcpExerciseForPatient: data && data.createExerciseForPatient,
  };
};
