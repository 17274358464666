// Type
const FILL_PATIENT_MOTIVATIONS = 'fill/patient_motivations';
const LOADING_PATIENT_MOTIVATIONS = 'loading/patient_motivations';
const FILL_MOTIVATIONS_OVERVIEW_TAB = 'FILL_MOTIVATIONS_OVERVIEW_TAB';
const LOADING_MOTIVATIONS_OVERVIEW_TAB = 'LOADING_MOTIVATIONS_OVERVIEW_TAB';

// TS
interface MotivationType {
  type: typeof FILL_PATIENT_MOTIVATIONS | typeof FILL_MOTIVATIONS_OVERVIEW_TAB;
  payload: MotivationDataType;
}

interface LoadingType {
  type: typeof LOADING_PATIENT_MOTIVATIONS | typeof LOADING_MOTIVATIONS_OVERVIEW_TAB;
  payload: boolean;
}

interface DataType {
  data: object;
}

interface MotivationDataType {
  data: DataType[];
}
export interface MotivationState {
  loading: boolean;
  error: any;
  motivation: any;
  motivationOverviewTab: any;
  loadingOverviewTab: boolean;
}
type ActionTypes = MotivationType | LoadingType;

type PayloadType = {
  skipped: boolean;
  createdAt: string;
  type: number | string;
};

// Action
export const setMotivation = (data: MotivationDataType): MotivationType => ({
  type: FILL_PATIENT_MOTIVATIONS,
  payload: data,
});
export const loadingMotivation = (data: boolean): LoadingType => ({
  type: LOADING_PATIENT_MOTIVATIONS,
  payload: data,
});
export const setMotivationOverviewTab = (data: MotivationDataType): MotivationType => ({
  type: FILL_MOTIVATIONS_OVERVIEW_TAB,
  payload: data,
});
export const loadingMotivationOverviewTab = (data: boolean): LoadingType => ({
  type: LOADING_MOTIVATIONS_OVERVIEW_TAB,
  payload: data,
});

// const transformerPayload = (data: any): MotivationDataType[] => {
//   const item = data ? data.map((pain: PayloadType) => pain) : [];
//   return [...item];
// };

const init: MotivationState = {
  loading: false,
  error: null,
  motivation: [],
  motivationOverviewTab: [],
  loadingOverviewTab: false,
};

// Reducer
export const motivationReduser = (state = init, { type, payload }: ActionTypes) => {
  switch (type) {
    case FILL_PATIENT_MOTIVATIONS: {
      // const newPayload = transformerPayload(payload);
      return {
        ...state,
        loading: false,
        motivation: payload,
      };
    }
    case LOADING_PATIENT_MOTIVATIONS:
      return {
        ...state,
        loading: payload,
      };
    case FILL_MOTIVATIONS_OVERVIEW_TAB: {
      // const newPayload = transformerPayload(payload);
      return {
        ...state,
        motivationOverviewTab: payload,
      };
    }
    case LOADING_MOTIVATIONS_OVERVIEW_TAB:
      return {
        ...state,
        loadingOverviewTab: payload,
      };
    default:
      return state;
  }
};
