import { loader } from 'graphql.macro';
import { useMutationRequest } from '../../hooks/feature/useMutationRequest';
import { ResendInviteForm, UserInviteForm } from '../../components/invite/types';
import {
  UseInviteHcp,
  UseResendCodeByEmailToPatient,
  UseResendInviteTokenByHcp,
  UseResendInviteTokenByPatient,
  UseSignUpPatientByHcpType,
} from '../types';

// Mutations
const mutationByHcp = loader('./gql/mutationResendInviteTokenByHcp.graphql');
const mutationByPatient = loader('./gql/mutationResendInviteTokenByPatient.graphql');
const mutationInviteHcp = loader('./gql/mutationInviteHcp.graphql');
const mutationConnectPatientByHcp = loader('./gql/mutationConnectPatientByHcp.graphql');
const mutationSignUpPatientByHcp = loader('./gql/mutationSignUpPatientByHcp.graphql');
const mutationResendPatientCodeByHcp = loader('./gql/mutationResendPatientCodeByHcp.graphql');
const acceptPendingPatientInvitations = loader('./gql/mutationAnswerOnPatientInvitations.graphql');
const changeEmailReceiving = loader('./gql/mutationChangeEmailReceiving.graphql');

// Invites
// Connect Patient By Hcp
export const useConnectPatientByHcp = (): any => {
  const [_connectPatient, { data, errorText, loading }] = useMutationRequest<{
    connectPatientByHcp: any;
  }>(mutationConnectPatientByHcp, { fetchPolicy: 'no-cache' });
  const _connectPatientByHcp = (email: string): void => {
    _connectPatient({
      variables: {
        patientEmail: { email },
      },
    });
  };

  return {
    _connectPatientByHcp,
    isConnect: data && data.connectPatientByHcp,
    connectError: errorText,
    connecting: loading,
  };
};

// Clinician-led sign up Patient
export const useSignUpPatientByHcp = (): UseSignUpPatientByHcpType => {
  const [_signUpPatient, { data, errorText, loading }] = useMutationRequest<{
    signUpPatientByHcp: any;
  }>(mutationSignUpPatientByHcp, { fetchPolicy: 'no-cache' });

  const _signUpPatientByHcp = (signUpPatientData: any): void => {
    _signUpPatient({
      variables: {
        signUpPatientData,
      },
    });
  };

  return {
    _signUpPatientByHcp,
    isSignUpPatientSent: data && data.signUpPatientByHcp,
    signUpError: errorText,
    signUpLoading: loading,
  };
};

// Invite HCP
export const useInviteHcp = (): UseInviteHcp => {
  const [_inviteHcp, { data, errorText, loading }] = useMutationRequest<{ inviteHcp: boolean }>(
    mutationInviteHcp,
    {},
  );
  const _onInvite = (form: UserInviteForm): void => {
    _inviteHcp({
      variables: form,
    });
  };
  return {
    _onInvite,
    hcp: data && data.inviteHcp,
    errorHcp: errorText,
    loading,
  };
};

// Resend Invites
// Resend invite token by HCP
export const useResendInviteTokenByHcp = (): UseResendInviteTokenByHcp => {
  const [_inviteHcp, { data, errorText, loading }] = useMutationRequest<{
    resendInviteTokenByHcp: boolean;
  }>(mutationByHcp, {});
  const _onInvite = (form: ResendInviteForm): void => {
    _inviteHcp({
      variables: {
        hcpData: form,
      },
    });
  };
  const response = data && data.resendInviteTokenByHcp;
  return {
    _onInvite,
    hcp: response,
    response,
    error: errorText,
    loading,
  };
};

// Rsend invite token by Patient
export const useResendInviteTokenByPatient = (): UseResendInviteTokenByPatient => {
  const [_inviteHcp, { data, errorText, loading }] = useMutationRequest<{
    resendInviteTokenByPatient: boolean;
  }>(mutationByPatient, {});

  const _onInvite = (form: any): void => {
    _inviteHcp({
      variables: {
        patientData: form,
      },
    });
  };
  const response = data && data.resendInviteTokenByPatient ? data.resendInviteTokenByPatient : null;
  return {
    _onInvitePatient: _onInvite,
    patient: response,
    errorPatient: errorText,
    loadingPatient: loading,
  };
};

// Rsend signin/sinup code by Hcp to patient
export const useResendCodeByEmailToPatient = (): UseResendCodeByEmailToPatient => {
  const [_resendCode, { data, errorText, loading }] = useMutationRequest<{
    resendPatientCodeByHcp: boolean;
  }>(mutationResendPatientCodeByHcp, {});

  const response = data && data.resendPatientCodeByHcp;
  return {
    _resendCode,
    codeResent: response,
    errorCodeResend: errorText,
    loadingResend: loading,
  };
};

// accept/decline pending patients
export const useAcceptPendingPatientInvitations = (): any => {
  const [
    _onAcceptPendingPatientInvitations,
    { data, errorText, loading },
  ] = useMutationRequest<{answerOnPatientInvitations: boolean}>(
    acceptPendingPatientInvitations, { fetchPolicy: 'no-cache' },
  );
  const _acceptPendingPatientInvitations = (solution: string, patientIds: number): void => {
    _onAcceptPendingPatientInvitations({
      variables: {
        solution,
        patientIds,
      },
    });
  };

  return {
    _acceptPendingPatientInvitations,
    solutionLoading: loading,
    solutionSaved: data && data.answerOnPatientInvitations,
  };
};

// Change Accept Patient Invitation (Manage notifications)
export const useChangeEmailReceiving = (): any => {
  const [
    _onChangeEmailReceiving,
    { data, errorText, loading },
  ] = useMutationRequest<{changeEmailReceiving: boolean}>(
    changeEmailReceiving, { fetchPolicy: 'no-cache' },
  );
  const _changeEmailReceiving = (checked: boolean): void => {
    _onChangeEmailReceiving({
      variables: {
        solution: checked,
      },
    });
  };

  return {
    _changeEmailReceiving,
    changedEmailReceiving: data && data.changeEmailReceiving,
    emailReceivingError: errorText,
    emailReceivingLoading: loading,
  };
};

// Delete not used (Depricated)
// ********************************************
// const mutationInvitePatient = loader('./gql/notUsed/mutationInvitePatient.graphql');
// const mutationpatientInviteData = loader('./gql/notUsed/mutationInvitePatientUpdate.graphql');
// const mutationPatientInviteByHcp = loader('./gql/mutationInvitePatientByHcp.graphql');

// New sign up process
// export const useInvitePatientByHcp = (): any => {
//   const [_onInvitePatient, { data, errorText, loading }] = useMutationRequest<{
//     invitePatientByHcp: boolean;
//   }>(mutationPatientInviteByHcp, {});

//   const _invitePatient = (email: string): void => {
//     _onInvitePatient({
//       variables: {
//         patientEmail: { email },
//       },
//     });
//   };

//   return {
//     _invitePatient,
//     dataInvited: data && data.invitePatientByHcp,
//     errorInvitePatient: errorText,
//     loadingInvite: loading,
//   };
// };

// export const useInvitePatient = (): UseInvitePatient => {
//   const [_inviteHcp, { data, errorText, loading }] = useMutationRequest<{
//     invitePatientStepOne: { id: number };
//   }>(mutationInvitePatient, {});
//   const _onInvite = (form: any): void => {
//     _inviteHcp({
//       variables: {
//         patientInviteData: form,
//       },
//     });
//   };
//   const response = data && data.invitePatientStepOne && data.invitePatientStepOne.id;

//   return {
//     _onInvite,
//     patient: response,
//     errorPatient: errorText,
//     loading,
//   };
// };

// export const useInvitePatientStepTwo = (): UseInvitePatientStepTwo => {
//   const [_inviteHcp, { data, errorText, loading }] = useMutationRequest<{
//     invitePatientStepTwo: boolean;
//   }>(mutationpatientInviteData, {});

//   const _onUpdate = (form: FormProperty, hcpIds: HcpIds): void => {
//     _inviteHcp({
//       variables: {
//         patientInviteData: form,
//         hcpIds,
//       },
//     });
//   };

//   return {
//     _onUpdate,
//     dataUpdated: data && data.invitePatientStepTwo,
//     errorUpdatePatient: errorText,
//     loadingUpdate: loading,
//   };
// };
