// Core
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCurrenLang } from '../../redux/selector';
import { NotificationModal } from '../../common';
import { path } from '../../utils';
import styles from '../styles.module.css';
import storage from '../../utils/storage';

interface TNotFound {
  title?: string;
  content?: string;
  withoutHomePage?: boolean;
}

const NotFound = ({ title, content, withoutHomePage }: TNotFound): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const not_found_page_title = t && t.notifications.not_found_page_title;
  const not_found_page_sub_title = t && t.notifications.not_found_page_sub_title;
  const home_page = t && t.notifications.home_page;

  const user = storage.get('user');
  const isAdmin = user && user.role === 'admin';
  const redirectPath = isAdmin ? path.admin : path.home;
  return (
    <div className={styles.container}>
      <div className={styles.container_width}>
        <NotificationModal
          title={title || not_found_page_title}
          contant={content || not_found_page_sub_title}
          redirect={redirectPath}
          redirectTitle={!withoutHomePage ? home_page : ''}
        />
      </div>
    </div>
  );
};
export default NotFound;
