import {
  defaultValue,
  getChecked,
  getMinutes,
  getSeconds,
  getStatusOptions,
} from '../../../utils/helper';

export const initialValues = (
  data: any,
  exercisesCategories: any,
  exercisesProcedureFocus: any,
  t: any,
  focusType1Arr: any,
  focusType2Arr: any,
): any => {
  const procedures: any = {};
  const categores: any = {};
  focusType1Arr.map((procedure: any, i: number) => {
    procedures[`procedure${i + 1}`] = Boolean(data.exerciseProcedureTypes?.length
      && getChecked(exercisesProcedureFocus[`procedure${i + 1}`].name, data.exerciseProcedureTypes));
    return null;
  });
  focusType2Arr.map((focus: any, i: number) => {
    categores[`focus${i + 1}`] = Boolean(data.exerciseCategories?.length
      && getChecked(exercisesCategories[`focus${i + 1}`].name, data.exerciseCategories));
    return null;
  });

  return ({
    exerciseId: data.exerciseId ? data.exerciseId : null,
    exerciseName: data.exerciseName ? data.exerciseName : data.name,
    videoName: data.videoName,
    dim: data.exerciseTime ? 'time' : 'reps',
    // reps: data.exerciseReps ? defaultValue(String(data.exerciseReps)) : defaultValue('1'),
    reps: data.exerciseReps || '',
    // sets: data.exerciseSets ? defaultValue(String(data.exerciseSets)) : defaultValue('1'),
    sets: data.exerciseSets || '',
    // timesDay: data.exerciseTimesPerDay
    //   ? defaultValue(String(data.exerciseTimesPerDay))
    //   : defaultValue('1'),
    timesDay: data.exerciseTimesPerDay || '',
    restDaysWeek: data.exerciseRestDays || '',
    // restDaysWeek: data.exerciseRestDays
    //   ? defaultValue(String(data.exerciseRestDays))
    //   : defaultValue('0'),
    minutes: data.exerciseTime
      ? defaultValue(String(getMinutes(data.exerciseTime)))
      : defaultValue('0'),
    seconds: data.exerciseTime
      ? defaultValue(String(getSeconds(data.exerciseTime)))
      : defaultValue('0'),
    comments: data.exerciseComment || '',
    // focus1: Boolean(
    //   data.exerciseCategories?.length
    //   && getChecked(exercisesCategories?.focus1?.name, data.exerciseCategories),
    // ),
    // focus2: Boolean(
    //   data.exerciseCategories?.length
    //   && getChecked(exercisesCategories?.focus2?.name, data.exerciseCategories),
    // ),
    // focus3: Boolean(
    //   data.exerciseCategories?.length
    //   && getChecked(exercisesCategories?.focus3?.name, data.exerciseCategories),
    // ),
    // focus4: Boolean(
    //   data.exerciseCategories?.length
    //   && getChecked(exercisesCategories?.focus4?.name, data.exerciseCategories),
    // ),
    status: getStatusOptions(data.status, 'videobank', t) || getStatusOptions('Inactive', 'videobank', t),
    name: data.name,
    thumbnailName: data.thumbnailName,
    videobankId: data.currentId ? data.currentId : null,
    exerciseVideoUploaded: data.exerciseId ? data.exerciseVideoUploaded : true,
    exerciseThumbnailUploaded: data.exerciseId ? data.exerciseThumbnailUploaded : true,
    isDraft: true,
    ...categores,
    ...procedures,
    // procedure1: Boolean(
    //   data.exerciseProcedureTypes?.length
    //   && getChecked(exercisesProcedureFocus?.procedure1?.name, data.exerciseProcedureTypes),
    // ),
    // procedure2: Boolean(
    //   data.exerciseProcedureTypes?.length
    //   && getChecked(exercisesProcedureFocus?.procedure2?.name, data.exerciseProcedureTypes),
    // ),
    // procedure3: Boolean(
    //   data.exerciseProcedureTypes?.length
    //   && getChecked(exercisesProcedureFocus?.procedure3?.name, data.exerciseProcedureTypes),
    // ),
    // procedure4: Boolean(
    //   data.exerciseProcedureTypes?.length
    //   && getChecked(exercisesProcedureFocus?.procedure4?.name, data.exerciseProcedureTypes),
    // ),
    // procedure5: Boolean(
    //   data.exerciseProcedureTypes?.length
    //   && getChecked(exercisesProcedureFocus?.procedure5?.name, data.exerciseProcedureTypes),
    // ),
    // procedure6: Boolean(
    //   data.exerciseProcedureTypes?.length
    //   && getChecked(exercisesProcedureFocus?.procedure6?.name, data.exerciseProcedureTypes),
    // ),
    // procedure7: Boolean(
    //   data.exerciseProcedureTypes?.length
    //   && getChecked(exercisesProcedureFocus?.procedure7?.name, data.exerciseProcedureTypes),
    // ),
  });
};

export const notAllowedStatuses = ['Inactive', 'Not uploaded', 'Deleted by patient', 'Deleted by hcp'];
export const numberOfThumbnails = 3;
