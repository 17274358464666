import React, { ReactElement } from 'react';
import { CustomLinkProps } from './types';
import styles from './styles.module.css';

export const CustomLink = ({
  buttonName,
  buttonMethod,
  disabledButton,
  buttonType,
}: CustomLinkProps): ReactElement => (
  <button
    className={styles.btn_link}
    type={buttonType}
    disabled={disabledButton && disabledButton}
    onClick={buttonMethod && buttonMethod}
  >
    {buttonName}
  </button>
);
