/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import cx from 'classnames';

import './style.css';
import styles from './styles.module.css';
import { ArrowBackIosIcon, ArrowForwardIosIcon } from '../../theme/icons';
import { getCurrenLang } from '../../redux/selector';
import { getFormat, listOfMonths } from '../../utils/helper';

export interface TDatepickerProps extends ReactDatePickerProps {
  isNextYear?: boolean;
  labelName?: string;
  autocomplete?: string;
  hasErrors?: any;
  onChange: any;
  carrentValue?: any;
  currentLang: string;
  isTouched?: any;
  isRedStar?: boolean;
  startYear?: any;
  endYear?: any;
}

export const DatePicker = (props: TDatepickerProps): ReactElement => {
  const {
    isNextYear = false,
    labelName,
    autocomplete,
    name,
    hasErrors,
    isTouched,
    onChange,
    carrentValue,
    currentLang,
    isRedStar = false,
    ...rest
  } = props;

  const range = (start: number, stop: number, step = 1) => Array(stop - start)
    .fill(start)
    .map((x, y) => x + y * step);
  const years = isNextYear
    ? range(new Date().getFullYear() - 1, new Date().getFullYear() + 2, 1)
    : range(new Date().getFullYear() - 110, new Date().getFullYear() + 1, 1);

  const handleChange = (currentDate: Date): void => {
    let newDate = null;
    if (currentDate) {
      const now = new Date();
      newDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
      );
    }
    onChange(name, newDate);
  };
  const monthList = listOfMonths(currentLang);

  return (
    <>
      <div
        className={cx({
          lable_field: true,
          errorPicker: isTouched && hasErrors,
        })}
      >
        <div className="subtitle">
          {labelName}
          {isRedStar && (
            <span className={styles.red_star}>*</span>
          )}
        </div>
        <ReactDatePicker
          id={name}
          name={name}
          autoComplete={autocomplete}
          dateFormat={getFormat(currentLang)}
          onChange={handleChange}
          adjustDateOnChange
          // calendarStartDay={1}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }): ReactElement => (
            <>
              <div
                style={{
                  margin: '0 5px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  className="datepicker_btn"
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <ArrowBackIosIcon />
                </button>
                <div className="datepicker_select_wrapper">
                  <select
                    className="datepicker_select datepicker_select_months"
                    value={monthList[new Date(date).getMonth()]}
                    onChange={
                      ({ target: { value } }): void => changeMonth(
                        monthList.indexOf(value),
                      )
                    }
                  >
                    {monthList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    className="datepicker_select"
                    value={new Date(date).getFullYear()}
                    onChange={({ target: { value } }): void => changeYear(Number(value))}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  className="datepicker_btn"
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </>
          )}
          {...rest}
        />
        {isTouched && hasErrors ? <div className="error">{hasErrors}</div> : null}
      </div>
    </>
  );
};

export const DatePickerRange = (props: TDatepickerProps): ReactElement => {
  const {
    name,
    onChange,
    startDate,
    endDate,
    currentLang,
    startYear,
    endYear,
    ...rest
  } = props;

  const range = (start: number, stop: number, step = 1) => Array(stop - start)
    .fill(start)
    .map((x, y) => x + y * step);
  const years = range(startYear, endYear, 1);

  return (
    <>
      <ReactDatePicker
        id={name}
        name={name}
        onChange={onChange}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        // @ts-ignore
        selectsRange
        dateFormat={getFormat(currentLang)}
        // calendarStartDay={1}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }): ReactElement => (
          <>
            <div
              style={{
                margin: '0 5px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                className="datepicker_btn"
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ArrowBackIosIcon />
              </button>
              <div className="datepicker_select_wrapper">
                <select
                  className="datepicker_select datepicker_select_months"
                  value={listOfMonths(currentLang)[new Date(date).getMonth()]}
                  onChange={
                    ({ target: { value } }): void => changeMonth(
                      listOfMonths(currentLang).indexOf(value),
                    )
                  }
                >
                  {listOfMonths(currentLang).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  className="datepicker_select"
                  value={new Date(date).getFullYear()}
                  onChange={({ target: { value } }): void => changeYear(Number(value))}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="datepicker_btn"
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          </>
        )}
        {...rest}
      />
    </>
  );
};

export const DatePickerMenuElem = ({
  name,
  handleSearch,
  handleReset,
  queryPicker,
  setQueryPicker,
  currentLang,
}: any): ReactElement => {
  const t: any = useSelector<any>((state: any) => getCurrenLang(state));
  const search = t && t.common.search;
  const reset = t && t.common.reset;

  return (
    <div className={styles.dashbord}>
      <DatePicker
        autocomplete="off"
        name={name}
        labelName=""
        selected={queryPicker}
        onChange={(text: any, date: Date): void => setQueryPicker(date)}
        maxDate={new Date()}
        inline
        currentLang={currentLang}
      />
      <div className={styles['btn-group-container']}>
        <Button
          type="primary"
          onClick={(): void => handleSearch(queryPicker)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, padding: '0 5px', textAlign: 'center' }}
        >
          {search}
        </Button>
        <Button
          onClick={(): void => {
            setQueryPicker(new Date());
            handleReset();
          }}
          size="small"
          style={{ width: 90, padding: '0 5px', textAlign: 'center' }}
        >
          {reset}
        </Button>
      </div>
    </div>
  );
};

export const DatePickerNew = (props: TDatepickerProps): ReactElement => {
  const {
    isNextYear = false,
    labelName,
    autocomplete,
    name,
    hasErrors,
    onChange,
    carrentValue,
    currentLang,
    ...rest
  } = props;

  const range = (start: number, stop: number, step = 1) => Array(stop - start)
    .fill(start)
    .map((x, y) => x + y * step);
  const years = isNextYear
    ? range(new Date().getFullYear() - 1, new Date().getFullYear() + 2, 1)
    : range(new Date().getFullYear() - 120, new Date().getFullYear() + 1, 1);

  const handleChange = (currentDate: Date): void => {
    let newDate = null;
    if (currentDate) {
      const now = new Date();
      newDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        now.getHours(),
        now.getMinutes(),
        now.getSeconds(),
      );
    }
    onChange(name, newDate);
  };
  const monthList = listOfMonths(currentLang);

  return (
    <>
      <div className="label">
        <div className="titleField">{labelName}</div>
        <ReactDatePicker
          id={name}
          name={name}
          autoComplete={autocomplete}
          dateFormat={getFormat(currentLang)}
          onChange={handleChange}
          adjustDateOnChange
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }): ReactElement => (
            <>
              <div
                style={{
                  margin: '0 5px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  className="datepicker_btn"
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <ArrowBackIosIcon />
                </button>
                <div className="datepicker_select_wrapper">
                  <select
                    className="datepicker_select datepicker_select_months"
                    value={monthList[new Date(date).getMonth()]}
                    onChange={
                      ({ target: { value } }): void => changeMonth(
                        monthList.indexOf(value),
                      )
                    }
                  >
                    {monthList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    className="datepicker_select"
                    value={new Date(date).getFullYear()}
                    onChange={({ target: { value } }): void => changeYear(Number(value))}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  className="datepicker_btn"
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </>
          )}
          {...rest}
        />
        {carrentValue && hasErrors ? <div className="error">{hasErrors}</div> : null}
      </div>
    </>
  );
};
