// Core
import React, { FC } from 'react';
// Instruments
import styles from './styles.module.css';
import logo from '../../theme/image/logo.png';

const LoginHeaderApp: FC = () => (
  <div className={styles.header}>
    <img src={logo} alt="header logo" />
  </div>
);

export default LoginHeaderApp;
