import { proceduresEnum } from '../../utils/enums';

// Action
const reasonTitles = [
  'tooPainful',
  'lackOfEquipment',
  'noTime',
  'didntUnderstand',
  'fatigue',
  'willDoLater',
  'alreadyDone',
  'noReason',
  'restDay',
];
const getReasonLabels = (t: any): {[key: string]: string} => ({
  tooPainful: t?.dashboard.hcp.profile_patient.reasons.too_painful,
  lackOfEquipment: t?.dashboard.hcp.profile_patient.reasons.lack_of_equipment,
  noTime: t?.dashboard.hcp.profile_patient.reasons.no_time,
  didntUnderstand: t?.dashboard.hcp.profile_patient.reasons.didnt_understand,
  fatigue: t?.dashboard.hcp.profile_patient.reasons.fatigue,
  willDoLater: t?.dashboard.hcp.profile_patient.reasons.will_do_later,
  alreadyDone: t?.dashboard.hcp.profile_patient.reasons.already_done,
  noReason: t?.dashboard.hcp.profile_patient.reasons.i_prefer_not_to_say,
  restDay: t?.dashboard.hcp.profile_patient.reasons.rest_day,
});
const getMotivationLabels = (t: any, procedureKey: string): {[key: string]: string} => {
  switch (procedureKey) {
    case proceduresEnum.SPINAL: {
      return {
        avPainScoreBackCompleted: t?.dashboard.hcp.profile_patient.completion_and_steps
          .av_back_pain_score_completed,
        avPainScoreBackSkipStop: t?.dashboard.hcp.profile_patient.completion_and_steps
          .av_back_pain_score_skip_stop,
        avPainScoreLegCompleted: t?.dashboard.hcp.profile_patient.completion_and_steps
          .av_leg_pain_score_completed,
        avPainScoreLegSkipStop: t?.dashboard.hcp.profile_patient.completion_and_steps
          .av_leg_pain_score_skip_stop,
      };
    }
    default:
      return {
        averagePainScoreCompleted: t?.dashboard.hcp.profile_patient
          .completion_and_steps.average_pain,
        averagePainScoreSkipStop: t?.dashboard.hcp.profile_patient.completion_and_steps
          .skip_stop_value,
      };
  }
};

const defaultReasons = {
  TO_PAINFUL: 0,
  LACK_OF_EQUIPMENT: 0,
  NO_TIME: 0,
  DIDNT_UNDERSTAND: 0,
  FATIGUE: 0,
  WILL_DO_LATER: 0,
  ALREADY_DONE: 0,
  NO_REASON: 0,
  REST_DAY: 0,
};

const defaultStatuses = {
  completed: 0,
  partlyDone: 0,
  attempts: 0,
  noAttempt: 0,
};

const defaultPainLevel = {
  average: 0,
  skipped: 0,
};

const exercisePainLevel = ['average', 'skipped'];
const statusDefault = ['completed', 'partlyDone', 'attempts', 'noAttempt'];

const perionStatus = {
  day: '',
  weeks: '',
  month: '',
};

export default {
  reasonTitles,
  defaultReasons,
  defaultStatuses,
  defaultPainLevel,
  exercisePainLevel,
  statusDefault,
  perionStatus,
  getReasonLabels,
  getMotivationLabels,
};
