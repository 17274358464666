import { loader } from 'graphql.macro';
import { Dispatch } from 'redux';
import { client } from '../../utils/init/client';
import {
  NOT_READING_NOTIFICATION,
  ITEMS_PER_PAGE,
  ITEMS_PER_PATIENT_PAGE,
  NOT_READING_PATIENT_NOTIFICATION,
} from '../../utils/variables';
import { config } from '../../utils/configs';
import { NOTIF_STATUS } from '../../utils/enums';
import { TNotification } from '../../components/HeaderApp/types';

const SET_VISIBLE_NOTIFICATION_LIST = 'SET_VISIBLE_NOTIFICATION_LIST';
const SET_NOTIFICATIONS_STATUS = 'SET_NOTIFICATIONS_STATUS';
const SET_CHANGED_STATUS = 'SET_CHANGED_STATUS';
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
const SET_LOADING_DATA = 'SET_LOADING_DATA';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

const SET_CHANGED_PATIENT_NOTIF_STATUS = 'SET_CHANGED_PATIENT_NOTIF_STATUS';
const SET_PATIENT_NOTIFICATIONS_STATUS = 'SET_PATIENT_NOTIFICATIONS_STATUS';
const SET_LOADING_PATIENT_DATA = 'SET_LOADING_PATIENT_DATA';
const DELETE_PATIENT_NOTIFICATION = 'DELETE_PATIENT_NOTIFICATION';

interface ActionTypes {
  type: string;
  payload: any;
}

interface InitStateType {
  visibleNotificationList: boolean;
  notificationsList: Array<TNotification>;
  notificationsStatus: string;
  countUnread: number;
  totalPages: number;
  itemsPerPageCumulative: number;
  error: string;
  patientNotifications: {
    notificationsList: Array<TNotification>;
    notificationsStatus: string;
    countUnread: number;
    totalPages: number;
    itemsPerPageCumulative: number;
  };
}
// Queris
const changeStateHcpNotification = loader(
  '../../components/HeaderApp/globalNotification/gql/mutationChangeStateHcpNotification.graphql',
);
const getCurrentHcpNotifications = loader(
  '../../components/HeaderApp/globalNotification/gql/getNotifications.graphql',
);
const changeStatePatientNotification = loader(
  '../../components/HeaderApp/globalNotification/gql/mutationChangeStatePatientNotification.graphql',
);
const getPatientNotifications = loader(
  '../../components/HeaderApp/globalNotification/gql/getPatientNotifications.graphql',
);

// Action creator
export const setvisibleNotificationList = (data: boolean): ActionTypes => ({
  type: SET_VISIBLE_NOTIFICATION_LIST,
  payload: data,
});

export const setNotificationsStatus = (data: string): ActionTypes => ({
  type: SET_NOTIFICATIONS_STATUS,
  payload: data,
});

export const setPatientNotificationsStatus = (data: string): ActionTypes => ({
  type: SET_PATIENT_NOTIFICATIONS_STATUS,
  payload: data,
});

export const setNotificationsList = (itemsPerPageCumulative: number) => async (
  dispatch: Dispatch,
  getState: () => any,
): Promise<void> => {
  const { notifications } = getState();
  try {
    const response = await client.query({
      query: getCurrentHcpNotifications,
      fetchPolicy: 'no-cache',
      variables: {
        readState: notifications.notificationsStatus,
        listProps: { page: 1, itemsPerPage: itemsPerPageCumulative },
      },
    });
    const newList = {
      notificationsList: response.data.getCurrentHcpNotifications.list,
      countUnread: response.data.getCurrentHcpNotifications.totalUnread,
      totalPages: response.data.getCurrentHcpNotifications.totalPages,
      itemsPerPageCumulative,
    };
    dispatch({
      type: SET_LOADING_DATA,
      payload: newList,
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: error,
    });
  }
};

export const setPatientNotificationsList = (
  itemsPerPageCumulative: number, patientId: number,
) => async (
  dispatch: Dispatch,
  getState: () => any,
): Promise<void> => {
  const { notifications } = getState();
  const patientNotificationsState = notifications.patientNotifications;
  try {
    const response = await client.query({
      query: getPatientNotifications,
      fetchPolicy: 'no-cache',
      variables: {
        patientId,
        readState: patientNotificationsState.notificationsStatus,
        listProps: { page: 1, itemsPerPage: itemsPerPageCumulative },
      },
    });
    const newList = {
      notificationsList: response.data.getCurrentHcpNotificationsForPatient.list,
      countUnread: response.data.getCurrentHcpNotificationsForPatient.totalUnread,
      totalPages: response.data.getCurrentHcpNotificationsForPatient.totalPages,
      itemsPerPageCumulative,
    };
    dispatch({
      type: SET_LOADING_PATIENT_DATA,
      payload: newList,
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: error,
    });
  }
};

export const changeNotificationStatus = (hcpNotificationId: number) => async (
  dispatch: Dispatch,
  getState: () => any,
): Promise<void> => {
  const { notifications } = getState();
  try {
    const response = await client.mutate({
      mutation: changeStateHcpNotification,
      variables: {
        hcpNotificationId,
      },
    });
    const changedStatusData = {
      hcpNotificationId,
      countUnread: response.data.changeReadStateHcpNotification,
    };
    if (notifications.notificationsStatus === NOTIF_STATUS.READ) {
      dispatch({
        type: SET_CHANGED_STATUS,
        payload: changedStatusData,
      });
    } else if (
      notifications.notificationsList.length < NOT_READING_NOTIFICATION
        && notifications.totalPages > 1
    ) {
      try {
        const newResponse = await client.query({
          query: getCurrentHcpNotifications,
          fetchPolicy: 'no-cache',
          variables: {
            readState: notifications.notificationsStatus,
            listProps: { page: 1, itemsPerPage: ITEMS_PER_PAGE },
          },
        });
        const newList = {
          notificationsList: newResponse.data.getCurrentHcpNotifications.list,
          countUnread: newResponse.data.getCurrentHcpNotifications.totalUnread,
          totalPages: newResponse.data.getCurrentHcpNotifications.totalPages,
          itemsPerPageCumulative: notifications.itemsPerPageCumulative,
        };
        dispatch({
          type: SET_LOADING_DATA,
          payload: newList,
        });
      } catch (error) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: error,
        });
      }
    } else {
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: changedStatusData,
      });
    }
  } catch (error) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: error,
    });
  }
};

export const changePatientNotificationStatus = (
  hcpNotificationId: number, patientId: number,
) => async (
  dispatch: Dispatch,
  getState: () => any,
): Promise<void> => {
  const { notifications } = getState();
  const patientNotificationsState = notifications.patientNotifications;
  try {
    const response = await client.mutate({
      mutation: changeStatePatientNotification,
      variables: {
        hcpNotificationId,
        patientId,
      },
    });
    const changedStatusData = {
      hcpNotificationId,
      countUnread: response.data.changeReadStateHcpNotificationForPatient,
    };
    if (patientNotificationsState.notificationsStatus === NOTIF_STATUS.READ) {
      dispatch({
        type: SET_CHANGED_PATIENT_NOTIF_STATUS,
        payload: changedStatusData,
      });
    } else if (
      patientNotificationsState.notificationsList.length < NOT_READING_PATIENT_NOTIFICATION
        && patientNotificationsState.totalPages > 1
    ) {
      try {
        const newResponse = await client.query({
          query: getPatientNotifications,
          fetchPolicy: 'no-cache',
          variables: {
            readState: patientNotificationsState.notificationsStatus,
            listProps: { page: 1, itemsPerPage: ITEMS_PER_PATIENT_PAGE },
          },
        });
        const newList = {
          notificationsList: newResponse.data.getCurrentHcpNotificationsForPatient.list,
          countUnread: newResponse.data.getCurrentHcpNotificationsForPatient.totalUnread,
          totalPages: newResponse.data.getCurrentHcpNotificationsForPatient.totalPages,
          itemsPerPageCumulative: patientNotificationsState.itemsPerPageCumulative,
        };
        dispatch({
          type: SET_LOADING_PATIENT_DATA,
          payload: newList,
        });
      } catch (error) {
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: error,
        });
      }
    } else {
      dispatch({
        type: DELETE_PATIENT_NOTIFICATION,
        payload: changedStatusData,
      });
    }
  } catch (error) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: error,
    });
  }
};

let timer: any;
export const getTimer = (): any => timer;
export const pulling = () => async (dispatch: Dispatch, getState: () => any): Promise<void> => {
  const { notifications } = getState();
  clearTimeout(timer);
  try {
    const response = await client.query({
      query: getCurrentHcpNotifications,
      fetchPolicy: 'no-cache',
      variables: {
        readState: notifications.notificationsStatus,
        listProps: { page: 1, itemsPerPage: notifications.itemsPerPageCumulative },
      },
    });
    const newList = {
      notificationsList: response.data.getCurrentHcpNotifications.list,
      countUnread: response.data.getCurrentHcpNotifications.totalUnread,
      totalPages: response.data.getCurrentHcpNotifications.totalPages,
      itemsPerPageCumulative: notifications.itemsPerPageCumulative,
    };
    dispatch({
      type: SET_LOADING_DATA,
      payload: newList,
    });
    timer = setTimeout(() => {
      pulling()(dispatch, getState);
    }, config.pollInterval);
  } catch (error) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: error,
    });
  }
};

// Polling patient data
let timerForPatient: any;
export const getTimerForPatient = (): any => timerForPatient;
export const pullingPatientData = (patientId: number) => async (
  dispatch: Dispatch, getState: () => any,
): Promise<void> => {
  const { notifications } = getState();
  const { patientNotifications } = notifications;

  clearTimeout(timerForPatient);

  try {
    const response = await client.query({
      query: getPatientNotifications,
      fetchPolicy: 'no-cache',
      variables: {
        readState: patientNotifications.notificationsStatus,
        listProps: { page: 1, itemsPerPage: patientNotifications.itemsPerPageCumulative },
        patientId,
      },
    });
    const res = response.data.getCurrentHcpNotificationsForPatient;
    const newList = {
      notificationsList: res.list,
      countUnread: res.totalUnread,
      totalPages: res.totalPages,
      itemsPerPageCumulative: patientNotifications.itemsPerPageCumulative,
    };
    dispatch({
      type: SET_LOADING_PATIENT_DATA,
      payload: newList,
    });
    timerForPatient = setTimeout(() => {
      pullingPatientData(patientId)(dispatch, getState);
    }, config.pollInterval);
  } catch (error) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: error,
    });
  }
};

// Reducer
const initialState: InitStateType = {
  visibleNotificationList: false,
  notificationsList: [],
  notificationsStatus: NOTIF_STATUS.UNREAD,
  countUnread: 0,
  totalPages: 1,
  itemsPerPageCumulative: ITEMS_PER_PAGE,
  error: '',
  patientNotifications: {
    notificationsList: [],
    notificationsStatus: NOTIF_STATUS.UNREAD,
    countUnread: 0,
    totalPages: 1,
    itemsPerPageCumulative: ITEMS_PER_PATIENT_PAGE,
  },
};

export const globalNotificationsReduser = (
  state = initialState,
  { type, payload }: ActionTypes,
): InitStateType => {
  switch (type) {
    case SET_VISIBLE_NOTIFICATION_LIST:
      return {
        ...state,
        visibleNotificationList: payload,
      };
    case SET_NOTIFICATIONS_STATUS:
      return {
        ...state,
        notificationsStatus: payload,
      };
    case SET_PATIENT_NOTIFICATIONS_STATUS:
      return {
        ...state,
        patientNotifications: {
          ...state.patientNotifications,
          notificationsStatus: payload,
        },
      };
    case SET_CHANGED_STATUS: {
      const newNotificationsList = state.notificationsList.map((item: TNotification): any => {
        const {
          hcpNotificationId,
          message,
          patientId,
          readState,
          timestamp,
          title,
          type,
        } = item;
        let readStateNew = readState;
        if (item.hcpNotificationId === payload.hcpNotificationId) {
          if (readState === NOTIF_STATUS.UNREAD) {
            readStateNew = NOTIF_STATUS.READ;
          } else {
            readStateNew = NOTIF_STATUS.UNREAD;
          }
        }
        return {
          hcpNotificationId,
          message,
          patientId,
          readState: readStateNew,
          timestamp,
          title,
          type,
        };
      });
      return {
        ...state,
        notificationsList: newNotificationsList,
        countUnread: payload.countUnread,
      };
    }
    case SET_CHANGED_PATIENT_NOTIF_STATUS: {
      const newNotificationsList = state.patientNotifications.notificationsList.map(
        (item: TNotification): any => {
          const {
            hcpNotificationId,
            message,
            patientId,
            readState,
            timestamp,
            title,
            type,
          } = item;
          let readStateNew = readState;
          if (item.hcpNotificationId === payload.hcpNotificationId) {
            if (readState === NOTIF_STATUS.UNREAD) {
              readStateNew = NOTIF_STATUS.READ;
            } else {
              readStateNew = NOTIF_STATUS.UNREAD;
            }
          }
          return {
            hcpNotificationId,
            message,
            patientId,
            readState: readStateNew,
            timestamp,
            title,
            type,
          };
        },
      );
      return {
        ...state,
        patientNotifications: {
          ...state.patientNotifications,
          notificationsList: newNotificationsList,
          countUnread: payload.countUnread,
        },
      };
    }
    case DELETE_NOTIFICATION: {
      const newList = state.notificationsList.filter((
        notification: TNotification,
      ) => notification.hcpNotificationId !== payload.hcpNotificationId);
      return {
        ...state,
        notificationsList: newList,
        countUnread: payload.countUnread,
      };
    }
    case DELETE_PATIENT_NOTIFICATION: {
      const newList = state.patientNotifications.notificationsList.filter((
        notification: TNotification,
      ) => notification.hcpNotificationId !== payload.hcpNotificationId);
      return {
        ...state,
        patientNotifications: {
          ...state.patientNotifications,
          notificationsList: newList,
          countUnread: payload.countUnread,
        },
      };
    }
    case SET_LOADING_DATA:
      return {
        ...state,
        notificationsList: payload.notificationsList,
        countUnread: payload.countUnread,
        totalPages: payload.totalPages,
        itemsPerPageCumulative: payload.itemsPerPageCumulative,
      };
    case SET_LOADING_PATIENT_DATA:
      return {
        ...state,
        patientNotifications: {
          ...state.patientNotifications,
          notificationsList: payload.notificationsList,
          countUnread: payload.countUnread,
          totalPages: payload.totalPages,
          itemsPerPageCumulative: payload.itemsPerPageCumulative,
        },
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};
