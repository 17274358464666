// Core
import React, { Suspense, useLayoutEffect } from 'react';
import {
  Switch, Route, useHistory, useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Instruments
import { routers } from './routers';
import { Loading } from '../../common/Loader';

import HeaderApp from '../../components/HeaderApp';
import storage from '../storage';
import { path, constants } from './book';
import NotFound from '../../pages/NotFound';
import { parseJwt } from '../parseJwt';
import { PrivatRouteProps } from './types';

const PrivateRoute = (
  { component: Component, permissions, ...rest }: PrivatRouteProps,
): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;

  const pathAdmin = pathname === path.admin;
  const pathHcp = pathname === path.home;

  const pathAdminSignin = pathname === path.adminSignIn || pathname === path.admin;
  const pathHcpSignin = pathname === path.signIn || pathname === path.home;

  const user = storage.get('user');
  const userAuthenticationed = user && user.role;

  const isAdmin = userAuthenticationed === constants.admin
    || pathname === path.adminSignIn;
  const isHcp = userAuthenticationed === constants.hcp
    || pathname === path.signIn;

  const isPathAdmin = pathname && !!pathname.match(/admin/);
  const isPublicPath = rest.public;

  useLayoutEffect(() => {
    if (userAuthenticationed) {
      const data = user && user.token;
      const validToken = parseJwt(data);

      if (!isPublicPath && validToken.exp * 1000 < Date.now()) {
        storage.save('user', '');
        if (isPathAdmin) {
          history.push(path.adminSignIn);
        } else {
          history.push(path.signIn);
        }
      }

      if (pathAdminSignin || pathHcpSignin) {
        history.push({
          pathname: pathAdminSignin ? path.adminDashboard : path.dashboard,
          search,
        });
      }
      if (permissions.includes(constants.admin) && isHcp && isPathAdmin) {
        storage.save('user', '');
        history.push(path.adminSignIn);
      }
      if (permissions.includes(constants.hcp) && isAdmin && !isPathAdmin) {
        storage.save('user', '');
        history.push(path.signIn);
      }
    }
    if (!userAuthenticationed) {
      if (pathAdmin || pathHcp) {
        history.push({
          pathname: pathAdmin ? path.adminSignIn : path.signIn,
        });
      }
      if (!isPublicPath) {
        if (isPathAdmin) {
          history.push(path.adminSignIn);
        } else {
          history.push(path.signIn);
        }
      }
    }
    document.title = rest && rest.label;
  }, [
    pathHcp,
    pathAdmin,
    userAuthenticationed,
    pathAdminSignin,
    pathHcpSignin,
  ]);

  return <Route {...rest} render={(props): JSX.Element => <Component {...props} />} />;
};

const AppRoute = (): JSX.Element => (
  <>
    <HeaderApp />
    <Suspense fallback={<Loading />}>
      <Switch>
        {routers.map((route, ind) => (
          <PrivateRoute {...route} key={`route${String(ind)}`} component={route.component} />
        ))}
        <Route
          path="*"
          render={(): JSX.Element => (
            <>
              <NotFound />
            </>
          )}
        />
      </Switch>
    </Suspense>
    <ToastContainer />
  </>
);

export default AppRoute;
