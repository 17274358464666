/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { SearchOutlined } from '@ant-design/icons';

import styles from '../styles.module.css';
import { getCurrenLang } from '../../../redux/selector';
import SearchPatientPendingInvitation from './searchPatientPendingInvitation';

const TableTitle = ({
  searchHandler,
  resetHandler,
  inputValue,
  setInputValue,
  openSearch,
  setOpenSearch,
}: any): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const first_name = t && t.dashboard.hcp.table.first_name;
  const last_name = t && t.dashboard.hcp.table.last_name;
  const email_t = t && t.dashboard.hcp.table.email;
  const titleColumnsConfig = ['firstName', 'lastName', 'email'];

  const handleVisibleChange = (name: string): void => {
    setInputValue('');
    if (openSearch === name) {
      setOpenSearch('');
    } else {
      setOpenSearch(name);
    }
  };

  const inputHandler = ({ target: { value } }: any): void => {
    setInputValue(value);
  };

  return (
    <>
      <div
        className={cx({
          [styles['full-screen']]: true,
          [styles.table__title]: true,
        })}
      >
        {titleColumnsConfig.map((column: string) => {
          let titleName = '';
          switch (column) {
            case 'firstName':
              titleName = first_name;
              break;
            case 'lastName':
              titleName = last_name;
              break;
            case 'email':
              titleName = email_t;
              break;
            default:
              titleName = '';
          }

          return (
            <div key={column} className={styles[`table__title-${column}-size`]}>
              <div className={styles['table__title-item-container']}>
                <span className={styles['table__title-descr']}>{titleName}</span>
                <div
                  className={styles['table__search-icon-box']}
                  onClick={(): void => handleVisibleChange(column)}
                >
                  <SearchOutlined />
                </div>
              </div>
              {openSearch === column
              && (
                <SearchPatientPendingInvitation
                  name={column}
                  searchHandler={searchHandler}
                  resetHandler={resetHandler}
                  inputValue={inputValue}
                  inputHandler={inputHandler}
                />
              )}
            </div>
          );
        })}
        <div className={styles['table__column-btn-cont-size']} />
      </div>
    </>
  );
};

export default TableTitle;
