import moment from 'moment';
import { DatesObject } from '../model';

export function getDatesByFormat(
  dateFrom: string, dateTo: string, periodType: string,
): DatesObject {
  const dateFormat = 'YYYY-MM-DD';
  const datesObject: DatesObject = {};

  let currentDate = moment(dateFrom, dateFormat);
  const stopDate = moment(dateTo, dateFormat);
  let startWeek = moment(dateFrom, dateFormat).week();
  let startMonth = moment(dateFrom, dateFormat).month();

  let keyNumber = 0;

  while (currentDate <= stopDate) {
    if (periodType === 'days') {
      keyNumber += 1;
    } else if (periodType === 'weeks') {
      if (keyNumber === 0) {
        keyNumber += 1;
      }
      if (startWeek !== currentDate.week()) {
        startWeek = currentDate.week();
        keyNumber += 1;
      }
    } else if (periodType === 'months') {
      if (keyNumber === 0) {
        keyNumber += 1;
      }

      if (startMonth !== currentDate.month()) {
        startMonth = currentDate.month();
        keyNumber += 1;
      }
    }

    if (!datesObject[keyNumber]) {
      datesObject[keyNumber] = [];
    }

    datesObject[keyNumber].push(currentDate.format(dateFormat));
    currentDate = currentDate.add(1, 'days');
  }

  return datesObject;
}
