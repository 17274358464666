/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Slider from 'react-slick';

import styles from './styles.module.css';
import './sclickStyle.css';
import { Modal } from '../Modal';
import { CloseIcon } from '../../theme/icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SliderElement } from '../../components/Video/VideoExercise/Components';
import { ExercisePhotos } from '../../components/Video/VideoExercise/types';

interface VideoModalProps {
  videoSrc: string;
  onclose: any;
}
interface PhotoSliderModalProps {
  onclose: any;
  photoPreviews: string[];
  exercisePhotos: ExercisePhotos[];
}

export const VideoModal = ({ videoSrc, onclose }: VideoModalProps): JSX.Element => (
  <Modal onClose={onclose} style={styles.videoModal}>
    <div className={styles.close}>
      <CloseIcon className={styles['close-icon']} onClick={onclose} />
    </div>
    <video
      controls
      className={styles['video-teg']}
    >
      <source src={videoSrc} type="video/mp4" />
      <source src={videoSrc} type="video/webm" />
      <source src={videoSrc} type="video/ogg" />
      Your browser doesn&apos;,t support HTML video. Here is a
      {' '}
      <a href={videoSrc}>link to the video</a>
      {' '}
      instead.
    </video>
  </Modal>
);

export const PhotoSliderModal = (
  { exercisePhotos, photoPreviews, onclose }: PhotoSliderModalProps,
): JSX.Element => (
  <Modal onClose={onclose} style={styles.photoModal}>
    <div className={styles.close}>
      <CloseIcon className={styles['close-icon']} onClick={onclose} />
    </div>
    <Slider
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      infinite
      adaptiveHeight
      arrows={false}
      dots
      // dotsClass={styles.button__bar}
      dotsClass="button__bar"
      responsive={[
        {
          breakpoint: 600,
          settings: {
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            adaptiveHeight: true,
            arrows: false,
            // dotsClass: styles.button__bar,
            dotsClass: 'button__bar',
          },
        },
      ]}
    >
      {photoPreviews.map((photo: string) => (
        <div key={photo} className={styles['slider__item-container']}>
          <img src={photo} alt="Exercise" />
        </div>
      ))}
      {exercisePhotos.map((photoUuid: {fileUuid: string}) => (
        <div key={photoUuid.fileUuid} className={styles['slider__item-container']}>
          <SliderElement
            photoUuid={photoUuid.fileUuid}
          />
        </div>
      ))}
    </Slider>
  </Modal>
);
