export const config = {
  language: process.env.REACT_APP_LOCATION,
  uri: process.env.REACT_APP_BACKEND_API,
  pollInterval: Number(process.env.REACT_APP_POLLINTERVAL_NOTIFICATION_FOR_HCP),
  IOS_LINK: process.env.REACT_APP_APP_STORE_LINK,
  ANDROID_LINK: process.env.REACT_APP_GOOGLE_PLAY_LINK,
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  videoServiceUrl: process.env.REACT_APP_BACKEND_URL_GB,
  helpPhoneNumber: process.env.REACT_APP_HELP_PHONE_NUMBER,
  helpPhoneNumberLink: process.env.REACT_APP_HELP_PHONE_NUMBER_LINK,
};
