import React, { ReactElement } from 'react';
import { useFormik } from 'formik';
import { HospitalName } from '../../components/Form/types';
import { Options } from '../../utils/model';

interface PropTypes {
  initialValues: object;
  schema?: object;
  onSend: (values: any, action: any) => void;
  component?: any;
  errorMessage: string;
  validate?: any;
  options?: Options[];
  hospitalNameOptions?: HospitalName[];
  professionOptions?: Options[];
  departmentOptions?: Options[];
  disabledField?: boolean;
  onCancel?: any;
}

export const FormFormik = ({
  initialValues,
  schema,
  onSend,
  validate,
  component: Component,
  errorMessage,
  ...rest
}: PropTypes): ReactElement => {
  const formik: any = useFormik({
    initialValues,
    validationSchema: schema,
    validate,
    onSubmit: (values, action) => onSend(values, action),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Component
        errorMessage={errorMessage}
        onChangeMethod={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        setValues={formik.setValues}
        getFieldProps={formik.getFieldProps}
        hasErrors={formik.errors}
        inputValue={formik.values}
        isValid={formik.isValid}
        isTouched={formik.touched}
        {...rest}
      />
    </form>
  );
};
