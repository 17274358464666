import { useSelector } from 'react-redux';
import { getCurrenLang } from '../redux/selector';

export default function useHandleGqlError(error: any): string | undefined {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const error_occurred_try_later = t?.dashboard.hcp.profile_patient.error_occurred_try_later;

  if (!error) return undefined;

  if (typeof error === 'string') {
    return error;
  }

  if (
    error?.graphQLErrors?.[0]?.extensions?.code === 'VALIDATION_ERROR' ||
    error?.graphQLErrors?.[0]?.extensions?.code === 'GRAPHQL_VALIDATION_FAILED'
  ) {
    return error.graphQLErrors[0].extensions.message || error_occurred_try_later;
  }

  if (error?.graphQLErrors?.[0]?.message) {
    return error.graphQLErrors[0].message;
  }

  return error_occurred_try_later || '';
}
