// Core
import { ApolloClient } from 'apollo-client'; // with Caching
import { createHttpLink } from 'apollo-link-http'; // with http request
import { InMemoryCache } from 'apollo-cache-inmemory'; //
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { config } from '../configs';
import storage from '../storage';
import { path } from '../routers/book';

// GraphQL Server
const { uri } = config;
const httpLink = createHttpLink({
  uri,
  credentials: process.env.NODE_ENV === 'development' ? 'omit' : 'include',
});
console.log('process.env.NODE_ENV=', process.env.NODE_ENV);

// Cache initialization
const cache = new InMemoryCache();

const userFromLS = storage.get('user');
const langFromLS = storage.get('lang');

// Auth
const authLink = setContext((_, { headers }) => {
  const lang = langFromLS || config.language;
  const token = userFromLS && userFromLS.token;

  if (token && lang) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        lang,
        'Apollo-Require-Preflight': true,
      },
    };
  }
  return {
    headers: {
      ...headers,
      lang,
      'Apollo-Require-Preflight': true,
    },
  };
});

const isHcpPAth = window.location.pathname !== '/signin';
const isAdminPAth = window.location.pathname !== '/admin/signin';

const resetToken = onError(({ networkError, graphQLErrors }) => {
  // @ts-ignore
  if (networkError && networkError.name === 'ServerError' && networkError.statusCode === 401) {
    console.log('networkError init app');
  }
  if (graphQLErrors) {
    if (
      (graphQLErrors &&
        graphQLErrors[0] &&
        graphQLErrors[0].extensions &&
        graphQLErrors[0].extensions.code &&
        graphQLErrors[0].extensions.exception &&
        graphQLErrors[0].extensions.exception.status === 401) ||
      (graphQLErrors[0]?.extensions?.code === 'UNAUTHORIZED')
    ) {
      const user = storage.get('user');
      const role = user && user.role && !!user.role;
      const isAdmin = user?.role === 'admin';
      if (isHcpPAth || isAdminPAth) {
        if (role) {
          if (isAdmin) {
            window.location.assign(path.adminSignIn);
          } else {
            window.location.assign(path.signIn);
          }
          localStorage.setItem('user', '');
        }
      }
    }
  }
});

const wrappedHttpLink = resetToken.concat(authLink.concat(httpLink));

export const client = new ApolloClient({
  cache,
  link: wrappedHttpLink,
});
