import React, { ReactElement } from 'react';
import cx from 'classnames';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import { getCurrenLang } from '../../../../redux/selector';
import GlobalNotificationItem from '../notificationItem';
import { NotificationsListProps, TNotification } from '../../types';

const NotificationsList = ({
  isPatientOverview,
  totalPages,
  visibleNotificationList,
  notificationsList,
  switchHandler,
  setNextPage,
  changeStatusHandler,
  checkedSwitch,
}: NotificationsListProps): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const notificationsTitle = t?.header.notifications_title;
  const onlyShowUnread = t?.header.only_show_unread;
  const allNotificationsRead = t?.header.all_notifications_read;
  const noMoreMessages = t?.header.no_more_messages;
  const loading_ = t?.header.loading;

  const endMessage = totalPages === 1;

  // JSX
  const noData = <div className={styles['notification-no-data']}>{allNotificationsRead}</div>;

  return (
    <div
      id={`${isPatientOverview}'scrollableDiv'`}
      className={cx({
        [styles['notification-list']]: !isPatientOverview,
        [styles.visible]: visibleNotificationList,
        [styles['notification-list--patientOverview']]: isPatientOverview,
      })}
    >
      <div className={styles['notification-header']}>
        <h1 className={styles['notification-header-h1']}>{notificationsTitle}</h1>
        <div className={styles['notification-switch-container']}>
          <span
            className={cx({
              [styles['notification-switch-label']]: !isPatientOverview,
              [styles['notification-switch-label-patient-overview']]: isPatientOverview,
            })}
          >
            {onlyShowUnread}
          </span>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checkedSwitch}
            // defaultChecked
            className={cx({
              [styles['notification-switch']]: true,
              [styles.visible]: visibleNotificationList || isPatientOverview,
            })}
            size="small"
            onChange={(checked: boolean): void => {
              switchHandler(checked);
            }}
          />
        </div>
      </div>
      {notificationsList.length <= 0 && noData}
      {notificationsList.length ? (
        <InfiniteScroll
          dataLength={notificationsList.length}
          next={(): any => setNextPage()}
          hasMore={!endMessage}
          loader={(
            <h4>
              {loading_}
              ...
            </h4>
          )}
          scrollableTarget={`${isPatientOverview}'scrollableDiv'`}
          endMessage={<span className={styles['notification-row']}>{noMoreMessages}</span>}
        >
          {notificationsList.map((notification: TNotification) => (
            <GlobalNotificationItem
              notification={notification}
              key={notification.hcpNotificationId}
              visibleNotificationList={visibleNotificationList}
              changeStatusHandler={changeStatusHandler}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <div />
      )}
    </div>
  );
};

export default NotificationsList;
