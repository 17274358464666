import { lazy } from 'react';
import { path } from './book';

export const routers = [
  {
    path: path.home,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Dashboard' /* webpackChunkName: "Home-page-hcp" */)),
  },
  {
    path: path.dashboard,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Dashboard')),
  },
  {
    path: path.signUp,
    label: 'Sign Up',
    exact: false,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/create' /* webpackChunkName: "SignUp-page" */)),
  },
  {
    path: path.resetEmail,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ResetPassword/email' /* webpackChunkName: "ResetWithEmail-page" */)),
  },
  {
    path: path.resetPassword,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ResetPassword/password' /* webpackChunkName: "reset-page" */)),
  },
  {
    path: path.resetPasswordHcpByHcp,
    label: 'Reset HCP password',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ResetPassword/ResetPasswordHcpByHcp')),
  },
  {
    path: path.signIn,
    label: 'Sign In',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignIn' /* webpackChunkName: "signin-page-hcp" */)),
  },
  {
    path: path.admin,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Dashboard' /* webpackChunkName: "home-page-admin" */)),
  },
  {
    path: path.adminDashboard,
    label: 'Dashboard',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Dashboard')),
  },
  {
    path: path.adminSignIn,
    label: 'Sign In',
    exact: true,
    public: true,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/SignIn' /* webpackChunkName: "signin-page-admin" */)),
  },
  {
    path: path.resetAdminEmail,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ResetPassword/email' /* webpackChunkName: "ResetWithEmail-page" */)),
  },
  {
    path: path.resetAdminPassword,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ResetPassword/password' /* webpackChunkName: "ResetWithPassword-page" */)),
  },
  {
    path: path.profile,
    label: 'Profile',
    exact: true,
    public: false,
    permissions: ['hcp', 'admin'],
    component: lazy(() => import('../../pages/ProfileUser' /* webpackChunkName: "Profile-page" */)),
  },
  {
    path: path.inviteHcpAsHcp,
    label: 'Invite HCP',
    exact: false,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Invite/HCP' /* webpackChunkName: "Invite-hcp" */)),
  },
  {
    path: path.inviteHcpAsAdmin,
    label: 'Invite HCP',
    exact: false,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Invite/HCP' /* webpackChunkName: "Invite-hcp" */)),
  },
  {
    path: path.connectPatientAsHcp,
    label: 'Invite Patient',
    exact: false,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Connect/ConnectPatientPage')),
  },
  {
    path: path.notFoud,
    label: '404',
    exact: false,
    public: true,
    permissions: ['admin', 'hcp'],
    component: lazy(() => import('../../pages/NotFound' /* webpackChunkName: "not found page" */)),
  },
  {
    path: path.profile_patient,
    label: 'Profile patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ProfilePatient' /* webpackChunkName: "profile_patient_page" */)),
  },
  {
    path: path.profile_patient_admin,
    label: 'Profile patient',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ProfilePatient' /* webpackChunkName: "profile_patient_page" */)),
  },
  {
    path: path.profile_hcp_admin,
    label: 'Profile hcp',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ProfileHcp' /* webpackChunkName: "profile_hcp_page" */)),
  },
  {
    path: path.profile_hcp_from_hcp,
    label: 'Profile hcp',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ProfileHcp' /* webpackChunkName: "profile_hcp_from_hcp" */)),
  },
  {
    path: path.profile_patient_from_hcp_admin,
    label: 'Profile patient',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Dashboard/Profile' /* webpackChunkName: "profile_patient_page" */)),
  },
  {
    path: path.profile_patient_from_hcp,
    label: 'Profile patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Dashboard/Profile' /* webpackChunkName: "profile_patient_page" */)),
  },
  {
    path: path.pending_patient_invitations,
    label: 'Pending patient invitations',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/PatientInvitations')),
  },
  {
    path: path.manage_notifications,
    label: 'Manage notifications',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManageNotifications')),
  },
  {
    path: path.manage_rewards_hcp,
    label: 'Manage rewards hcp',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManageRewards')),
  },
  {
    path: path.manage_patient_information,
    label: 'Manage patient information',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManagePatientInformation')),
  },
  {
    path: path.upload_patient_information,
    label: 'Upload patient information',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManagePatientInformation/UploadPatientInformation')),
  },
  {
    path: path.manage_exercises,
    label: 'Manage exercises',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManageExercises')),
  },
  {
    path: path.manage_teams,
    label: 'Manage teams',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManageTeams')),
  },
  {
    path: path.my_teams,
    label: 'My teams',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManageTeams/MyTeams')),
  },
  {
    path: path.create_team,
    label: 'Create teams',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/ManageTeams/CreateTeam')),
  },
  {
    path: path.upload_video_for_patient,
    label: 'Upload video for patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/UploadMyVideo')),
  },
  {
    path: path.record_video_for_patient,
    label: 'Record video for patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/RecordMyVideo')),
  },
  {
    path: path.upload_my_video,
    label: 'Upload my video',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/UploadMyVideo')),
  },
  {
    path: path.record_my_video,
    label: 'Record my video',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/RecordMyVideo')),
  },
  {
    path: path.upload_my_photo,
    label: 'Upload my photo',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/UploadMyPhoto')),
  },
  {
    path: path.take_photo,
    label: 'Take photo',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/TakeNewPhoto')),
  },
  {
    path: path.manage_organisations,
    label: 'Manage organisations',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Admin/ManageOrganisations')),
  },
  {
    path: path.manage_professions,
    label: 'Manage professions',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Admin/ManageProfessions')),
  },
  {
    path: path.manage_departments,
    label: 'Manage Deportmants',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Admin/ManageDepartments')),
  },
  {
    path: path.manage_proms,
    label: 'Manage proms',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Admin/ManageProms')),
  },
  {
    path: path.manage_rewards_admin,
    label: 'Manage rewards admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/Admin/ManageRewards')),
  },
  {
    path: path.manage_patient_information_admin,
    label: 'Manage patient information admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ManagePatientInformation')),
  },
  {
    path: path.upload_patient_information_admin,
    label: 'Upload patient information admin',
    exact: true,
    public: false,
    permissions: ['admin'],
    component: lazy(() => import('../../pages/ManagePatientInformation/UploadPatientInformation')),
  },
  {
    path: path.team_video_bank,
    label: 'Team video bank',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/TeamVideoBank')),
  },
  {
    path: path.team_video_bank_folder,
    label: 'Team video bank folder',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/TeamVideoBankFolder')),
  },
  {
    path: path.prescription_history,
    label: 'View prescription history',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/PrescriptionHistory')),
  },
  {
    path: path.from_patient,
    label: 'From patient',
    exact: true,
    public: false,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/Video/FromPatient')),
  },
  {
    path: path.success_delete_hcp_account,
    label: 'Success delete hcp account',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SuccessMessage/SuccessDeletedHcpAccount')),
  },

  // To Mobile App
  {
    path: path.signUpForMobile,
    label: 'Sign In',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal' /* webpackChunkName: "signin-page-hcp" */)),
  },
  {
    path: path.questionnaireForMobile,
    label: 'questionnaire reminder',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal' /* webpackChunkName: "signin-page-hcp" */)),
  },
  {
    path: path.sharingPatientResult,
    label: 'Sharing result',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal' /* webpackChunkName: "signin-page-hcp" */)),
  },
  {
    path: path.resetPasswordMobile,
    label: 'Reset password',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal' /* webpackChunkName: "signin-page-hcp" */)),
  },
  {
    path: path.resetPinMobile,
    label: 'Reset pin',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.connectPatientWithCodeMobile,
    label: 'Connect with code',
    exact: true,
    public: true,
    permissions: ['hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.loginPatientWithCodeMobile,
    label: 'Patient login  with code',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToQuestionnairesMobile,
    label: 'Patients questionnaire',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToAchievementsMobile,
    label: 'Patients Achievements',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToExerciseLibraryMobile,
    label: 'Patients Exercise Library',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToRewardsMobile,
    label: 'Patients Rewards',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.redirectToProcedureAndTeamMobile,
    label: 'Patients Procedure And Team',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.patientVerifyEmail,
    label: 'Patient Verify Email',
    exact: true,
    public: true,
    permissions: ['patient', 'hcp'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.signinPageMobile,
    label: 'Patient Sign In',
    exact: true,
    public: true,
    permissions: ['patient'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
  {
    path: path.signupPageMobile,
    label: 'Patient Sign Up',
    exact: true,
    public: true,
    permissions: ['patient'],
    component: lazy(() => import('../../pages/SignUp/modal')),
  },
];
