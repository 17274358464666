// Type
import { PeriodListOptionValue } from '../../utils/model';
import { listOptionInit } from '../../utils/share/options';
import {
  ActionTypes,
  DataType,
  ERROR_PATIENT_COMPLETED_EXERCISES,
  ErrorHttpAction,
  FILL_PATIENT_COMPLETINS,
  FILL_PATIENT_EXERCISES,
  FILL_EXERCISES_OVERVIEW_TAB,
  FILL_DATA_BY_EXERCISES,
  FILL_STEPS_EXERCISES,
  IsExerciseChart,
  PatientCompletionsFillAction,
  PatientErrorFetching,
  PatientExerciseName,
  PatientFillAction,
  PatientFillPeriondAction,
  PatientFillStep,
  PatientStartFetching,
  ExercisesType,
  SET_EXERCISE_NAME,
  SET_IS_EXERCISE,
  START_FETCHING_PATIENT,
  FILL_EXERCISES_MOTIVATION_PAIN_TAB,
  FILL_EXERCISES_REASONS_TAB,
  FILL_STEPS_OVERVIEW_TAB,
  FILL_STEPS_BY_EXERCISES,
  STEPS_LOADING,
  REASONS_LEGEND_VALUE,
  MOTIVATIONS_LEGEND_VALUE,
  BY_EXERCISES_LEGEND_VALUE,
  COMPLETATION_STEPS_LEGEND_VALUE,
  COMPLETATION_SKIP_STOP_LEGEND_VALUE,
  ExercisesFillAction,
} from './types';

export function fillSteps(payload: DataType): PatientFillStep {
  return {
    type: FILL_STEPS_EXERCISES,
    payload,
  };
}
export function fillStepsOverviewTab(payload: DataType): PatientFillStep {
  return {
    type: FILL_STEPS_OVERVIEW_TAB,
    payload,
  };
}
export function fillStepsByExercises(payload: DataType): PatientFillStep {
  return {
    type: FILL_STEPS_BY_EXERCISES,
    payload,
  };
}

export function fillCompletionsPeriods(payload: DataType): PatientFillAction {
  return {
    type: FILL_PATIENT_EXERCISES,
    payload,
  };
}

export const fillExercisesOverviewTab = (payload: [] | object[]): ExercisesFillAction => ({
  type: FILL_EXERCISES_OVERVIEW_TAB,
  payload,
});

export function fillExercMotivPainTab(payload: DataType): PatientFillAction {
  return {
    type: FILL_EXERCISES_MOTIVATION_PAIN_TAB,
    payload,
  };
}
export function fillExercisesReasonsTab(payload: DataType): PatientFillAction {
  return {
    type: FILL_EXERCISES_REASONS_TAB,
    payload,
  };
}

export const fillCompletionsExercises = (payload: DataType): PatientFillPeriondAction => ({
  type: FILL_DATA_BY_EXERCISES,
  payload,
});

export const loadingExercises = (payload: boolean): PatientStartFetching => ({
  type: START_FETCHING_PATIENT,
  payload,
});

export function loadingSteps(payload: boolean): PatientStartFetching {
  return {
    type: STEPS_LOADING,
    payload,
  };
}

export function fillCompletions(payload: DataType): PatientCompletionsFillAction {
  return {
    type: FILL_PATIENT_COMPLETINS,
    payload,
  };
}

export function setfetchingError(payload: ErrorHttpAction): PatientErrorFetching {
  return {
    type: ERROR_PATIENT_COMPLETED_EXERCISES,
    payload,
  };
}

export const setExerciseName = (payload: string | null): PatientExerciseName => ({
  type: SET_EXERCISE_NAME,
  payload,
});

export function setIsExercise(payload: boolean): IsExerciseChart {
  return {
    type: SET_IS_EXERCISE,
    payload,
  };
}
export const setReasonsLegendValue = (payload: any): PatientFillAction => ({
  type: REASONS_LEGEND_VALUE,
  payload,
});
export const setMotivationsLegendValue = (payload: any): PatientFillAction => ({
  type: MOTIVATIONS_LEGEND_VALUE,
  payload,
});
export const setComplByExercLegendValue = (payload: any): PatientFillAction => ({
  type: BY_EXERCISES_LEGEND_VALUE,
  payload,
});
export const setComplStepsLegendValue = (payload: any): PatientFillAction => ({
  type: COMPLETATION_STEPS_LEGEND_VALUE,
  payload,
});
export const setComplSkipStopLegendValue = (payload: any): PatientFillAction => ({
  type: COMPLETATION_SKIP_STOP_LEGEND_VALUE,
  payload,
});

const init: ExercisesType = {
  byExercises: [],
  exercisesPerPeriod: [],
  isExercise: true,
  isFetching: false,
  exerciseName: null,
  error: false,
  exercisesOverviewTab: [],
  exercMotivPainTab: [],
  exercReasonsTab: [],
  step: [],
  stepOverviewTab: [],
  stepByExercises: [],
  stepsLoading: false,
  reasonsLegendValue: [],
  motivationsLegendValue: [],
  complByExercLegendValue: {
    completed: 0,
    partlyDone: 0,
    attempts: 0,
    noAttempt: 0,
  },
  complStepsLegendValue: [],
  complSkipStopLegendValue: [],
};

// Reducer
export const completedReducer = (state = init, action: ActionTypes): ExercisesType => {
  switch (action.type) {
    case FILL_DATA_BY_EXERCISES:
      return {
        ...state,
        byExercises: action.payload, // DATA for graphic complition
        error: false,
      };
    case FILL_PATIENT_EXERCISES:
      return {
        ...state,
        exercisesPerPeriod: action.payload,
        error: false,
      };
    case FILL_EXERCISES_OVERVIEW_TAB:
      return {
        ...state,
        exercisesOverviewTab: action.payload,
      };
    case FILL_EXERCISES_MOTIVATION_PAIN_TAB:
      return {
        ...state,
        exercMotivPainTab: action.payload,
      };
    case FILL_EXERCISES_REASONS_TAB:
      return {
        ...state,
        exercReasonsTab: action.payload,
      };
    case FILL_STEPS_EXERCISES:
      return {
        ...state,
        step: action.payload,
        error: false,
      };
    case FILL_STEPS_OVERVIEW_TAB:
      return {
        ...state,
        stepOverviewTab: action.payload,
      };
    case FILL_STEPS_BY_EXERCISES:
      return {
        ...state,
        stepByExercises: action.payload,
      };
    case STEPS_LOADING:
      return {
        ...state,
        stepsLoading: action.payload,
      };
    case START_FETCHING_PATIENT:
      return {
        ...state,
        isFetching: action.payload,
      };
    case ERROR_PATIENT_COMPLETED_EXERCISES:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case SET_EXERCISE_NAME:
      return {
        ...state,
        exerciseName: action.payload,
      };
    case SET_IS_EXERCISE:
      return {
        ...state,
        isExercise: action.payload,
      };
    case REASONS_LEGEND_VALUE:
      return {
        ...state,
        reasonsLegendValue: action.payload,
      };
    case MOTIVATIONS_LEGEND_VALUE:
      return {
        ...state,
        motivationsLegendValue: action.payload,
      };

    case BY_EXERCISES_LEGEND_VALUE:
      return {
        ...state,
        complByExercLegendValue: action.payload,
      };

    case COMPLETATION_STEPS_LEGEND_VALUE:
      return {
        ...state,
        complStepsLegendValue: action.payload,
      };

    case COMPLETATION_SKIP_STOP_LEGEND_VALUE:
      return {
        ...state,
        complSkipStopLegendValue: action.payload,
      };

    default:
      return state;
  }
};
