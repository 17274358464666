import { config } from '../../utils/configs';
import { UserCountryEnum } from '../../utils/enums';
import { ProcedureKey } from '../../utils/model';
import { dotFormatDate, formatDateEU } from '../../utils/variables';

// Type
const SET_CURRENT_INNERWIDTH = 'SET_CURRENT_INNERWIDTH';
const FILL_CURRENT_HCP_NAME = 'FILL_CURRENT_HCP_NAME';
const SET_CURRENT_DATE_FORMAT = 'SET_CURRENT_DATE_FORMAT';
const SET_CURRENT_USER_LANGUAGE = 'SET_CURRENT_USER_LANGUAGE';
const SET_CURRENT_USER_COUNTRY = 'SET_CURRENT_USER_COUNTRY';
const SET_PROCEDURE_KEYS = 'SET_PROCEDURE_KEYS';

// TS
export interface CommonType {
  screen: {
    innerWidth: number;
  };
  hcp: HcpName;
  formatDates: CurrentFormatDates;
  userLang: string;
  userCountry: string;
  listProcedureKeys: ProcedureKey[];
}
export interface CurrentFormatDates {
  momentFormat: string;
  reactDatePickerFormat: string;
}
export interface HcpName {
  firstName: string;
  lastName: string;
  allowedPromsId: object;
  country: string;
  id: number | null;
  email: string;
  permissions: any[];
  isAutomaticallyAccept: boolean;
  rewardsType: number;
  recipients: any[];
  hospitalId: number | null;
}
interface WindowScreenType {
  type: string;
  payload: number;
}
interface HcpNameType {
  type: string;
  payload: HcpName;
}
interface CurrentDateFormatType {
  type: string;
  payload: CurrentFormatDates;
}
interface CurrentUserLangType {
  type: string;
  payload: string;
}
interface AllProceduresType {
  type: string;
  payload: ProcedureKey[];
}
type ActionTypes = WindowScreenType | HcpNameType | CurrentDateFormatType | CurrentUserLangType;

// Action
export const setWindowClientWidth = (value: number): WindowScreenType => ({
  type: SET_CURRENT_INNERWIDTH,
  payload: value,
});
export const setHcpName = (payload: HcpName): HcpNameType => ({
  type: FILL_CURRENT_HCP_NAME,
  payload,
});
export const setCurrentDateFormat = (payload: CurrentFormatDates): CurrentDateFormatType => ({
  type: SET_CURRENT_DATE_FORMAT,
  payload,
});
export const setCurrentLang = (payload: string): CurrentUserLangType => ({
  type: SET_CURRENT_USER_LANGUAGE,
  payload,
});
export const setCurrentCountry = (payload: string): CurrentUserLangType => ({
  type: SET_CURRENT_USER_COUNTRY,
  payload,
});
export const setProcedureKeys = (payload: ProcedureKey[]): AllProceduresType => ({
  type: SET_PROCEDURE_KEYS,
  payload,
});

// Initial comon STATE
const init: CommonType = {
  screen: { innerWidth: 0 },
  hcp: {
    firstName: '',
    lastName: '',
    allowedPromsId: [],
    country: UserCountryEnum.GB,
    id: null,
    email: '',
    permissions: [],
    isAutomaticallyAccept: false,
    rewardsType: 1,
    recipients: [],
    hospitalId: null,
  },
  formatDates: { momentFormat: formatDateEU, reactDatePickerFormat: dotFormatDate },
  userLang: config.language || 'en-gb',
  userCountry: UserCountryEnum.GB,
  listProcedureKeys: [],
};

// Reducer
export const commonReducer = (state = init, { type, payload }: ActionTypes): any => {
  switch (type) {
    case SET_CURRENT_INNERWIDTH:
      return {
        ...state,
        screen: { innerWidth: payload },
      };
    case FILL_CURRENT_HCP_NAME:
      return {
        ...state,
        hcp: payload,
      };
    case SET_CURRENT_DATE_FORMAT:
      return {
        ...state,
        formatDates: payload,
      };
    case SET_CURRENT_USER_LANGUAGE:
      return {
        ...state,
        userLang: payload,
      };
    case SET_CURRENT_USER_COUNTRY:
      return {
        ...state,
        userCountry: payload,
      };
    case SET_PROCEDURE_KEYS:
      return {
        ...state,
        listProcedureKeys: payload,
      };
    default:
      return state;
  }
};
